import BaseModelWithTokens from '../base/base-model-with-tokens';

export default class AuthWithTokens extends BaseModelWithTokens {
  resourceName() {
    return 'oauth';
  }

  fields() {
    return ['accessToken', 'refreshToken', 'login', 'pwd', 'id'];
  }
}
