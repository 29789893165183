const initialState = {
  reloaded: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'RELOAD_PAY_OBJECTS':
      return {
        ...state,
        reloaded: true,
        payObjects: payload,
      };
    case 'SAVE_PAY_OBJECTS':
      return {
        ...state,
        payObjects: payload,
      };
    case 'SAVE_CURRENCIES':
      return {
        ...state,
        currencies: payload,
      };

    default:
      return state;
  }
};
