import Programs from '../../../json-api/models/programs/programs';
import ProgramsWithTokens from '../../../json-api/models/programs/programs-with-tokens';
import isNode from '../../../helpers/utils/is-node';
import store from '../../../../store-config';

export default async function getPromocodes() {
  const programsModel =
    !isNode() && store.getState().user.logged ? new ProgramsWithTokens() : new Programs();
  const { data } = await programsModel.with(['categories', 'promocodes']).get();
  return data.filter(({ isActive }) => isActive);
}
