import React from 'react';
import PropTypes from 'prop-types';

Social.propTypes = {
  desktop: PropTypes.bool
};

Social.defaultProps = {
  desktop: true
};

export default function Social({ desktop }) {
  return (
    <div className={`footer__social footer__social${desktop ? '_desktop' : '_mobile'}`}>
      <span>Мы в соцсетях</span>
      <div>
        <a
          href="https://vk.com/skidkateam"
          target="_blank"
          rel="noopener noreferrer nofollow"
          aria-label="Вконтакте"
        >
          <svg className="vk">
            <use xlinkHref="/assets/svg/sprite-v2.svg#vk-footer" />
          </svg>
        </a>
        {/*<a
          href="https://www.facebook.com/official.skidka.ru"
          target="_blank"
          rel="noopener noreferrer nofollow"
          aria-label="Facebook"
        >
          <svg className="fb">
            <use xlinkHref="/assets/svg/sprite-v2.svg#facebook-footer" />
          </svg>
        </a>*/}
        <a
          href="https://www.youtube.com/user/wwwSKIDKAru"
          target="_blank"
          rel="noopener noreferrer nofollow"
          aria-label="YouTube"
        >
          <svg className="yt">
            <use xlinkHref="/assets/svg/sprite-v2.svg#youtube" />
          </svg>
        </a>
        {/*<a
          href="https://www.instagram.com/skidkaru/"
          target="_blank"
          rel="noopener noreferrer nofollow"
          aria-label="Instagram"
        >
          <svg className="inst">
            <use xlinkHref="/assets/svg/sprite-v2.svg#instagram" />
          </svg>
        </a>*/}
      </div>
    </div>
  );
}
