import ModelWithTokens from '../base/base-model-with-tokens';

export default class TicketMessages extends ModelWithTokens {
  resourceName() {
    return 'ticket_messages';
  }

  fields() {
    return [
      'createStamp',
      'statusId',
      'themeId',
      'message',
      'messageAuthor',
      'attachments',
      'ticketFields',
      'userId',
      'ticketId',
      'read',
      'attachments',
      'messageFromUser'
    ];
  }
}
