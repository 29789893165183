/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '../menu-item';

MenuLeft.propTypes = {
  handleShow: PropTypes.func.isRequired
};

export default function MenuLeft({ handleShow }) {
  return (
    <div className="menu-left menu">
      <div className="menu__items_main">
        <div className="menu__top-block">
          <MenuItem
            className="menu__item"
            to="/shops/"
            onClick={() => handleShow(false)}
            title="Кэшбэк"
          />
          <div style={{ position: "relative" }}>
          <MenuItem
            className="menu__item"
            to="/promocode/"
            onClick={() => handleShow(false)}
            title="Промокоды"
          />
            <img alt="Новинка" src="/assets/img/main-promo/fresh-sale-mobile.png" className="menu-badge" />
          </div>
        </div>
      </div>
    </div>
  );
}
