import * as Sentry from '@sentry/browser';
import sentryConfig from './sentry-config';

const sentryBrowser = () => {
  if (process.env.MODE !== 'development') {
    Sentry.init(sentryConfig);
  }
};

export default sentryBrowser;
