import Categories from "../../../json-api/models/programs/categories";

/**
 * Запрос категорий с сервера
 */
export default async function fetchCategories() {
  const categoriesModel = new Categories();
  const { data: categories } = await categoriesModel.get();
  return {
    categories: [
      { label: "Все категории", value: null, translitName: "", withPromocodes: true },
      { label: "Избранные магазины", value: "fav", translitName: "fav", loggedOnly: true },
      ...categories.map(
        ({ name: label, id: value, seoText, alias: translitName, withPromocodes }) => ({
          label,
          value,
          seoText,
          translitName,
          withPromocodes
        })
      )
    ]
  };
}
