import ModelWithTokens from '../base/base-model-with-tokens';

export default class Subscriptions extends ModelWithTokens {
  resourceName() {
    return 'subscriptions';
  }

  fields() {
    return ['label'];
  }
}
