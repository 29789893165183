// import BaseModel from '../base/base-model';
import BaseModel from '../base/base-model';

export default class PayObjects extends BaseModel {
  resourceName() {
    return 'pay_objects';
  }

  fields() {
    return ['name', 'iconPath', 'isTarget'];
  }
}
