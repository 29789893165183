const menuItemsData = [
  { to: '/shops/fav/', spriteId: 'favorite', title: 'Избранное' },
  { to: '/user/history/', spriteId: 'history', title: 'История' },
  { to: '/user/codes/', spriteId: 'codes', title: 'Кэшбэк-коды' },
  {
    to: '/user/notifications/',
    spriteId: 'notification',
    title: 'Уведомления',
    showNotifyCount: true
  },
  { to: '/user/support/', spriteId: 'service', title: 'Поддержка' },
  { to: '/user/profile/', spriteId: 'profile', title: 'Настройки профиля', hideOnMobile: true },
  { to: '/admin/', spriteId: 'admin', title: 'Админка', isAdminItem: true },
  { to: '', spriteId: 'logout', title: 'Выход', isLogout: true }
];

export default menuItemsData;
