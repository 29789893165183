import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  count: PropTypes.number,
  inMenu: PropTypes.bool
};

const defaultProps = {
  count: 0,
  inMenu: false
};

function NotifyCount({ count, inMenu }) {
  return (
    <>
      {count && <div className={`notify-count${inMenu ? '_menu notify-count' : ''}`}>{count}</div>}
    </>
  );
}

NotifyCount.propTypes = propTypes;
NotifyCount.defaultProps = defaultProps;

export default NotifyCount;
