import ModelWithTokens from '../base/base-model-with-tokens';

export default class PayTargets extends ModelWithTokens {
  resourceName() {
    return 'pay_targets';
  }

  fields() {
    return [
      'payFields',
      'name',
      'note',
      'paymentInfo',
      'amount_rub',
      'phone',
      'minSum',
      'maxSum',
      'commissionNote',
      'additional',
      'percent',
      'minimal'
    ];
  }
}
