import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { matchPath, withRouter } from 'react-router';
import { connect } from 'react-redux';

import Header from '../header/header';
import Footer from '../footer/footer';
import routes from '../../router/router';
import ToastCustom from '../shared/toast/toast';
import showLoader from '../../helpers/utils/show-loader';
import setMetaAttribute from '../../helpers/set-meta-attribute';

function WithHeaderFooter({
  children,
  history,
  showToast,
  toastMsg,
  setShowToast,
  toastType,
  route,
  toastZIndex,
}) {
  const [currentRoute, setCurrentRoute] = useState(route);

  useEffect(() => {
    setCurrentRoute(
      routes.find(({ path, exact }) =>
        matchPath(history.location.pathname + history.location.search, {
          path,
          exact,
        }),
      ),
    );
  }, [history.location.pathname, history.location.search]);

  useEffect(() => {
    showLoader(false);
    if (currentRoute && currentRoute.title) {
      document.title = currentRoute.title;

      setMetaAttribute({
        attributeName: 'property',
        attributeValue: 'og:title',
        content: currentRoute.title,
      });

      setMetaAttribute({
        attributeName: 'name',
        attributeValue: 'description',
        content:
          'Совершайте покупки в интернет-магазинах и получайте высокий % кэшбэка с десятками способов вывода денег без комиссии на СКИДКА РУ'
      });

      setMetaAttribute({
        attributeName: 'property',
        attributeValue: 'og:description',
        content:
          'Совершайте покупки в интернет-магазинах и получайте высокий % кэшбэка с десятками способов вывода денег без комиссии на СКИДКА РУ'
      });
    }
  }, [currentRoute]);

  return (
    <>
      <ToastCustom
        show={showToast}
        setShow={setShowToast}
        msg={toastMsg}
        type={toastType}
        zIndex={toastZIndex}
      />
      {currentRoute && currentRoute.hideHeaderFooter ? (
        <>{children}</>
      ) : (
        <div className="main-container">
          <Header />
          <div className="header-footer-inner">{children}</div>
        </div>
      )}

      {(!currentRoute || (currentRoute && !currentRoute.hideHeaderFooter)) && <Footer />}
    </>
  );
}

WithHeaderFooter.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({ pathname: PropTypes.string, search: PropTypes.string }),
  }).isRequired,
  showToast: PropTypes.bool,
  toastMsg: PropTypes.string,
  toastType: PropTypes.string,
  setShowToast: PropTypes.func.isRequired,
  route: PropTypes.shape({
    title: PropTypes.string,
    hideHeaderFooter: PropTypes.bool,
  }).isRequired,
  toastZIndex: PropTypes.number,
};

WithHeaderFooter.defaultProps = {
  showToast: false,
  toastMsg: '',
  toastType: '',
  toastZIndex: undefined,
};

const mapStateToProps = ({ appReducer }) => ({
  showToast: appReducer.toast.show,
  toastMsg: appReducer.toast.msg,
  toastType: appReducer.toast.type,
  toastZIndex: appReducer.toast.zIndex,
  route: appReducer.serverRoute,
});

const mapDispatchToProps = (dispatch) => ({
  setShowToast: () =>
    dispatch({ type: 'SET_TOAST', payload: { show: false, msg: '', type: '', zIndex: 1 } }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WithHeaderFooter));
