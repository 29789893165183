import dayjs from 'dayjs';
import store from '../../store-config';

export default function setLoadingStatus({ type }) {
  function setToStore(data) {
    store.dispatch({
      type: 'CHANGE_DATA_HANDLER',
      payload: {
        type,
        ...data
      }
    });
  }

  return {
    setIsPending: () => setToStore({ isPending: true }),
    setIsUnpending: () => setToStore({ isPending: false }),
    setLoaded: () => setToStore({ loaded: true }),
    setOld: () => setToStore({ old: true }),
    setNew: () => setToStore({ old: false }),
    setLastRequest: () => setToStore({ lastRequest: dayjs(new Date()).unix() })
  };
}
