import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import routes from '../../../router/router';

export default function ScrollToTop() {
  const { pathname, search } = useLocation();

  useEffect(() => {
    const match = routes.find(({ path, exact }) =>
      matchPath(pathname + search, {
        path,
        exact
      })
    );

    if (!match?.disableScrollTop)
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 300);
  }, [pathname, search]);

  return null;
}
