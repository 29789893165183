import React from 'react';
import loadable from '@loadable/component';

const GoToRedirect = loadable(() =>
  import(/* webpackChunkName: "goto" */ '../../components/goto/goto')
);

export default function GotoPage() {
  return <GoToRedirect />;
}
