import BlogStore from './blog/blog-store';
import EmailFieldStore from './user/email-field/email-field-store';
import EmailFieldTransport from './user/email-field/email-field-transport';
import NotifiesDomainStore from './user/notifies/notifies-store';
import NotifiesTransport from './user/notifies/notifies-transport';
import NotifiesUIStore from './user/notifies/notifies-ui-store';

export default class RootStore {
  notifiesUI: NotifiesUIStore;

  emailField: EmailFieldStore;

  blog: BlogStore;

  constructor(initialState: any) {
    const notifiesDomainStore = new NotifiesDomainStore(new NotifiesTransport());
    this.notifiesUI = new NotifiesUIStore(notifiesDomainStore);
    this.emailField = new EmailFieldStore(new EmailFieldTransport());
    this.blog = new BlogStore(initialState);
  }
}
