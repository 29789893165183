import React from 'react';
import loadable from '@loadable/component';
import preloadProgramsDataForSSR from '../context/programs-context/preload-programs-data-for-ssr';
import fetchProgramsByIds from '../context/programs-context/api-requests/fetch-programs-by-ids';

const Index = loadable(() => import(/* webpackChunkName: "index" */ '../components/index/index'));

export default function IndexPage() {
  return <Index />;
}

IndexPage.serverFetch = async ({ isLogged, req }) => {
  const preloadedState = await preloadProgramsDataForSSR({
    isLogged,
    url: req.url
  });

  const promoPrograms = await fetchProgramsByIds(['72', '233', '936']);
  preloadedState.programsContext.promoPrograms = promoPrograms;

  return {
    preloadedState
  };
};
