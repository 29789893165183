import getCursor from '../../../helpers/utils/get-cursor';
import Promocodes from '../../../json-api/models/programs/promocodes';

/**
 * Запрос промокодов с сервера.
 * Если передано значение пагинации, то добавить к ссылке курсор
 * @param {boolean} [options.isLogged] - пользователь залогинен
 * @param {string} [options.cursor] - курсор
 * @param {string} [options.filters] - фильтры
 */
export default async function fetchPromocodes({ cursor, filters } = {}) {
  try {
    const promocodesModel = new Promocodes();
    let request = promocodesModel.with(['countries', 'categories', 'program_misc']);

    // Добавить фильтры к запросу
    if (filters?.country) request = request.where('country', filters.country);
    if (filters?.category) request = request.where('category', filters.category);

    // TODO: в sarala используется обязательный параметр size в пагинации, надо убрать.
    const { data, links, meta } = cursor
      ? await request.paginate(null, cursor)
      : await request.get();
    return {
      promocodes: {
        data: data.map((item) => {
          item.logo = item.program_misc.logo;
          item.name = item.program_misc.name;
          return item;
        }),
        prev: getCursor(links.prev),
        next: getCursor(links.next),
        count: meta.count
      }
    };
  } catch (error) {
    console.error({ error });
    return { promocodes: { data: [] } };
  }
}
