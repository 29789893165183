import uniqid from 'uniqid';
import * as Sentry from '@sentry/browser';
import {jitsu} from "../../src/helpers/jitsu/jitsu";

const initialState = {
  user: {
    id: null,
    balance: {
      data: [],
    },
    country: {},
  },
  logged: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'RESET_USER':
      return initialState;
    // Обновляет user в в зависимости от пришедших в ответ на запрос данных
    case 'UPDATE_USER': {
      const newUser = state.user.clone ? state.user.clone() : state.user;

      // Обновляются подписки юзера
      if (payload.type === 'subscriptions') {
        if (!Array.isArray(payload.data)) {
          // eslint-disable-next-line no-param-reassign
          payload.data = [payload.data];
        }
        const data = payload.data.map((subscription) => {
          const sub = {};
          sub.id = subscription.id;
          return sub;
        });
        newUser.subscriptions.data = data;
      }

      // Обновляется ник
      if (payload.type === 'name') {
        newUser.nickname = payload.data.nickname;
        newUser.nicknameToShow =
          payload.data.nickname ||
          (payload.data.email && payload.data.email !== null && payload.data.email.split('@')[0]) ||
          payload.data.id;
      }

      // Обновляются уведомления
      if (payload.type === 'notifies') {
        newUser.notifies = {
          ...newUser.notifies,
          data: newUser.notifies.data.filter((notify) => notify.id !== payload.data),
        };
      }

      // Обновляются уведомления
      if (payload.type === 'read-notifies') {
        newUser.notifies = {
          ...newUser.notifies,
          data: newUser.notifies.data.map((notify) => ({ ...notify, isReaded: true })),
        };
      }

      // Обновляются favorites
      if (payload.type === 'favorites') {
        newUser.favorites = { ...payload.data };
      }

      if (payload.type === 'email') {
        newUser.email = payload.data;
      }

      // Обновляется статус подтверждения почты
      if (payload.type === 'emailApproved') {
        newUser.emailApproved = payload.data;
      }

      return {
        ...state,
        user: newUser,
      };
    }

    case 'SET_LOGGED': {
      return {
        ...state,
        logged: payload,
      };
    }

    case 'SAVE_USER_DATA': {
      // Если передано changedField, то меняется только одно поле объекта user,
      // если нет, то весь объект
      let newUser;
      if (payload.changedField) {
        newUser = state.user.clone();
        newUser[payload.changedField] = payload.data[payload.changedField];

        // Хак, чтобы аватарка обновилась при изменении, т.к. путь до нее не меняется
        const curAvatarPath = newUser.avatarPath;
        if (payload.changedField === 'avatarPath')
          newUser.avatarPath = `${curAvatarPath}?${uniqid()}`;
      }

      return {
        ...state,
        userId: payload.userId,
        user: payload.changedField ? newUser : payload.data,
        notifyCount: payload.notifyCount,
        logged: !!payload.logged,
      };
    }

    case 'SET_USER_ID': {
      Sentry.configureScope((scope) => {
        scope.setUser({ id: payload });
      });

      jitsu.id({
        "internal_id": payload
      }, true)

      return {
        ...state,
        user: { ...state.user, id: payload },
      };
    }

    case 'SET_IS_NEED_REQUEST': {
      return {
        ...state,
        isNeedRequest: payload,
      };
    }

    case 'SAVE_CODES': {
      return {
        ...state,
        codes: payload,
      };
    }

    case 'SET_NOTIFY_COUNT': {
      return {
        ...state,
        notifyCount: payload,
      };
    }

    case 'SAVE_BALANCE': {
      return {
        ...state,
        user: { ...state.user, balance: { data: payload } },
      };
    }

    case 'SAVE_COUNTRY': {
      return {
        ...state,
        user: {
          ...state.user,
          delivery_countries: {
            label: payload.label,
            value: payload.shortName,
            isFilter: payload.isFilter,
            isDelivery: payload.isDelivery,
            phoneCode: payload.phoneCode,
          },
        },
      };
    }

    default:
      return state;
  }
};
