import BaseModel from '../base/base-model';

export default class Categories extends BaseModel {
  resourceName() {
    return 'categories';
  }

  fields() {
    return ['name', 'alias', 'sort', 'icon', 'background', 'seoText', 'withPromocodes'];
  }
}
