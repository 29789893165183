import Programs from '../../../json-api/models/programs/programs';
import Descriptions from '../../../json-api/models/programs/descriptions/descriptions';
import isNode from '../../../helpers/utils/is-node';
import ProgramsWithTokens from '../../../json-api/models/programs/programs-with-tokens';

export default async function getProgramDetails({ getState }) {
  const url = isNode() ? getState().appReducer.nodeUrl : window.location.pathname;
  const parts = url.trim().split('/');
  const uri = parts[parts.length - 2].trim();
  const programsModel =
    getState().user.logged && !isNode() ? new ProgramsWithTokens() : new Programs();
  const { data: programs } = await programsModel
    .where('uriCode', uri.replace('-keshbek', ''))
    .with(['cashback_rates', 'promocodes', 'currencies', 'program_misc'])
    .get();

  if (programs.length === 0) {
    // 404
    return {
      redirect: {
        code: '404',
        path: `/404/`,
      }
    };
  }

  const programId = programs[0].id;

  // Основная информация магазина
  const detailData = { ...programs[0], ...programs[0].program_misc };

  detailData.h1 = detailData.h1?.replace('{%SHOP_NAME%}', detailData.name);
  detailData.title = detailData.title?.replace('{%SHOP_NAME%}', detailData.name);
  detailData.metaDescription = detailData.metaDescription?.replace(
    '{%SHOP_NAME%}',
    detailData.name
  );

  detailData.h1 = detailData.h1?.replace('{%META_TITLE_VALUE%}', '');
  detailData.title = detailData.title?.replace('{%META_TITLE_VALUE%}', '');
  detailData.metaDescription = detailData.metaDescription?.replace('{%META_TITLE_VALUE%}', '');

  const descrModel = new Descriptions();
  // Дополнительная информация магазина
  const shopDescr = await descrModel.find(programId);

  if (!uri.includes('-keshbek')) {
    return {
      detailData,
      shopDescr,
      uri,
      redirect: {
        code: '301',
        path: `/shops/details/${encodeURIComponent(uri)}-keshbek/`
      }
    };
  }

  return { detailData, shopDescr, uri, redirect: null };
}
