import ModelWithTokens from '../base/base-model-with-tokens';
import PayTargets from '../pay-request/pay-targets';
import PayObjects from '../pay-request/pay-objects';
import ProgramMisc from '../programs/program-misc';
import Currencies from '../pay-request/currencies';
import { ITEMS_PER_PAGE_COUNT } from '../../../../constants/history';

export default class Payout extends ModelWithTokens {
  resourceName() {
    return 'payouts';
  }

  fields() {
    return [
      'account',
      'amount',
      'amountRub',
      'commission',
      'currencyId',
      'payFields',
      'payObjectId',
      'payoutStamp',
      'statusId',
      'targetId',
    ];
  }

  get(config) {
    this.queryBuilder.paginate(ITEMS_PER_PAGE_COUNT);
    return this.makeFetchRequest(
      `${this.baseUrl()}/users/${this.userId}/payouts/${this.queryBuilder.getQuery()}`,
      config,
    );
  }

  getMore(config) {
    this.queryBuilder.paginate(ITEMS_PER_PAGE_COUNT, this.next);
    return this.makeFetchRequest(
      `${this.baseUrl()}/payouts/${this.queryBuilder.getQuery()}`,
      config,
    );
  }

  delete(config) {
    return this.makePersistRequest({
      url: `${this.baseUrl()}/payouts/${this.id}`,
      method: 'DELETE',
      ...config,
    });
  }

  relationships() {
    return {
      payTargets: new PayTargets(),
      program_misc: new ProgramMisc(),
      currencies: new Currencies(),
      payObjects: new PayObjects(),
    };
  }
}
