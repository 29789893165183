import React from 'react';
import loadable from '@loadable/component';

const Redactor = loadable(() =>
  import(/* webpackChunkName: "blog-redactor" */ '../components/blog/redactor/redactor')
);

export default function RedactorPage() {
  return <Redactor />;
}
