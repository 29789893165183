import React from 'react';
import getDate from './get-date';
import './article-info.scss';
import 'dayjs/locale/ru';

interface Props {
  theme?: string;
  publishTimestamp?: number;
  isDraft?: boolean;
}

export default function ArticleInfo({ theme, publishTimestamp, isDraft }: Props) {
  if (!theme && !publishTimestamp) return null;
  const date = getDate(publishTimestamp, isDraft);

  return (
    <div className="blog__item-data">
      <div className="item-data__category">{theme}</div>
      <div className="item-data__date">{date}</div>
    </div>
  );
}
