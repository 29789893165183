import React from "react";
import loadable from "@loadable/component";

const ProfileContainer = loadable(() =>
  import(
    /* webpackChunkName: "profile" */ "../../components/profile/profile-container"
  )
);

export default function Profile() {
  return <ProfileContainer />;
}
