import TicketThemes from '../../../json-api/models/tickets/ticket-themes';

export default async function getTicketThemes() {
  const themes = new TicketThemes();
  const themesData = await themes.get();
  const themesObj = {};
  themesData.data.forEach(theme => {
    let fieldsObj = null;
    if (theme.ticket_fields.data) {
      fieldsObj = {};
      theme.ticket_fields.data.forEach(field => {
        fieldsObj[field.id] = field;
      });
    }

    themesObj[theme.id] = {
      label: theme.label,
      fields: fieldsObj
    };
  });

  return themesObj;
}
