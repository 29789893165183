import SocialLinks from "../../../json-api/models/auth/social-links";

export default async function getSocialLinks() {
  const social = new SocialLinks();
  let socialData = await social.get();
  // id для спрайта svg
  socialData = socialData.data.map(soc => {
    switch (soc.id) {
      /*case "fb":
        soc.svgId = "facebook";
        return soc;*/
      case "ok":
        soc.svgId = "odnoklassniki";
        return soc;
      case "vk":
        soc.svgId = "vk";
        return soc;
      case "ya":
        soc.svgId = "yandex";
        return soc;
      case "apple":
        soc.svgId = "apple";
        return soc;
      case "google":
        soc.svgId = "google";
        return soc;
      default:
        break;
    }
    return soc;
  });

  return socialData;
}
