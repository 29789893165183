export default function setMetaAttribute({ attributeName, attributeValue, content }) {
  let tag = document.querySelector(`meta[${attributeName}="${attributeValue}"]`);
  if (!tag) {
    const createdDescription = document.createElement('meta');
    createdDescription[attributeName] = attributeValue;
    document.head.appendChild(createdDescription);
    tag = createdDescription;
  }

  if (content) tag.setAttribute('content', content);
}
