import showLoader from '../../../../helpers/utils/show-loader';
import AuthBy from '../../../../json-api/models/admin/auth-by';
import { captureSentryError } from '../../../../helpers/utils/capture-sentry-browser';

export default async function requestAccessForAdmin({ userId }) {
  showLoader(true);
  try {
    const authByModel = new AuthBy();
    const { accessToken } = await authByModel.find(userId);
    return accessToken;
  } catch (err) {
    captureSentryError(new Error('Unable to auth by user'));
  } finally {
    showLoader(false);
  }
}
