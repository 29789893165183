import ModelWithTokens from '../base/base-model-with-tokens';
import ProgramMisc from '../programs/program-misc';
import store from '../../../../store-config';
import { ITEMS_PER_PAGE_COUNT } from '../../../../constants/history';

export default class Sale extends ModelWithTokens {
  resourceName() {
    return 'sales';
  }

  fields() {
    return [
      'orderId',
      'programId',
      'programLogoPath',
      'saleStamp',
      'approveStamp',
      'moneyStamp',
      'updateStamp',
      'amount',
      'amountCurrencySign',
      'cashback',
      'cashbackCurrencySign',
      'cashbackRate',
      'cashbackRateType',
      'statusId',
      'basket',
      'cashbackRub',
    ];
  }

  get(config) {
    this.queryBuilder.paginate(ITEMS_PER_PAGE_COUNT);
    return this.makeFetchRequest(
      `${this.baseUrl()}/users/${this.userId}/sales/${this.queryBuilder.getQuery()}`,
      config,
    );
  }

  getMore(config) {
    this.queryBuilder.paginate(ITEMS_PER_PAGE_COUNT, this.next);
    return this.makeFetchRequest(
      `${this.baseUrl()}/users/'}${this.userId}/sales/${this.queryBuilder.getQuery()}`,
      config,
    );
  }

  delete(config) {
    const data = {
      data: [
        {
          type: 'sales',
          id: this.id,
        },
      ],
    };
    return this.makeFetchRequest(
      `${this.baseUrl()}/users/${this.userId}/sales/`,
      config,
      'DELETE',
      data,
    );
  }

  relationships() {
    return {
      program_misc: new ProgramMisc(),
    };
  }
}
