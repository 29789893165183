import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../shared/button/button';
import './program-card.scss';
import Image from '../../shared/image/image';
import '../../promocode/promocode-card/promocode-card.scss';
import Icon from '../../shared/icon/icon';

function ProgramCard({
  logo,
  isFav,
  isRateMaxPercent,
  rateMaxSize,
  uriCode,
  isOneRate,
  isGoto,
  id,
  onBottomTextClick,
  onClickFav,
  noFlat,
  noFav,
  className,
  disabled,
  buttonDisabled,
  messageUnderButton,
  hasPromocodes
}) {
  return (
    <CardWrapper
      type={isGoto ? 'goto' : ''}
      onBottomTextClick={onBottomTextClick}
      id={id}
      uriCode={uriCode}
      noFlat={noFlat}
      className={className}
      disabled={disabled}
      buttonDisabled={buttonDisabled}
      messageUnderButton={messageUnderButton}
      hasPromocodes={hasPromocodes}
    >
      <div className={`card__logo-wrapper card__logo-wrapper${noFav ? '_no-fav' : ''}`}>
        <Image className="card__logo" src={logo} alt="" />
        {/* <img className="card__logo" src={logo} alt="" /> */}
      </div>
      <div className="card__info">
        {!disabled && (
          <div className="card__cb">
            <div className="cb-title">Кэшбэк</div>
            <div className="cb-amount fix-ruble">
              {!isOneRate ? 'до ' : ' '}
              {`${rateMaxSize}${isRateMaxPercent ? '%' : ' ₽'}`}
            </div>
          </div>
        )}
        {!noFav && (
          <svg
            className={`cb-fav ${isFav ? 'on' : ''}`}
            onClick={(e) => {
              e.preventDefault();
              onClickFav(id);
            }}
          >
            <use xlinkHref="/assets/svg/sprite-v2.svg#heart" />
          </svg>
        )}
      </div>
      {/* {id === '72' && (
        <>
          <Image className="card__badge card__badge_l" src="/assets/svg/ali-badge/badge.svg" />
          <Image
            className="card__badge card__badge_s"
            src="/assets/svg/ali-badge/badge-small.svg"
          />
        </>
      )} */}
    </CardWrapper>
  );
}

const CardWrapper = ({
  children,
  type,
  id,
  onBottomTextClick,
  className,
  disabled,
  uriCode,
  buttonDisabled,
  messageUnderButton,
  isFav,
  logo,
  promocodesCount,
  onClickFav,
  hasPromocodes,
  ...props
}) => {
  if (type === 'goto') {
    return (
      <div className="card_goto-wrapper">
        <div className={`card card_goto ${className || ''}`}>
          {children}
          {!disabled && (
            <>
              {buttonDisabled ? (
                <Button
                  type="arrow-right"
                  className="button_with-arrow goto-button"
                  disabled={buttonDisabled}
                >
                  Перейти в магазин
                </Button>
              ) : (
                <Link to={`/user/goto/${id}/`} className="goto-button" target="_blank">
                  <Button
                    type="arrow-right"
                    className="button_with-arrow "
                    disabled={buttonDisabled}
                  >
                    Перейти в магазин
                  </Button>
                </Link>
              )}

              {messageUnderButton && (
                <div className="card__btn-text">
                  <Icon id="shop-warning" className="card__btn-text_icon" />
                  <div>
                    <p style={{ marginBottom: 0 }}>{messageUnderButton}</p>
                  </div>
                </div>
              )}
              <div className="card__bottom-text" onClick={onBottomTextClick}>
                Прочитайте <span className="blue-text">правила покупок с кэшбэком</span>
              </div>
            </>
          )}
        </div>
        {hasPromocodes && (
          <Link
            to={`/promocode/details/${encodeURIComponent(uriCode)}/`}
            className="promocode-card promocode-card_vertical details-promo with-shadow"
          >
            <Icon id="promocode-percent" />
            <span>Промокоды</span>
          </Link>
        )}
        {!hasPromocodes && (
          <div className="promocode-card promocode-card_vertical details-promo with-shadow">
            <Icon id="promocode-percent" />
            <span>Промокодов пока нет :(</span>
          </div>
        )}
      </div>
    );
  }

  return (
    <Link
      to={`/shops/details/${encodeURIComponent(uriCode)}/`}
      className={`card  ${props.noFlat ? '' : 'flat-mobile'} ${className || ''}`}
    >
      {children}
    </Link>
  );
};

export default ProgramCard;
