import React from 'react';
import loadable from '@loadable/component';
import dataController from '../data-controller/data-controller';

const Shop = loadable(() =>
  import(/* webpackChunkName: "details" */ '../components/shops/details')
);

function DetailsPage({ setMeta }) {
  return <Shop setMeta={setMeta} />;
}

DetailsPage.serverFetch = ({ store }) => {
  return dataController.get(['programDetails'], store);
};

export default DetailsPage;
