import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import './dropdown.scss';

const propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  defaultValue: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  customStyle: PropTypes.object,
};

const defaultProps = {
  defaultValue: null,
};

export default function Dropdown({
  className,
  label,
  options,
  defaultValue,
  placeholder,
  onChange,
  customStyle,
}) {
  const getCurrentValue = () => {
    if (defaultValue && options) {
      return options.find((option) => option.label === defaultValue.label);
    }

    return null;
  };

  const customStyles = customStyle || {
    menu: (provided) => ({
      ...provided,
      borderRadius: '20px',
      padding: '16px 0px',
      marginTop: '15px',
      boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
      zIndex: '101',
    }),
    option: (provided, state) => ({
      padding: '10px 24px',
      cursor: 'pointer',
      fontSize: '16px',
      color: '#646479',
      height: '41px',
      backgroundColor: state.isSelected ? '#F7F7F8' : null,
      fontWeight: state.isSelected ? '500' : null,
      zIndex: '101',

      ':active': {
        backgroundColor: '#F7F7F8',
        fontWeight: '500',
      },

      ':hover': {
        backgroundColor: '#F7F7F8',
      },
    }),
    control: (styles) => ({
      // none of react-select's styles are passed to <Control />
      border: '2px solid #B1B1BB',
      boxSizing: 'border-box',
      borderRadius: '61px',
      fontSize: '16px',
      display: 'flex',
      height: '66px',
      padding: '0px 14px',
      zIndex: '101',

      ':focus': {
        border: '2px solid #B1B1BB',
      },

      ':active': {
        border: '2px solid #1D7BCD',
      },

      ':hover': {
        border: '2px solid #1D7BCD',
      },
    }),
    input: () => ({
      height: '66px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
  };

  const placeholderWithCountry =
    defaultValue && defaultValue.label ? defaultValue.label : placeholder;

  return (
    <div className="dropdown">
      {label && <span className="dropdown-label">{label}</span>}
      <Select
        className={`dropdown-select ${className}`}
        classNamePrefix="dropdown"
        isSearchable={false}
        options={options}
        value={getCurrentValue()}
        placeholder={placeholderWithCountry}
        styles={customStyles}
        inputProps={{ readOnly: true }}
        onChange={onChange}
      />
    </div>
  );
}

Dropdown.propTypes = propTypes;
Dropdown.defaultProps = defaultProps;
