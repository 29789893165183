import ApiModel from 'src/json-api/framework/api-model';
import Notifies from 'src/json-api/_models/user/notifies';
import User from 'src/json-api/_models/user/user';

export default class NotifiesTransport {
  userModel;

  notifiesModel = new ApiModel<Notifies>(Notifies);

  constructor() {
    const model = new ApiModel<User>(User);
    this.userModel = model;
  }

  get = () => this.userModel.getRelations(Notifies);

  update(id: string, data: Notifies['fields']) {
    this.notifiesModel.id = id;
    this.notifiesModel.fields = data;
    return this.notifiesModel.update();
  }

  delete(id: string) {
    this.notifiesModel.id = id;
    return this.notifiesModel.delete(id);
  }
}
