import BaseModel from '../base/base-model';

export default class SocialLinks extends BaseModel {
  resourceName() {
    return 'social_links';
  }

  fields() {
    return ['siteLink', 'link'];
  }
}
