import React from 'react';
import './button.scss';

export interface ButtonProps {
  children: string | React.ReactNode;
  type?:
    | 'default'
    | 'orange'
    | 'file'
    | 'no-border'
    | 'bullet'
    | 'with-corner'
    | 'arrow-right'
    | 'empty'
    | 'rounded'
    | 'white';
  className?: string;
  size?: 'm' | 'l' | 's';
  disabled?: boolean;
  isLoading?: boolean;
  active?: boolean;
  withArrow?: boolean;
  onClick: (cb: any) => void;
}

export default function Button({
  children,
  type = 'default',
  className = '',
  size = 'm',
  disabled = false,
  isLoading = false,
  active,
  withArrow,
  onClick,
  ...props
}: ButtonProps): React.ReactElement {
  return (
    <button
      {...props}
      type="button"
      className={`main-button main-button_${type} ${className} main-button_${size} ${
        disabled ? 'main-button_disabled' : ''
      } ${isLoading ? 'main-button_loading' : ''} ${active ? 'active' : ''}`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
      {(type === 'arrow-right' || withArrow) && (
        <svg className="main-button__right">
          <use xlinkHref="/assets/svg/sprite-v2.svg#next" />
        </svg>
      )}
      {type === 'with-corner' && <img src="/assets/svg/promocodes/corner.png" alt="" />}
    </button>
  );
}
