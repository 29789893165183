/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from '../shared/button/button';
import MailIcon from './mail-icon';
import showToast from '../../helpers/utils/show-toast';
import User from '../../json-api/models/user/user';

import './approve-email-notify.scss';

ApproveEmailNotify.propTypes = {
  email: PropTypes.string,
  userId: PropTypes.string
};

ApproveEmailNotify.defaultProps = {
  email: '',
  userId: ''
};

function ApproveEmailNotify({ email, userId }) {
  const [validation, setValidation] = useState('');

  async function onSendEmail() {
    const userModel = new User();
    userModel.id = userId;
    userModel.email = email;
    try {
      await userModel.update();
      showToast('Письмо отправлено');
    } catch (err) {
      if (err.errors) setValidation(err.errors[0].detail);
    }
  }

  return (
    <div className="bottom-notify">
      <MailIcon />
      <div className="bottom-notify__text">
        Подтвердите свой email, чтобы получать уведомления о кэшбэке.
        <br /> Mы отправили письмо на <span>{email}</span>
      </div>
      <div className="approve-email__btns">
        <Link to="/user/profile/">
          <Button size="s" className="bottom-notify__btn" type="no-border">
            Это не мой email
          </Button>
        </Link>
        <Button size="s" className="bottom-notify__btn" type="no-border" onClick={onSendEmail}>
          Отправить повторно
        </Button>
      </div>
      <div className="input__error-message">{validation}</div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  udateUserEmail: (email) =>
    dispatch({ type: 'UPDATE_USER', payload: { type: 'email', data: email } })
});

export default connect(mapDispatchToProps)(ApproveEmailNotify);
