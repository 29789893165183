import React from 'react';
import { Link } from 'react-router-dom';

export default function Agreement() {
  return (
    <div className="footer__docs">
      <Link to="/agreement/#eula">Пользовательское соглашение</Link>
      <Link to="/agreement/#terms-and-conditions">Политика конфиденциальности</Link>
      <Link to="/agreement/#cookie">Политика использования файлов cookie</Link>
    </div>
  );
}
