import User from '../../../json-api/models/user/user';
import Tickets from '../../../json-api/models/tickets/tickets';

export default async function getTickets({ getState }) {
  const { user } = getState();
  const userModel = new User();
  userModel.id = user.user.id;
  const { data } = await userModel.getRelated(new Tickets(), {});
  return data.map(({ id, attributes }) => ({ id, ...attributes }));
}
