import ModelWithTokens from '../base/base-model-with-tokens';

export default class UserPrograms extends ModelWithTokens {
  resourceName() {
    return 'user_programs';
  }

  fields() {
    return ['name'];
  }
}
