/* eslint-disable react/display-name */
import qs from 'qs';
import React from 'react';

import Auth from '../pages/user/auth';
import Reg from '../pages/user/reg';
import SocialAuth from '../pages/user/social-auth';
import Profile from '../pages/user/profile';
import AttachPhone from '../pages/user/attach-phone';
import DeleteUser from '../pages/user/delete';
import CodesPage from '../pages/user/codes';
import NotificationsPage from '../pages/user/notifications';
import GotoPage from '../pages/user/goto';
import HistoryPage from '../pages/user/history';
import SupportPage from '../pages/user/support';
import SupportNew from '../pages/user/support-new';
import SupportDialog from '../pages/user/support-dialog';
import PayRequsetPage from '../pages/user/pay-request';
import ApproveRegRage from '../pages/user/approve-reg';
import PwdRecoveryPage from '../pages/user/pwd-recovery';
import ForgotPassPage from '../pages/user/forgot-pass';
import PhoneAttachPage from '../pages/user/phone-attach';
import AuthByPage from '../pages/user/auth-by';
import ApproveSubPage from '../pages/user/approve-sub';

const userRoutes = [
  // для мобильных приложений
  {
    path: '/user/auth/?*m=1*',
    component: Auth,
    exact: false,
    title: 'Авторизация | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: true,
    hideHeaderFooter: true
  },
  {
    path: '/user/reg/?*m=1*',
    component: Reg,
    exact: false,
    title: 'Регистрация | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: true,
    hideHeaderFooter: true
  },
  {
    path: '/user/pwd_recovery/?*m=1*',
    component: PwdRecoveryPage,
    exact: false,
    title: 'Восстановление пароля | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: true,
    hideHeaderFooter: true
  },
  {
    path: '/user/auth_social/?*m=1*',
    component: SocialAuth,
    exact: false,
    title: 'Авторизация | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: true,
    hideHeaderFooter: true
  },
  {
    path: ['/user/auth/', '/user/auth/?*'],
    component: Auth,
    exact: true,
    title: 'Авторизация | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: true,
    loggedOnly: false,
    notLoggedOnly: true,
    isAuth: true
  },
  {
    path: ['/user/reg/', '/user/reg/?*'],
    component: Reg,
    exact: true,
    title: 'Регистрация | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: true,
    loggedOnly: false,
    notLoggedOnly: true
  },
  {
    path: ['/user/pwd_recovery/', '/user/pwd_recovery/?*'],
    component: PwdRecoveryPage,
    exact: true,
    title: 'Восстановление пароля | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: true,
    loggedOnly: false,
    notLoggedOnly: true
  },
  {
    path: ['/user/auth_social/', '/user/auth_social/?*'],
    component: SocialAuth,
    exact: true,
    title: 'Авторизация | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: true,
    loggedOnly: false,
    notLoggedOnly: true
  },

  // Удаление пользователя
  {
    path: ['/user/profile/delete/', '/user/profile/delete/?*'],
    component: DeleteUser,
    exact: true,
    title: 'Удаление пользователя | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: true,
    loggedOnly: true,
    notLoggedOnly: false
  },
  // Настройки профиля
  {
    path: ['/user/profile/', '/user/profile/?*'],
    component: Profile,
    exact: true,
    title: 'Настройки профиля | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: true,
    loggedOnly: true,
    notLoggedOnly: false
  },
  // Настройки профиля, прикрепить телефон
  {
    path: ['/user/attach_phone/', '/user/attach_phone/?*'],
    component: AttachPhone,
    exact: true,
    title: 'Настройки профиля | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: true,
    loggedOnly: true,
    notLoggedOnly: false
  },
  // Кэшбэк-коды
  {
    path: ['/user/codes/', '/user/codes/?*'],
    component: CodesPage,
    exact: true,
    title: 'Кэшбэк-коды | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: true,
    loggedOnly: true,
    notLoggedOnly: false
  },
  // Уведомления
  {
    path: ['/user/notifications/', '/user/notifications/?*'],
    component: NotificationsPage,
    exact: true,
    title: 'Уведомления | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: true,
    loggedOnly: true,
    notLoggedOnly: false
  },
  // Переход
  {
    path: ['/user/goto/:id/', '/user/goto/:id/?*'],
    component: GotoPage,
    exact: false,
    title: 'Переход | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: true,
    loggedOnly: false,
    notLoggedOnly: false,
    hideHeaderFooter: true,
    isGoto: true
  },
  // История
  {
    path: ['/user/history/', '/user/history/?*'],
    component: HistoryPage,
    exact: true,
    title: 'История | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: true,
    loggedOnly: true,
    notLoggedOnly: false
  },

  // Поддержка, создание заявки
  {
    path: '/user/support/new/*',
    component: SupportNew,
    exact: true,
    title: 'Поддержка | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: true,
    loggedOnly: true,
    notLoggedOnly: false
  },
  // Поддержка, диалог
  {
    path: ['/user/support/dialog/:id/', '/user/support/dialog/:id/?*'],
    component: SupportDialog,
    exact: true,
    title: 'Поддержка | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: true,
    loggedOnly: true,
    notLoggedOnly: false
  },
  // Поддержка, список заявок
  {
    path: ['/user/support/', '/user/support/?*'],
    component: SupportPage,
    exact: true,
    title: 'Поддержка | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: true,
    loggedOnly: true,
    notLoggedOnly: false
  },
  // Вывод средств
  {
    path: ['/user/payout/', '/user/payout/?*'],
    component: PayRequsetPage,
    exact: true,
    title: 'Вывод средств | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: true,
    loggedOnly: true,
    notLoggedOnly: false
  },
  // Регистрация, подтверждение
  {
    path: ['/user/approve/reg/', '/user/approve/reg/?*'],
    component: ApproveRegRage,
    exact: true,
    title: 'Регистрация | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: true,
    loggedOnly: false,
    notLoggedOnly: false
  },
  // Забыли пароль, переход из почты
  {
    path: ['/user/approve/pwd_recovery/', '/user/approve/pwd_recovery/?*'],
    component: () => {
      const params = qs.parse(document.location.search.slice(1));
      return (
        <ForgotPassPage
          tokenFromLink={params.token && params.token.replace('/', '')}
          // typeFromLink={params.token && params.type.replace('/', '')}
        />
      );
    },
    exact: false,
    title: 'Восстановление пароля | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: true,
    loggedOnly: false,
    notLoggedOnly: false
  },
  // Привязка телефона, переход из почты
  {
    path: [
      '/user/approve/current_email/',
      '/user/approve/new_email/',
      '/user/approve/current_email/?*',
      '/user/approve/new_email/?*'
    ],
    component: PhoneAttachPage,
    exact: true,
    title: 'Подтверждение почты | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: true
  },
  {
    path: '/user/auth_by/*',
    component: AuthByPage,
    exact: true,
    title: 'Авторизация | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: true,
    loggedOnly: false,
    notLoggedOnly: false,
    hideHeaderFooter: true
  },
  {
    path: ['/user/approve/adv_subscribe_email/', '/user/approve/adv_subscribe_email/?*'],
    component: ApproveSubPage,
    exact: true,
    title: 'Подтверждение почты | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: true,
    loggedOnly: false,
    notLoggedOnly: false,
    hideHeaderFooter: true
  }
];

export default userRoutes;
