import BaseModel from '../../base/base-model';

export default class GeneralConditions extends BaseModel {
  resourceName() {
    return 'general_conditions';
  }

  fields() {
    return ['text', 'title'];
  }
}
