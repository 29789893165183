import React from 'react';
// import { LazyImage } from 'react-lazy-images';

export default function Image({ src, lowResSrc = '', alt, ...props }) {
  return (
    // <LazyImage
    //   src={src}
    //   alt={alt}
    //   actual={({ imageProps }) => <img {...imageProps} />}
    //   placeholder={({ imageProps, ref }) => <img ref={ref} src={lowResSrc} alt={imageProps.alt} />}
    //   {...props}
    // />
    <img src={src} alt={alt} {...props} />
  );
}
