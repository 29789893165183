import ProgramsWithTokens from 'src/json-api/models/programs/programs-with-tokens';
import ProgramCard from '../../../components/program/program-list/program-card';
import Programs from '../../../json-api/models/programs/programs';

export default async function fetchProgramsByIds(ids, logged) {
  const programModel = logged ? new ProgramsWithTokens() : new Programs();
  const request = programModel
    .with(['countries', 'categories', 'program_misc', 'promocodes'])
    .where('id', ids.join(','));
  const { data } = await request.get();

  return data.map((item) => {
    item.logo = item.program_misc.logo;
    item.name = item.program_misc.name;
    return item;
  });
}
