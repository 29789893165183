import ModelWithTokens from '../base/base-model-with-tokens';

export default class Balance extends ModelWithTokens {
  resourceName() {
    return 'balance';
  }

  fields() {
    return ['waiting', 'available'];
  }
}
