import React from 'react';
import loadable from '@loadable/component';

const Notifications = loadable(() =>
  import(/* webpackChunkName: "notifications" */ '../../components/notifications/notifications')
);

export default function NotificationsPage() {
  return <Notifications />;
}
