import { Entity } from 'src/json-api/framework/types';
import SiteApi from '../base/site-api';

export default class Balance extends SiteApi implements Entity {
  resourceName = 'balance';

  fields: Partial<{
    waiting: string;
    available: string;
  }>;

  relations: {};

  responseDelete: never;

  responseCreate: never;

  responseUpdate: never;
}
