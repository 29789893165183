import BaseModel from '../base/base-model';

export default class ProgramMisc extends BaseModel {
  resourceName() {
    return 'program_misc';
  }

  fields() {
    return ['logo', 'name'];
  }
}
