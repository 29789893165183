import axios from 'axios';
import * as Sentry from '@sentry/browser';
import Model from '../../sarala/Model';
import { captureSentryError } from '../../../helpers/utils/capture-sentry-browser';
import isNode from '../../../helpers/utils/is-node';

export default class BaseModel extends Model {
  baseUrl() {
    return isNode() ? `http://${global.host}/api/json/1.0` : '/api/json/1.0';
  }

  async request(config) {
    try {
      Sentry.addBreadcrumb({
        category: `${config.method}: ${config.url}`,
        data: config,
        message: 'request',
        level: 'info', // Sentry.Severity.Info,
        type: 'http',
      });

      config.headers = {
        ...config.headers,
        client: 'site',
        'client-version': isNode() ? 'server' : 'browser'
      };

      return axios
        .request(config)
        .then((res) => {
          Sentry.addBreadcrumb({
            category: `Response of ${config.method}: ${config.url}`,
            data: res,
            message: 'request',
            level: 'info', //Sentry.Severity.Info,
            type: 'http',
          });

          if (!res.data && res.status !== 204 && res.status !== 202)
            res.data = {
              errors: [{ detail: 'Произошла ошибка, попробуйте еще раз' }]
            };

          return res;
        })
        .catch((error) => error.response);
    } catch (err) {
      captureSentryError(new Error(err));
    }
  }
}
