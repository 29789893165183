import store from '../../../../store-config';
import isNode from '../../../helpers/utils/is-node';

export default function getPromocodeDetails() {
  const uri = getUri();
  const promocodeNameArray = uri ? uri.split('-') : [];
  const promocodeId = promocodeNameArray[promocodeNameArray.length - 1];
  if (!promocodeId)
    return {
      promocode: null,
      redirect: {
        code: '404',
        path: `/404/`,
        uri
      }
    };

  let curPromocode;
  const curProgram = store.getState().programs.programsData.find((program) => {
    if (program.promocodes.data.length === 0) return false;
    curPromocode = program.promocodes.data.find(({ id }) => id === promocodeId);
    if (curPromocode) return true;
    return false;
  });

  if (!curPromocode)
    return {
      promocode: null,
      redirect: {
        code: '404',
        path: `/404/`,
        uri
      }
    };

  return {
    promocode: { ...curProgram, programId: curProgram.id, ...curPromocode },
    allPromocodes: curProgram.promocodes.data.filter(({ id }) => id !== promocodeId)
  };
}

function getUri() {
  const url = isNode() ? store.getState().appReducer.nodeUrl : window.location.pathname;
  const parts = url.trim().split('/');
  let uri = parts[parts.length - 2].trim();
  if (uri === 'details') uri = parts[parts.length - 1].trim();
  return uri;
}
