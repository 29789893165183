import Programs from '../../../json-api/models/programs/programs';

export default async function getWelcomePrograms() {
  const shopIds = [
    72, // aliexpress
    6069, // Beru
    233, // Booking
    936, // mvideo
    420, // ebay
    656, // itunes
    5923, // eldo
    619, // iHerb
    1313, // sportmaster
    555 // goods
  ];
  const programsModel = new Programs();
  const requests = shopIds.map((id) => programsModel.find(id));

  const programsData = await Promise.all(requests);
  const programsObject = {};
  programsData.forEach(({ id, ...data }) => {
    programsObject[id] = { id, ...data };
  });

  return shopIds.map((id) => programsObject[id]);
}
