import React from 'react';
import ReactLoading from 'react-loading';
import StyledReactSelect from '../styled-react-select/styled-react-select';
import './search-input-v2.scss';

function SearchInputV2({
  instanceId,
  inputValue,
  onInputChange,
  onChange,
  options,
  className,
  placeholder,
  isLoading,
  canShowNoOptions,
  filterOption,
  ...props
}) {
  return (
    <StyledReactSelect
      {...props}
      instanceId={instanceId}
      filterOption={(item) => item}
      inputValue={inputValue}
      onInputChange={onInputChange}
      blurInputOnSelect
      onChange={onChange}
      options={options}
      className={className}
      placeholder={placeholder}
      components={{
        NoOptionsMessage: () => (
          <div className={`search__no-option ${canShowNoOptions && inputValue ? 'show' : 'hide'} `}>
            Ничего не найдено
          </div>
        ),
        DropdownIndicator: () =>
          isLoading ? (
            <ReactLoading
              className="search search-loader"
              type="spinningBubbles"
              height="1.5em"
              width="1.5em"
              color="#e0e0e0"
            />
          ) : (
            <svg className="search">
              <use xlinkHref="/assets/svg/sprite-v2.svg#search" />
            </svg>
          ),
      }}
    />
  );
}

export default SearchInputV2;
