import ModelWithTokens from '../base/base-model-with-tokens';

export default class TicketFields extends ModelWithTokens {
  resourceName() {
    return 'ticket_fields';
  }

  fields() {
    return ['fieldType', 'label', 'mask', 'options', 'placeholder', 'reference'];
  }
}
