import React from 'react';
import HelpPage from '../pages/help';
import IndexPage from '../pages';
import userRoutes from './user-router';
import AgreementRage from '../pages/agreement';
import ShopsCategoryPage from '../pages/shops-category';
import DetailsPage from '../pages/details';
import PromocodeDetailsPage from '../pages/promocode-details';
import PromocodesCategoryPage from '../pages/promocodes-category';
// import WelcomePage from '../pages/welcome';
import AboutPage from '../pages/about';
import BlogPage from '../pages/blog';
import MobileInfoPage from '../pages/mobile-info';
// import LandingPage from '../pages/landing';
import FavoritesPage from '../pages/favorites';
import RedactorPage from 'src/pages/redactor';
import ArticlePage from 'src/pages/blog-article';

const routes = [
  {
    // Путь
    path: ['/', '/?*'],
    // Компонент - страница
    component: IndexPage,
    // Точное совпадение роута с path
    exact: true,
    // meta.title
    title: 'СКИДКА РУ — кэшбэк, промокоды и скидки!',
    // Выключить server side rendering
    noSSR: false,
    // Для показа страницы быть обязательно авторизованным
    loggedOnly: false,
    // Для показа страницы нужно быть обязательно неавторизованым
    notLoggedOnly: false,
  },
  {
    path: '/help/agreement/*',
    component: AgreementRage,
    exact: true,
    title: 'Правовая информация | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: true,
    loggedOnly: false,
    notLoggedOnly: false,
    hideHeaderFooter: true,
  },
  {
    path: '/help/?*m=1*',
    component: HelpPage,
    exact: true,
    title: 'Вопросы и ответы | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: true,
    loggedOnly: false,
    notLoggedOnly: false,
    hideHeaderFooter: true,
  },
  {
    path: '/help/',
    component: HelpPage,
    exact: true,
    title: 'Вопросы и ответы | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: false,
    loggedOnly: false,
    notLoggedOnly: false,
    hideHeaderFooter: false,
  },
  {
    path: '/agreement/?*m=1*',
    component: AgreementRage,
    exact: true,
    title: 'Правовая информация | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: true,
    loggedOnly: false,
    notLoggedOnly: false,
    hideHeaderFooter: true,
  },
  {
    path: '/agreement/',
    component: AgreementRage,
    exact: true,
    title: 'Правовая информация | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: false,
    loggedOnly: false,
    notLoggedOnly: false,
    hideHeaderFooter: false,
  },
  {
    path: '/about/?*m=1*',
    component: AboutPage,
    exact: true,
    title: 'О компании | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: true,
    loggedOnly: false,
    notLoggedOnly: false,
    hideHeaderFooter: true,
  },
  {
    path: '/about/',
    component: AboutPage,
    exact: true,
    title: 'О компании | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: false,
    loggedOnly: false,
    notLoggedOnly: false,
    hideHeaderFooter: false,
  },
  {
    path: ['/shops/details/:uri/', '/shops/details/:uri/?*'],
    component: (() => {
      const component = (props) => <DetailsPage {...props} />;
      component.serverFetch = DetailsPage.serverFetch;
      return component;
    })(),
    exact: true,
    noSSR: false,
    loggedOnly: false,
    notLoggedOnly: false,
  },
  {
    path: '/shops/fav/',
    component: FavoritesPage,
    exact: true,
    noSSR: true,
    loggedOnly: true,
    notLoggedOnly: false,
  },
  {
    path: ['/shops/:categoryName/', '/shops/:categoryName/?*', '/shops/', '/shops/?*'],
    component: (() => {
      const component = (props) => <ShopsCategoryPage {...props} />;
      component.serverFetch = ShopsCategoryPage.serverFetch;
      return component;
    })(),
    exact: true,
    noSSR: false,
    loggedOnly: false,
    notLoggedOnly: false,
    disableScrollTop: true,
  },
  {
    path: ['/promocode/details/:uri/', '/promocode/details/:uri/?*'],
    component: (() => {
      const component = (props) => <PromocodeDetailsPage {...props} />;
      component.serverFetch = PromocodeDetailsPage.serverFetch;
      return component;
    })(),
    exact: true,
    noSSR: false,
    loggedOnly: false,
    notLoggedOnly: false,
    disableScrollTop: true,
  },
  {
    path: [
      '/promocode/:categoryName/',
      '/promocode/:categoryName/*?',
      '/promocode/',
      '/promocode/?*',
    ],
    component: (() => {
      const component = (props) => <PromocodesCategoryPage {...props} />;
      component.serverFetch = PromocodesCategoryPage.serverFetch;
      return component;
    })(),
    exact: true,
    noSSR: false,
    loggedOnly: false,
    notLoggedOnly: false,
    disableScrollTop: true,
  },
  // {
  //   path: ['/plugin/', '/plugin/?*'],
  //   component: PluginPage,
  //   exact: true,
  //   title: 'Расширение для браузера | СКИДКА РУ — кэшбэк, промокоды и скидки!',
  //   noSSR: false,
  //   loggedOnly: false,
  //   notLoggedOnly: false
  // },
  // {
  //   path: '/welcome/',
  //   component: WelcomePage,
  //   exact: true,
  //   title: 'СКИДКА РУ — кэшбэк, промокоды и скидки!',
  //   noSSR: false,
  //   loggedOnly: false,
  //   notLoggedOnly: true,
  //   hideHeaderFooter: true
  // },
  {
    path: '/mobile_info/*',
    component: MobileInfoPage,
    exact: true,
    title: 'СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: true,
    loggedOnly: false,
    notLoggedOnly: false,
    hideHeaderFooter: true,
  },
  {
    path: ['/blog/redactor/', '/blog/redactor/?*'],
    component: RedactorPage,
    exact: true,
    title: 'Блог | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: true,
    loggedOnly: true,
    notLoggedOnly: false,
    hideHeaderFooter: false,
  },
  {
    path: ['/blog/:id/', '/blog/:id/?*'],
    component: ArticlePage,
    exact: true,
    noSSR: false,
    loggedOnly: false,
    notLoggedOnly: false,
    hideHeaderFooter: false,
  },
  {
    path: ['/blog/', '/blog/?*'],
    component: BlogPage,
    exact: true,
    title: 'Блог | СКИДКА РУ — кэшбэк, промокоды и скидки!',
    noSSR: false,
    loggedOnly: false,
    notLoggedOnly: false,
    hideHeaderFooter: false,
  },

  // {
  //   path: '/landing/',
  //   component: LandingPage,
  //   exact: true,
  //   title: 'СКИДКА РУ — кэшбэк, промокоды и скидки!',
  //   noSSR: false,
  //   loggedOnly: false,
  //   notLoggedOnly: true,
  //   hideHeaderFooter: false
  // },
  ...userRoutes,
  // {
  //   component: NotFound,
  //   path: '*',
  //   exact: false,
  //   title: '404',
  //   noSSR: false,
  //   loggedOnly: false,
  //   notLoggedOnly: false,
  //   hideHeaderFooter: false,
  //   httpCode: 404
  // }
];

export default routes;
