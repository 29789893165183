import BaseModel from '../../base/base-model';

export default class MaxholdDoneConditions extends BaseModel {
  resourceName() {
    return 'maxhold_done_conditions';
  }

  fields() {
    return ['text', 'title'];
  }
}
