import { Entity } from 'src/json-api/framework/types';
import SiteApi from '../base/site-api';

export default class Requests extends SiteApi implements Entity {
  resourceName = 'requests';

  alwaysWithAuth = true;

  fields: Partial<{
    requestType: string;
    subject: 'email_change' | 'email_attach';
    token: string;
    code: string;
  }>;

  responseDelete: never;

  responseCreate: { token: string };

  responseUpdate: { token: string };

  relations: {};
}
