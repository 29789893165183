/* eslint-disable react/no-danger */
import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import './article.scss';
import BreadCrumbs from '../shared/bread-crumbs/bread-crumbs';
import ArticleInfo from './article-info/article-info';

export default function ArticleHOC({
  imagePath,
  title,
  children,
  markup,
  theme,
  publishDate,
  isDraft,
}) {
  return (
    <Grid>
      <Row>
        <Col xs={12}>
          <BreadCrumbs pages={[{ title: 'Блог', url: '/blog/' }, { title }]} />
        </Col>
        <Col xs={12}>
          <div className="article">
            {imagePath && <img className="article__img" src={imagePath} alt={title} />}
            <div className="article__body">
              <ArticleInfo theme={theme} publishTimestamp={publishDate} isDraft={isDraft} />
              {title && <h1 dangerouslySetInnerHTML={{ __html: title }} />}
              {markup ? <div dangerouslySetInnerHTML={{ __html: markup }} /> : children}
            </div>
          </div>
        </Col>
      </Row>
    </Grid>
  );
}
