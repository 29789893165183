import dayjs from 'dayjs';
import 'dayjs/locale/ru';

const isToday = (timestamp: number) => {
  const today = dayjs(new Date());
  const thatDate = dayjs.unix(timestamp);

  return (
    today.get('year') === thatDate.get('year') &&
    today.get('month') === thatDate.get('month') &&
    today.get('date') === thatDate.get('date')
  );
};

const isSameYear = (timestamp: number) => {
  const today = dayjs(new Date());
  const thatDate = dayjs.unix(timestamp);

  return today.get('year') === thatDate.get('year');
};

const isYesterday = (timestamp: number) => {
  const today = dayjs(new Date());
  const yesterday = today.subtract(1, 'day');
  const thatDate = dayjs.unix(timestamp);

  return (
    yesterday.get('year') === thatDate.get('year') &&
    yesterday.get('month') === thatDate.get('month') &&
    yesterday.get('date') === thatDate.get('date')
  );
};

export default function getDate(timestamp: number, isDraft?: boolean): string {
  dayjs.locale('ru');
  let date = '';

  if (!timestamp) return '';
  if (isDraft || isToday(timestamp)) {
    return 'Сегодня';
  }

  if (isYesterday(timestamp)) {
    return 'Вчера';
  }

  const format = isSameYear(timestamp) ? 'D MMMM' : 'D MMMM YYYY';

  date = dayjs.unix(timestamp).format(format);

  return date;
}
