import RequestBuilder from './request-builder';
import Serializer from './serializer';
import { Entity, FieldsKeys, ResponseMany, RelationsKeys } from './types';

export default class ApiModel<TEntity extends Entity> extends RequestBuilder<TEntity> {
  fields: { [key in FieldsKeys<TEntity>]?: TEntity['fields'][key] };

  protected relatedResource: keyof TEntity['relations'];

  protected relations: TEntity['relations'];

  constructor(EntityClass: { new (): Entity }) {
    super();
    this.serializer = new Serializer();

    const entityClass = new EntityClass();
    this.resourceName = entityClass.resourceName;
    this.apiAddress = entityClass.apiAddress;
    this.withAuth = entityClass.alwaysWithAuth;
    this.relations = entityClass.relations;
    this.fields = {};
  }

  include(includeResources: RelationsKeys<TEntity>[]): this {
    this.includes = includeResources;
    return this;
  }

  filter(filtersValue: { [key in FieldsKeys<TEntity>]?: TEntity['fields'][key] }): this {
    this.filters = { ...filtersValue };
    return this;
  }

  async getOne(id?: string) {
    if (id) this.id = id;

    const { data } = await this.makeRequest({ method: 'GET' });
    if (data.errors) return Promise.reject(data);

    return this.serializer.deserializeOne(data);
  }

  async getMany() {
    const { data } = await this.makeRequest({ method: 'GET' });
    if (data.errors) return Promise.reject(data);

    return this.serializer.deserializeMany(data);
  }

  private checkAbilityToRelatedRequest(RelatedEntity: Entity) {
    if (!this.id)
      throw Error(
        `Unable to create request, related requests must include id. id is ${typeof this.id}`
      );

    if (!this.relations[RelatedEntity.resourceName])
      throw Error(`Relation ${RelatedEntity.resourceName} does not exist in ${this.resourceName}`);
  }

  async update(): Promise<TEntity['responseUpdate'] & { id?: string }> {
    const { data } = await this.makeRequest({ method: 'PATCH', body: this.fields });
    if (data.errors) return Promise.reject(data);

    return this.serializer.deserializeOne(data);
  }

  async create(): Promise<TEntity['responseCreate'] & { id?: string }> {
    const { data, status } = await this.makeRequest({ method: 'POST', body: this.fields });
    if (data.errors) return Promise.reject(data);

    return status === 204 ? '' : this.serializer.deserializeOne(data);
  }

  async delete(id: string): Promise<TEntity['responseDelete'] & { id: string }> {
    if (id) this.id = id;

    const { data } = await this.makeRequest({ method: 'DELETE' });
    if (data.errors) return Promise.reject(data);

    return this.serializer.deserializeOne(data);
  }

  paginate() {
    // TODO
  }

  async getRelations<
    TRelation extends TEntity['relations'][keyof TEntity['relations']]
  >(RelatedEntityType: { new (): TRelation }): Promise<ResponseMany<TRelation['fields']>> {
    const RelatedEntity = new RelatedEntityType();

    this.checkAbilityToRelatedRequest(RelatedEntity);

    const { data } = await this.makeRelatedRequest({
      method: 'GET',
      relatedResource: RelatedEntity.resourceName
    });

    if (data.errors) return Promise.reject(data);

    return this.serializer.deserializeMany(data);
  }

  private async changeRelations<TRelation extends TEntity['relations'][keyof TEntity['relations']]>(
    method: 'PATCH' | 'POST' | 'DELETE',
    RelatedEntityType: { new (): TRelation },
    ids: string[] | string
  ): Promise<ResponseMany<TRelation['fields']>> {
    const RelatedEntity = new RelatedEntityType();

    this.checkAbilityToRelatedRequest(RelatedEntity);

    const idData = Array.isArray(ids) ? ids : [ids];

    const { data } = await this.makeRelatedRequest({
      method,
      relatedResource: RelatedEntity.resourceName,
      body: idData.map((id) => ({ type: RelatedEntity.resourceName, id }))
    });

    if (data.errors) return Promise.reject(data);

    return this.serializer.deserializeMany(data);
  }

  async createRelations<TRelation extends TEntity['relations'][keyof TEntity['relations']]>(
    RelatedEntityType: { new (): TRelation },
    ids: string[] | string
  ) {
    return this.changeRelations('POST', RelatedEntityType, ids);
  }

  async updateRelations<TRelation extends TEntity['relations'][keyof TEntity['relations']]>(
    RelatedEntityType: { new (): TRelation },
    ids: string[] | string
  ) {
    return this.changeRelations('PATCH', RelatedEntityType, ids);
  }

  async deleteRelations<TRelation extends TEntity['relations'][keyof TEntity['relations']]>(
    RelatedEntityType: { new (): TRelation },
    ids: string[] | string
  ) {
    return this.changeRelations('DELETE', RelatedEntityType, ids);
  }
}
