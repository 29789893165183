import React from 'react';
import { Link } from 'react-router-dom';

export default function Menu() {
  return (
    <div className="footer__menu">
      <Link to="/help/">Вопросы и ответы</Link>
      <Link to="/about/">О компании</Link>
      <Link to="/blog/">Блог</Link>
    </div>
  );
}
