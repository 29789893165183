import { Entity } from 'src/json-api/framework/types';
import SiteApi from '../base/site-api';

export default class Notifies extends SiteApi implements Entity {
  resourceName = 'notifies';

  alwaysWithAuth = true;

  fields: Partial<{
    title: string;
    dateStamp: string;
    body: string;
    isReaded: boolean;
  }>;

  relations = {};

  responseDelete: never;

  responseCreate: never;

  responseUpdate: this['fields'];
}
