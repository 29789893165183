import { defaultType } from '../../constants/history';
import { ActionType } from './actions';

const initialState = {
  type: defaultType,
  loaded: false,
  dataLoaded: false,
  data: {
    clicks: {
      items: [],
      next: '',
    },
    sales: {
      items: [],
      next: '',
    },
    payouts: {
      items: [],
      next: '',
    },
    stats: null,
  },
  filters: {
    orderId: null,
    startDate: null,
    endDate: null,
    programId: null,
    statusId: null,
  },
  filtersCounter: 0,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionType.CHANGE_TYPE:
      return {
        ...state,
        type: payload,
      };
    case ActionType.LOAD_CLICKS:
      return {
        ...state,
        loaded: true,
        data: {
          ...state.data,
          clicks: {
            items: payload.items,
            next: payload.next,
          },
        },
      };
    case ActionType.LOAD_SALES:
      return {
        ...state,
        loaded: true,
        data: {
          ...state.data,
          sales: {
            items: payload.items,
            next: payload.next,
          },
        },
      };
    case ActionType.LOAD_PAYOUTS:
      return {
        ...state,
        loaded: true,
        data: {
          ...state.data,
          payouts: {
            items: payload.items,
            next: payload.next,
          },
        },
      };
    case ActionType.LOAD_STATS:
      return {
        ...state,
        loaded: true,
        data: {
          ...state.data,
          stats: payload,
        },
      };
    case ActionType.SET_LOADED:
      return {
        ...state,
        loaded: payload,
      };
    case ActionType.SET_DATA_LOADED:
      return {
        ...state,
        dataLoaded: payload,
      };
    case ActionType.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
      };
    case ActionType.SET_FILTERS_COUNTER:
      return {
        ...state,
        filtersCounter: payload,
      };
    case ActionType.RESET_FILTERS:
      return {
        ...state,
        // filters: { type: null, orderId: null, startDate: null, programId: null },
        filters: {
          orderId: null,
          startDate: null,
          endDate: null,
          programId: null,
          statusId: null,
        },
        filtersCounter: 0,
      };
    case ActionType.RESET_TYPE:
      return {
        ...state,
        type: defaultType,
      };
    default:
      return state;
  }
};
