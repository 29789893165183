import BaseModelWithTokens from '../base/base-model-with-tokens';

export default class PayObjectsWithToken extends BaseModelWithTokens {
  resourceName() {
    return 'pay_objects';
  }

  fields() {
    return ['name', 'iconPath', 'isTarget'];
  }
}
