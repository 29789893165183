import { matchPath } from 'react-router';
import routes from '../../../router/router';

/**
 * Возвращает объект категории исходя из pathname
 * или { notFound: true } если категория не найдена
 *
 * В pathname находится alias категории
 *
 * @param {array} params.categories - массив категорий из api
 * @param {array} params.nodeUrl - pathname с сервера
 */
export default function getCategory({ categories, pathname }) {
  // TODO тут можно использовать reg exp вместо поиска по роутам, ведь все что нужно это получить alias
  let matchedRoute;
  let all;
  routes.forEach((route) => {
    const matched = matchPath(pathname, route);

    // TODO Теперь уж точно нужно сделать regexp, это костыль чтобы принимались query params
    if (
      matched?.path === '/promocode/' ||
      matched?.path === '/promocode/?*' ||
      matched?.path === '/shops/' ||
      matched?.path === '/shops/?*' ||
      matched?.path === '/' ||
      matched?.path === '/?*' ||
      (matched?.path === '/shops/:categoryName/' && matched?.params?.categoryName.includes('?')) ||
      (matched?.path === '/promocode/:categoryName/' && matched?.params?.categoryName.includes('?'))
    )
      all = true;
    if (matched) matchedRoute = matched.params.categoryName;
  });

  if (all) return { value: null };

  const categoryFromApi =
    categories &&
    matchedRoute &&
    categories.find(({ translitName }) => translitName === matchedRoute);

  if (!categoryFromApi) return { notFound: true };

  return { name: matchedRoute, seoText: '', ...categoryFromApi };
}
