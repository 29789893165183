import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, matchPath } from 'react-router-dom';
import { loadableReady } from '@loadable/component';
import { Provider as ReduxProvider } from 'react-redux';

import store from '../store-config';
import routes from './router/router';
import App from './components/app';
import ErrorBoundary from './components/error-boundary/error-boundary';
import sentryBrowser from '../sentry/sentry-browser';
import Providers from './context/providers';
import setMetaAttribute from './helpers/set-meta-attribute';
import StoresContext from './stores/stores-context';
import RootStore from './stores/root-store';

const currentRoute = routes.find((route) =>
  matchPath(window.location.pathname + window.location.search, {
    path: route.path,
    exact: route.exact,
  })
);

const noSSR = currentRoute && currentRoute.noSSR;
const renderMethod = noSSR ? ReactDOM.render : ReactDOM.hydrate;

const setMeta = ({ title, description, keywords, withoutAddition, disableDescription }) => {
  const titleStr = `${title}${withoutAddition ? '' : ' | СКИДКА РУ — кэшбэк, промокоды и скидки!'}`;
  document.title = titleStr;
  if (keywords) {
    setMetaAttribute({
      attributeName: 'name',
      attributeValue: 'keywords',
      content: keywords,
    });
  }
  setMetaAttribute({
    attributeName: 'property',
    attributeValue: 'og:title',
    content: titleStr,
  });
  if (!disableDescription) {
    setMetaAttribute({
      attributeName: 'name',
      attributeValue: 'description',
      content: description,
    });
    setMetaAttribute({
      attributeName: 'property',
      attributeValue: 'og:description',
      content: description,
    });
  }
};

sentryBrowser();

loadableReady(() => {
  renderMethod(
    <ErrorBoundary>
      <ReduxProvider store={store}>
        <BrowserRouter>
          <Providers initialState={window.providersInitialState}>
            <App setMeta={setMeta} />
          </Providers>
        </BrowserRouter>
      </ReduxProvider>
    </ErrorBoundary>,
    document.getElementById('root'),
    async () => {
      import('focus-visible/dist/focus-visible');
    }
  );
});
