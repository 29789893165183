import React from 'react';
import loadable from '@loadable/component';
import BlogStore from 'src/stores/blog/blog-store';
import { getArticleId } from 'src/components/blog/article';

const Article = loadable(() =>
  import(/* webpackChunkName: "blog-article" */ '../components/blog/article')
);

export default function ArticlePage({ setMeta }) {
  return <Article setMeta={setMeta} />;
}

ArticlePage.serverFetch = async ({ req }) => {
  const blogStore = new BlogStore();
  const { url } = req;
  const id = getArticleId(url).replace(/\\/g, '');
  const article = await blogStore.loadArticle(id);

  return {
    preloadedState: { loadedArticles: [article] },
  };
};
