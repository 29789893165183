import React from 'react';
import PropTypes from 'prop-types';
import WithPrograms from './programs-context/with-programs';
import RootStore from 'src/stores/root-store';
import StoresContext from 'src/stores/stores-context';

Providers.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
  initialState: PropTypes.shape({
    programsContext: {
      data: PropTypes.array,
      prev: PropTypes.string,
      next: PropTypes.string,
      isPending: PropTypes.bool,
    },
  }),
};

Providers.defaultProps = {
  initialState: {},
};

/**
 * Все провайдеры данных
 * @param {object} initialState - начальное состояние контекстов, нужно для передачи из ssr
 */
export default function Providers({ initialState, children }) {
  return (
    <StoresContext.Provider value={new RootStore(initialState)}>
      <WithPrograms initialState={initialState.programsContext}>{children}</WithPrograms>
    </StoresContext.Provider>
  );
}
