import ProgramsWithTokens from '../../../json-api/models/programs/programs-with-tokens';
import Programs from '../../../json-api/models/programs/programs';
import isNode from '../../../helpers/utils/is-node';
import getCursor from '../../../helpers/utils/get-cursor';

/**
 * Запрос магазинов с сервера.
 * Если пользователь залогинен, то использовать модель с токенами.
 * Если передано значение пагинации, то добавить к ссылке курсор
 * @param {boolean} [options.isLogged] - пользователь залогинен
 * @param {string} [options.cursor] - курсор
 * @param {string} [options.filters] - фильтры
 */
export default async function fetchPrograms({ isLogged, cursor, filters } = {}) {
  try {
    const programsModel = !isNode() && isLogged ? new ProgramsWithTokens() : new Programs();
    let request = programsModel.with(['categories', 'program_misc', 'promocodes']);

    const hasFilters = filters?.country || filters?.category || filters?.type;

    // Добавить фильтры к запросу
    if (filters?.country) request = request.where('country', filters.country);
    if (filters?.category) request = request.where('category', filters.category);
    if (filters?.type) request = request.where(filters.type, filters.type !== 'noCashback');

    // TODO: в sarala используется обязательный параметр size в пагинации, надо убрать.
    const { data, links, meta } = cursor
      ? await request.paginate(null, cursor)
      : await request.get();
    
    return {
      programs: {
        data: data
          .filter(({ isActive }) => isActive)
          .map((item) => {
            item.logo = item.program_misc.logo;
            item.name = item.program_misc.name;
            return item;
          }),
        prev: getCursor(links.prev),
        next: getCursor(links.next),
        count: meta.count
      }
    };
  } catch (error) {
    console.error({ error });
    return { programs: { data: [] } };
  }
}
