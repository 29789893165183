import store from '../../../store-config';

export default function showToast(msg, type = '', zIndex = 1) {
  store.dispatch({
    type: 'SET_TOAST',
    payload: {
      show: true,
      msg,
      type,
      zIndex
    }
  });
}
