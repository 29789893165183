import React from 'react';
import loadable from '@loadable/component';

const Agreement = loadable(() =>
  import(/* webpackChunkName: "agreement" */ '../components/agreement/agreement')
);

export default function AgreementRage() {
  return <Agreement />;
}
