import fetchCategories from './api-requests/fetch-categories';
import getCategory from '../../components/shops/helpers/get-category';
import fetchPrograms from './api-requests/fetch-programs';
// import fetchCountries from './api-requests/fetch-countries';

export default async function preloadProgramsDataForSSR({ isLogged, url, promocodesPage }) {
  const preloadedCategories = await fetchCategories();
  const urlParams = new URLSearchParams(url.split('?')[1]);
  const cursor = urlParams?.get('next');

  const category = getCategory({ categories: preloadedCategories.categories, pathname: url });

  const preloadedPrograms = await fetchPrograms({
    cursor,
    isLogged,
    filters: {
      category: category.value || '',
      type: promocodesPage ? 'hasPromocodes' : ''
    }
  });

  // const preloadedCountries = await fetchCountries();
  return {
    programsContext: {
      ...preloadedPrograms,
      // ...preloadedCountries,
      ...preloadedCategories,
      category
    }
  };
}
