import React from 'react';

export default function CookieIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100"
      height="125"
      viewBox="0 0 100 125"
      className="bottom-notify__icon"
    >
      <g id="b" clipPath="url(#c)">
        <rect width="100" height="125" fill="#fff" />
        <g transform="translate(-1319.959 -907.106)">
          <g transform="matrix(0.966, 0.259, -0.259, 0.966, 1326.552, 905.42)">
            <path
              d="M96.211,42.587v.356a8.908,8.908,0,0,1,0,17.459v.356a8.908,8.908,0,0,1,0,17.459v.356a8.908,8.908,0,0,1-.16,17.477,8.908,8.908,0,0,1-17.477.16h-.356a8.908,8.908,0,0,1-17.459,0H60.4a8.908,8.908,0,0,1-17.459,0h-.356a8.908,8.908,0,0,1-17.459,0h-.356A8.908,8.908,0,0,1,7.3,96.05a8.908,8.908,0,0,1-.16-17.477v-.356a8.908,8.908,0,0,1,0-17.459V60.4a8.908,8.908,0,0,1,0-17.459v-.356a8.908,8.908,0,0,1,0-17.459v-.356A8.908,8.908,0,0,1,7.3,7.3a8.908,8.908,0,0,1,17.477-.16h.356a8.908,8.908,0,0,1,17.459,0h.356a8.908,8.908,0,0,1,17.459,0h.356a8.908,8.908,0,0,1,17.459,0h.356A8.908,8.908,0,0,1,96.05,7.3a8.908,8.908,0,0,1,.16,17.477v.356a8.908,8.908,0,0,1,0,17.459Z"
              transform="translate(0 0)"
              fill="#ffb655"
            />
            <g transform="translate(17.185 17.401)">
              <circle
                cx="3.819"
                cy="3.819"
                r="3.819"
                transform="translate(53.465 63.012)"
                fill="#ff9811"
              />
              <ellipse
                cx="3.819"
                cy="2.864"
                rx="3.819"
                ry="2.864"
                transform="translate(28.642 19.095)"
                fill="#ff9811"
              />
              <circle
                cx="2.864"
                cy="2.864"
                r="2.864"
                transform="translate(45.827 42.008)"
                fill="#ff9811"
              />
              <ellipse
                cx="5.728"
                cy="4.774"
                rx="5.728"
                ry="4.774"
                transform="translate(0 63.012)"
                fill="#ff9811"
              />
              <circle
                cx="10.502"
                cy="10.502"
                r="10.502"
                transform="translate(7.638 36.28)"
                fill="#ff9811"
              />
              <ellipse
                cx="7.638"
                cy="6.683"
                rx="7.638"
                ry="6.683"
                transform="translate(55.374 7.638)"
                fill="#ff9811"
              />
              <circle
                cx="7.638"
                cy="7.638"
                r="7.638"
                transform="translate(1.909 1.909)"
                fill="#ff9811"
              />
              <circle
                cx="3.819"
                cy="3.819"
                r="3.819"
                transform="translate(38.189 0)"
                fill="#ff9811"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
