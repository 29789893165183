/* eslint-disable no-underscore-dangle */
import BaseModel from '../base/base-model';
import Currencies from '../pay-request/currencies';
import Promocodes from './promocodes';
import CashbackRates from './cb-rates';
import Categories from './categories';
import ProgramMisc from './program-misc';
import Countries from '../other/countries';

class Programs extends BaseModel {
  _uriCode;

  constructor() {
    super();
    this.toJSON = function toJSON() {
      const obj = { ...this };
      obj.uriCode = `${obj._uriCode}-keshbek`;
      return obj;
    };
  }

  resourceName() {
    return 'programs';
  }

  fields() {
    return [
      'name',
      'noCashback',
      'isOneRate',
      'rateMaxSize',
      'isRateMaxPercent',
      'uriCode',
      'shortDescription',
      'keywords',
      'logo',
      'mainCategory',
      'backgroundImage',
      'title',
      'h1',
      'isActive',
      'tags',
      'metaDescription',
      'promoDescription',
      'warning',
    ];
  }

  relationships() {
    return {
      promocodes: new Promocodes(),
      cashback_rates: new CashbackRates(),
      categories: new Categories(),
      currencies: new Currencies(),
      program_misc: new ProgramMisc(),
      countries: new Countries(),
    };
  }
}

Object.defineProperties(Programs.prototype, {
  uriCode: {
    get: function getter() {
      return `${this._uriCode}-keshbek`;
    },
    set: function setter(val) {
      this._uriCode = val;
    },
  },
});

export default Programs;
