import BaseModel from '../base/base-model';

export default class Auth extends BaseModel {
  resourceName() {
    return 'oauth';
  }

  fields() {
    return ['accessToken', 'refreshToken', 'login', 'pwd', 'id'];
  }
}
