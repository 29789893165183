import React from 'react';
import loadable from '@loadable/component';

const MobileInfo = loadable(() =>
  import(/* webpackChunkName: "mobile-info" */ '../components/mobile-info/mobile-info')
);

export default function MobileInfoPage() {
  return <MobileInfo />;
}
