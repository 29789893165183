import React from 'react';
import loadable from '@loadable/component';

const AuthReg = loadable(() =>
  import(/* webpackChunkName: "pwd-recovery" */ '../../components/auth-reg/auth-reg')
);

export default function PwdRecoveryPage() {
  return <AuthReg type="recovery" />;
}
