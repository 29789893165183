const initialState = {
  // social: []
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SAVE_SOCIAL':
      return {
        ...state,
        social: payload
      };

    default:
      return state;
  }
};
