import React from 'react';
import loadable from '@loadable/component';

const ApproveSub = loadable(() =>
  import(/* webpackChunkName: "approve-sub" */ '../../components/approve-sub/approve-sub')
);

export default function ApproveSubPage() {
  return <ApproveSub />;
}
