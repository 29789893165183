import BaseModel from '../base/base-model';

export default class Banners extends BaseModel {
  resourceName() {
    return 'banners';
  }

  fields() {
    return ['image', 'programId', 'place', 'deeplink', 'promocodeId'];
  }
}
