import axios, { AxiosResponse } from 'axios';
import isNode from 'src/helpers/utils/is-node';
import QueryBuilder from './query-builder';
import generateAccessToken from '../generate-access-token';
import { Entity, FieldsKeys, RelationsKeys } from './types';
import Serializer from './serializer';

export default class RequestBuilder<TEntity extends Entity> {
  protected resourceName: TEntity['resourceName'];

  protected apiAddress: string;

  protected serializer: Serializer<TEntity>;

  protected includes: RelationsKeys<TEntity>[];

  protected filters: { [key in FieldsKeys<TEntity>]?: TEntity['fields'][key] };

  protected queryBuilder: QueryBuilder;

  withAuth: boolean;

  id: string;

  constructor() {
    this.queryBuilder = new QueryBuilder();
  }

  protected async getAuthHeaders() {
    const { header } = await generateAccessToken();
    const name = Object.keys(header)[0];
    const value = header[name];
    return [{ name, value }];
  }

  protected async getHeadersBeforeRequest(): Promise<{ name: string; value: string }[]> {
    if (this.withAuth) return this.getAuthHeaders();
    return [];
  }

  protected async makeRequest({
    method,
    body = {},
  }: {
    method: 'GET' | 'POST' | 'DELETE' | 'PATCH';
    body?: { [key: string]: any };
    relatedResource?: string;
  }): Promise<AxiosResponse> {
    try {
      const headers = await this.getHeadersBeforeRequest();
      const config = this.createRequestConfig({ method, headers, body });
      const result = await axios.request(config);
      if (result?.data?.errors) return Promise.reject(result.data.errors);
      return result;
    } catch (error) {
      if (error?.data?.errors) return Promise.reject(error.data.errors);
      return Promise.reject([{ title: 'Ошибка', detail: 'Что-то пошло не так' }]);
    }
  }

  protected async makeRelatedRequest({
    method,
    body = [],
    relatedResource,
  }: {
    method: 'GET' | 'POST' | 'DELETE' | 'PATCH';
    body?: { type: string; id: string }[];
    relatedResource?: string;
  }): Promise<any> {
    const headers = await this.getHeadersBeforeRequest();
    return axios.request(
      this.createRequestConfigRelated({ method, headers, body, relatedResource })
    );
  }

  private createRequestConfig({
    method,
    headers = [],
    body,
  }: {
    method: 'GET' | 'POST' | 'DELETE' | 'PATCH';
    headers?: { name: string; value: string }[];
    body?: { [key: string]: any };
  }) {
    const url = this.queryBuilder.createRequestUrl({
      resourceName: this.resourceName,
      apiAddress: this.apiAddress,
      include: this.includes as string[],
      filter: this.filters,
      id: this.id,
    });

    const headersFormatted: { [key: string]: string } = {};
    headers.forEach(({ name, value }) => {
      headersFormatted[name] = value;
    });

    const requestConfig = {
      method,
      url,
      data: { data: { attributes: { ...body }, type: this.resourceName, id: this.id } },
      headers: {
        Accept: 'application/vnd.api+json',
        client: 'site',
        client_version: isNode() ? 'server' : 'browser',
        ...headersFormatted,
      },
    };

    return requestConfig;
  }

  private createRequestConfigRelated({
    method,
    headers = [],
    body,
    relatedResource,
  }: {
    method: 'GET' | 'POST' | 'DELETE' | 'PATCH';
    headers?: { name: string; value: string }[];
    body?: { type: string; id: string }[];
    relatedResource: string;
  }) {
    const url = this.queryBuilder.createRequestUrl({
      resourceName: this.resourceName,
      apiAddress: this.apiAddress,
      id: this.id,
      relatedResource,
    });

    const headersFormatted: { [key: string]: string } = {};
    headers.forEach(({ name, value }) => {
      headersFormatted[name] = value;
    });

    const requestConfig = {
      method,
      url,
      data: { data: body },
      headers: {
        Accept: 'application/vnd.api+json',
        client: 'site',
        client_version: isNode() ? 'server' : 'browser',
        ...headersFormatted,
      },
     };

    return requestConfig;
  }
}
