import React from 'react';
import loadable from '@loadable/component';

const PhoneAttach = loadable(() =>
  import(/* webpackChunkName: "attach-phone" */ '../../components/profile/phone-attach')
);

export default function AttachPhone() {
  return <PhoneAttach />;
}
