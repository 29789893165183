import React from "react";
import loadable from "@loadable/component";

const AuthReg = loadable(() =>
  import(/* webpackChunkName: "reg" */ "../../components/auth-reg/auth-reg")
);

export default function Reg({ headTitle }) {
  return <AuthReg type="reg" />;
}
