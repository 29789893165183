import React, { useCallback, useState } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import Social from './parts/social';
import Pay from './parts/pay';
import App from './parts/app';
import Menu from './parts/menu';
import Agreement from './parts/agreement';
import Contacts from './parts/contacts';
import isNode from '../../helpers/utils/is-node';

import './footer.scss';

Footer.propTypes = {
  logged: PropTypes.bool,
  history: PropTypes.shape({ location: PropTypes.shape({ pathname: PropTypes.string }) }).isRequired
};

Footer.defaultProps = {
  logged: false
};

function Footer({ logged, history }) {
  const [desktopVersionEnabled, setDesktopVersionEnabled] = useState();
  const [showMobile, setShowMobile] = useState(false);

  const handleVersion = useCallback(() => {
    setShowMobile(true);

    if (desktopVersionEnabled) {
      document.location.reload();
      return;
    }

    setDesktopVersionEnabled(!desktopVersionEnabled);

    document.querySelector('meta[name=viewport]').content = 'width=995, initial-scale=0.5';
  }, [desktopVersionEnabled]);

  return (
    <footer className="footer">
      <Grid>
        <Row className="footer__top-row">
          <Col md={7} xsHidden smHidden>
            <Menu />
          </Col>
          <Col md={5} xsHidden smHidden>
            <App />
          </Col>
        </Row>
        <Row>
          <Col md={7} lg={8} xsHidden smHidden>
            <Pay />
          </Col>
          {/*<Col xsHidden smHidden md={5} lg={4}>
            <Social desktop />
          </Col>*/}
        </Row>
        <div className="footer__line" />
        <Row className="row_under-line">
          <Col xs={12} mdHidden={!showMobile} lgHidden={!showMobile}>
            <div
              className="pc-version"
              tabIndex="0"
              role="button"
              onClick={handleVersion}
              onKeyDown={handleVersion}
            >
              {desktopVersionEnabled ? 'Мобильная версия' : 'Версия для компьютеров'}
            </div>
          </Col>

          <Col md={4} xsHidden smHidden>
            <Agreement />
          </Col>
          <Contacts />
          <Col xs={12} mdHidden lgHidden>
            <App />
          </Col>
          <Col xs={12} mdHidden lgHidden>
            <Menu />
          </Col>
          <Col xs={12} sm={6} mdHidden lgHidden>
            <Agreement />
          </Col>
        {/*<Col xs={12} mdHidden lgHidden>
            <Social />
          </Col>*/}
        </Row>
      </Grid>
    </footer>
  );
}

const mapStateToProps = ({ user }) => ({
  logged: user.logged
});

export default connect(mapStateToProps)(withRouter(Footer));
