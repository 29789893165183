import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import HeaderDesktop from './header-desktop/header-desktop';
import HeaderMobile from './header-mobile/header-mobile';
import SwipeMenu from '../shared/swipe-menu/swipe-menu';
import MenuLeft from './header-mobile/menu-left';
import MenuRight from './header-mobile/menu-right';
import dataController from '../../data-controller/data-controller';

Header.propTypes = {
  logged: PropTypes.bool,
  user: PropTypes.shape({}),
  notifyCount: PropTypes.number,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      nickname: PropTypes.string,
      nicknameToShow: PropTypes.string,
      avatarPath: PropTypes.string,
      isAdmin: PropTypes.bool,
      balance: PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({
            available: PropTypes.string,
            id: PropTypes.string,
            waiting: PropTypes.string
          })
        )
      })
    })
  ),
  userLoaded: PropTypes.bool
};

Header.defaultProps = {
  logged: false,
  user: null,
  notifyCount: 0,
  categories: [],
  userLoaded: false
};

function Header({ logged, user, notifyCount, userLoaded }) {
  const [showMenuLeft, setShowMenuLeft] = useState(false);
  const [showMenuRight, setShowMenuRight] = useState(false);

  useEffect(() => {
    // dataController.get(['user']);
  }, [logged]);

  return (
    <>
      <SwipeMenu
        width={320}
        show={!!showMenuLeft}
        handleShow={setShowMenuLeft}
        innerComponent={
          <MenuLeft
            showMenuLeft={setShowMenuLeft}
            handleShow={setShowMenuLeft}
            user={user}
            logged={logged}
          />
        }
        side="left"
      />
      <SwipeMenu
        width={320}
        show={!!showMenuRight} // menuRight
        handleShow={setShowMenuRight}
        innerComponent={
          <MenuRight
            showMenuRight={setShowMenuRight}
            user={user}
            logged={logged}
            userLoaded={userLoaded}
            notifyCount={notifyCount}
          />
        }
        side="right"
      />

      <div className="header-desktop">
        <HeaderDesktop
          user={user}
          userLoaded={userLoaded}
          logged={logged}
          notifyCount={notifyCount}
        />
      </div>
      <div className="header-mobile">
        <HeaderMobile
          user={user}
          logged={logged}
          showMenuLeft={setShowMenuLeft}
          showMenuRight={setShowMenuRight}
          notifyCount={notifyCount}
        />
      </div>
    </>
  );
}

const mapStateToProps = ({ user, dataConfig }) => {
  return {
    user: user.user,
    userLoaded: dataConfig.user.loaded,
    logged: user.logged,
    notifyCount:
      user.user.notifies && user.user.notifies.data.filter(({ isReaded }) => !isReaded).length
  };
};

export default connect(mapStateToProps)(Header);
