import { Entity } from 'src/json-api/framework/types';
import SiteApi from '../base/site-api';
import Balance from './balance';
import Subscriptions from './subscriptions';
import Notifies from './notifies';

export default class User extends SiteApi implements Entity {
  resourceName = 'users';

  alwaysWithAuth = true;

  fields: Partial<{
    nickname: string;
    email: string;
    avatarPath: string;
    phone: string;
    login: string;
    hasPassword: boolean;
    emailApproved: boolean;
  }>;

  relations = {
    balance: new Balance(),
    subscriptions: new Subscriptions(),
    notifies: new Notifies()
  };

  responseDelete: never;

  responseCreate: never;

  responseUpdate: this['fields'];
}
