import React, { useState, useEffect, useRef } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { Link, useLocation, useRouteMatch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import NotifyCount from '../notify-count';
import useDebounce from '../../shared/hooks/debounce-hook';
import Icon from '../../shared/icon/icon';
import './header-mobile.scss';
import SearchInputV2 from '../../shared/search-input-v2/search-input-v2';
import Programs from '../../../json-api/models/programs/programs';
import ProgramsWithTokens from '../../../json-api/models/programs/programs-with-tokens';

const propTypes = {
  user: PropTypes.shape({
    avatarPath: PropTypes.string,
  }),
  showMenuLeft: PropTypes.func.isRequired,
  showMenuRight: PropTypes.func.isRequired,
  notifyCount: PropTypes.number,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
};

const defaultProps = {
  user: null,
  notifyCount: 0,
};

function HeaderMobile({ showMenuLeft, showMenuRight, user, notifyCount, history }) {
  const [query, setQuery] = useState('');
  const [resultsData, setResultsData] = useState([]);
  const [dataIsPending, setDataIsPending] = useState(false);
  const [canShowNoOptions, setCanShowNoOptions] = useState(false);
  const queryDebounced = useDebounce(query, 500);
  const { pathname } = useLocation();

  useEffect(() => {
    if (queryDebounced && queryDebounced.length >= 2) {
      setCanShowNoOptions(false);
      let programModel;
      if (user?.delivery_countries?.label !== undefined) {
        programModel = new ProgramsWithTokens();
      } else {
        programModel = new Programs();
      }
      (async () => {
        setDataIsPending(true);
        let request = programModel.where('name', queryDebounced);
        if (pathname.includes('/promocode/')) request = request.where('hasPromocodes', true);
        else request = request.where('noCashback', false);
        const { data: programs } = await request.get();
        if (programs.length === 0) {
          setCanShowNoOptions(true);
        } else {
          setCanShowNoOptions(false);
        }
        setDataIsPending(false);
        setResultsData(
          programs.map((program) => ({
            label: program.program_misc.name,
            value: program.id,
            uri: program.uriCode,
          })),
        );
      })();
    }
  }, [queryDebounced]);

  const searchRef = useRef();

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.select.blur();
    }
  }, [searchRef]);

  return (
    <>
      <div className="header-bottom">
        <Grid className="header-container">
          <Row>
            <Col xs={2}>
              <div className="header__burder-menu">
                <svg onClick={showMenuLeft} tabIndex="0" role="button" aria-label="Меню категорий">
                  <use xlinkHref="/assets/svg/sprite-v2.svg#menu" />
                </svg>
              </div>
            </Col>
            <div tabIndex={-1} className="header-search">
              <SearchInputV2
                instanceId="shop-search"
                inputValue={query}
                onInputChange={(val) => {
                  setQuery(val);
                  setResultsData([]);
                }}
                onChange={({ uri }) => {
                  history.push(
                    `/${
                      pathname.includes('/promocode/') ? 'promocode' : 'shops'
                    }/details/${encodeURIComponent(uri)}/`,
                  );
                  setQuery('');
                }}
                options={resultsData}
                className={`search-blue ${
                  (resultsData.length === 0 && !canShowNoOptions) || !query ? 'no-results' : ''
                }`}
                placeholder={
                  pathname.includes('/promocode/') ? 'Найти магазин с промокодами' : 'Найти магазин'
                }
                canShowNoOptions={canShowNoOptions}
                isLoading={query !== queryDebounced || dataIsPending}
                value={null}
              />
            </div>
            <Col xs={8} className="vertical-center no-wrap">
              <div className="header-bottom__wrapper header-bottom__logo ">
                <a href="/">
                  <div aria-label="На главную">
                    <Icon id="logo" />
                  </div>
                </a>
              </div>
            </Col>
            <Col xs={2}>
              <div className="header-bottom__wrapper header-bottom__user-menu">
                <div className="user-menu__icons-wrapper">
                  <div className="user-menu__icons">
                    {notifyCount !== 0 && <NotifyCount count={notifyCount} />}
                    {user && user.avatarPath ? (
                      <div
                        onClick={showMenuRight}
                        onKeyDown={showMenuRight}
                        tabIndex="0"
                        role="button"
                      >
                        <img className="user-menu__avatar" src={user.avatarPath} alt="" />
                      </div>
                    ) : (
                      <svg
                        onClick={showMenuRight}
                        tabIndex="0"
                        role="button"
                        aria-label="Меню пользователя"
                      >
                        <use xlinkHref="/assets/svg/sprite-v2.svg#user" />
                      </svg>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    </>
  );
}

HeaderMobile.propTypes = propTypes;
HeaderMobile.defaultProps = defaultProps;

export default withRouter(HeaderMobile);
