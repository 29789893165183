/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../icon/icon';
import './fixed-button.scss';

FixedButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  text: PropTypes.string
};

FixedButton.defaultProps = {
  text: ''
};

export default function FixedButton({ onClick, text, onClose }) {
  return (
    <div className="fixed-button-container">
      <div className="fixed-button" onClick={onClick}>
        {text}
      </div>
      <Icon className="close" id="close" onClick={onClose} />
    </div>
  );
}
