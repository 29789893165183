import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

//Временно не загружаем активные направления вывода
//import dataController from '../../../data-controller/data-controller';
import PayObjects from '../../../json-api/models/pay-request/pay-objects';
import Image from '../../shared/image/image';

Pay.propTypes = {
  payObjects: PropTypes.arrayOf(PropTypes.instanceOf(PayObjects))
};

Pay.defaultProps = {
  payObjects: null
};

function Pay({ payObjects }) {
  /* Временно не загружаем активные направления вывода
  useEffect(() => {
    if (!payObjects) dataController.get(['payObjects']);
  }, [payObjects]);
  */
  
  payObjects = [
    {id: 1, isTarget: true, name: 'Mastercard', iconPath: '/upload/pay_objects_logo/mastercard.png'},
    {id: 2, isTarget: true, name: 'Visa', iconPath: '/upload/pay_objects_logo/visa.png'},
    {id: 3, isTarget: true, name: 'Mobile', iconPath: '/upload/pay_objects_logo/mobile.png'}
  ];

  return (
    <div className="footer__partners">
      {payObjects &&
        payObjects
          .filter(({ isTarget, iconPath }) => isTarget && iconPath)
          .map(
            ({ id, iconPath, name }) => (
              <Image
                lowResSrc="/assets/img/footer-pay-placeholder.jpg"
                key={id}
                src={iconPath}
                alt={name}
              />
            )
          )}
    </div>
  );
}

const mapStateToProps = ({ pay }) => {
  return {
    payObjects: pay.payObjects
  };
};

export default connect(mapStateToProps)(Pay);
