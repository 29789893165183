import BaseModel from '../base/base-model';
import AdminModelWithTokens from '../base/admin-model-with-tokens';

export default class AuthBy extends AdminModelWithTokens {
  resourceName() {
    return 'auth_by';
  }

  fields() {
    return ['accessToken'];
  }
}
