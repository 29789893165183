import 'url-search-params-polyfill';
import isNode from './is-node';
// import store from '../../../store-config';

export default function getSearchParams(param, url) {
  // const searchStr = isNode()
  //   ? `?${store.getState().appReducer.nodeUrl.split('?')[1]}`
  //   : window.location.search;

  if (isNode() && !url.includes('?')) return '';

  const searchStr = url.split('?')[1] || window.location.search;

  const urlParams = new URLSearchParams(searchStr);
  return urlParams.get(param);
}
