import React from 'react';
import loadable from '@loadable/component';

const AuthReg = loadable(() =>
  import(/* webpackChunkName: "auth" */ '../../components/auth-reg/auth-reg')
);

export default function Auth() {
  return <AuthReg type="auth" />;
}
