import React from "react";
import loadable from "@loadable/component";

const Codes = loadable(() =>
  import(/* webpackChunkName: "codes" */ "../../components/codes/codes")
);

export default function CodesPage() {
  return <Codes />;
}
