import { Entity } from 'src/json-api/framework/types';
import BlogApi from '../base/blog-api';
import Themes from './themes';

export default class Articles extends BlogApi implements Entity {
  resourceName = 'articles';

  fields: {
    name: string;
    title: string;
    imageUrl: string;
    seoTitle: string;
    seoDescription: string;
    seoKeywords: string;
    body: string;
    published: boolean;
    canEdit: boolean;
    publishDate: number;
  };

  relations = {
    themes: new Themes(),
  };

  responseDelete: never;

  responseCreate: '';

  responseUpdate: never;
}
