import React from 'react';
import loadable from '@loadable/component';

const ForgotPass = loadable(() =>
  import(/* webpackChunkName: "forgot-pass" */ '../../components/auth-reg/forgot-pass')
);

export default function ForgotPassPage({ tokenFromLink }) {
  return <ForgotPass tokenFromLink={tokenFromLink} />;
}
