import React from 'react';
import Image from '../../shared/image/image';

export default function App() {
  return (
    <div className="footer__apps">
      <div className="apps">
        <a
          href="https://play.google.com/store/apps/details?id=ru.skidka.cashback.bonus"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          <Image className="gp" src="/assets/svg/footer/google-play.svg" alt="Google Play" />
        </a>
        <a
          href="https://appgallery.huawei.com/#/app/C106403843?locale=ru_RU&source=site_footer"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          <Image className="gp" src="/assets/svg/footer/huawei-store.svg" alt="Huawei AppGallery" />
        </a>
        <a
          href="https://apps.apple.com/ru/app/skidka-ru/id1039357901"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          <Image className="as" src="/assets/svg/footer/app-store.svg" alt="App Store" />
        </a>
      </div>
      <div className="apps__copy">
        Apple и
        логотип Apple являются зарегистрированными товарными знаками компании Apple Inc. в США и
        других странах. App Store является знаком обслуживания компании Apple Inc.
      </div>
    </div>
  );
}
