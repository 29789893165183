import React from "react";
import loadable from "@loadable/component";

const SupportRequest = loadable(() =>
  import(
    /* webpackChunkName: "support-new" */ "../../components/support/support-request/support-request"
  )
);

export default function SupportNew() {
  return <SupportRequest />;
}
