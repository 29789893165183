import React, { useState, useEffect } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import Button from '../../shared/button/button';
import exit from '../../../helpers/utils/exit';
import NotifyCount from '../notify-count';
import Balance from './header-balance';
import './header-desktop.scss';
import Icon from '../../shared/icon/icon';
import isNode from '../../../helpers/utils/is-node';
import menuItemsData from '../menu-items-data';
import MenuItem from '../menu-item';

const propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    nickname: PropTypes.string,
    nicknameToShow: PropTypes.string,
    avatarPath: PropTypes.string,
    isAdmin: PropTypes.bool,
    balance: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          available: PropTypes.string,
          id: PropTypes.string,
          waiting: PropTypes.string
        })
      )
    })
  }),
  history: PropTypes.shape({ location: PropTypes.shape({ pathname: PropTypes.string }) })
    .isRequired,
  notifyCount: PropTypes.number,
  logged: PropTypes.bool,
  userLoaded: PropTypes.bool
};

const defaultProps = {
  user: null,
  notifyCount: 0,
  logged: false,
  userLoaded: false
};

function HeaderDesktop({ user, notifyCount, logged, userLoaded, history }) {
  const [showMenu, setShowMenu] = useState(false);
  const [currentLocation, setCurrentLocation] = useState();

  useEffect(() => {
    const { pathname } = history.location;
    if (pathname.includes('shops')) {
      setCurrentLocation('shops');
      return;
    }
    if (pathname.includes('promocode')) {
      setCurrentLocation('promocodes');
      return;
    }
    if (pathname === '/') {
      setCurrentLocation('main');
      return;
    }

    setCurrentLocation('');
  }, [history.location]);

  return (
    <>
      <div className="header-bottom">
        <Grid>
          <Row>
            <Col xs={4}>
              <div className="header-bottom__wrapper header-bottom__menu">
                <div className={`with-line ${currentLocation === 'shops' ? 'with-line_on' : ''}`}>
                  <Link to="/shops/">Кэшбэк</Link>
                </div>
                <div
                  className={`with-line ${currentLocation === 'promocodes' ? 'with-line_on' : ''}`}
                >
                  <Link to="/promocode/">Промокоды</Link>
                  <img alt="Новинка" src="/assets/img/main-promo/fresh-sale-desktop.png" className="menu-badge" />
                </div>
              </div>
            </Col>
            <Col xs={4}>
              <div className="header-bottom__wrapper header-bottom__logo ">
                <Link
                  to="/"
                  /* className={`with-line ${currentLocation === 'main' ? 'with-line_on' : ''}`} */
                >
                  <svg>
                    <use xlinkHref="/assets/svg/sprite-v2.svg?010124#logo" />
                  </svg>
                </Link>
              </div>
            </Col>
            <Col xs={4}>
              {userLoaded ? (
                <>
                  <div className="header-bottom__wrapper header-bottom__user-menu">
                    <div
                      onMouseEnter={() => {
                        setShowMenu(true);
                      }}
                      onMouseLeave={() => {
                        setShowMenu(false);
                      }}
                      onFocus={() => {
                        setShowMenu(true);
                      }}
                      onBlur={() => {
                        setShowMenu(false);
                      }}
                      className={`user-menu__icons-wrapper ${
                        showMenu ? 'user-menu__icons-wrapper_hovered' : ''
                      }`}
                      tabIndex="0"
                      role="button"
                    >
                      <div className="user-menu__icons">
                        <span className="user-menu__nick">{user.nicknameToShow}</span>
                        {notifyCount !== 0 && <NotifyCount count={notifyCount} />}
                        {user.avatarPath ? (
                          <img className="user-menu__avatar" src={user.avatarPath} alt="" />
                        ) : (
                          <Icon id="user" />
                        )}
                      </div>
                      <div className="user-menu__menu">
                        {menuItemsData
                          .filter(
                            ({ isAdminItem }) => !isAdminItem || (isAdminItem && user.isAdmin)
                          )
                          .map(
                            ({ to, spriteId, title, showNotifyCount, isAdminItem, isLogout }) => (
                              <MenuItem
                                key={spriteId}
                                to={to}
                                spriteId={spriteId}
                                title={title}
                                className={`user-menu__item ${
                                  !isNode() && !isLogout && document.location.href.includes(to)
                                    ? 'menu__item_current'
                                    : ''
                                }`}
                                notifyCount={showNotifyCount && notifyCount}
                                onClick={(e) => {
                                  setShowMenu(false);
                                  if (isAdminItem) {
                                    e.preventDefault();
                                    document.location.href = '/admin/';
                                  }
                                  if (isLogout) exit();
                                }}
                              />
                            )
                          )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {!logged && (
                    <div className="menu__reg">
                      <Link to="/user/auth/">
                        <Button size="small" type="white">
                          Вход
                        </Button>
                      </Link>
                      <Link to="/user/reg/">
                        <Button size="small" type="white">
                          Регистрация
                        </Button>
                      </Link>
                    </div>
                  )}
                </>
              )}
            </Col>
          </Row>
        </Grid>
      </div>
      {user.id && !isNode() && <Balance balance={user.balance} />}
    </>
  );
}

HeaderDesktop.propTypes = propTypes;
HeaderDesktop.defaultProps = defaultProps;

export default withRouter(HeaderDesktop);
