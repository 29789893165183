import React from 'react';

export default function MailIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100"
      height="125"
      className="bottom-notify__icon"
      viewBox="0 0 100 125"
    >
      <g id="b" clipPath="url(#c)">
        <rect width="100" height="125" fill="#fff" />
        <g transform="translate(-1775.479 -754.883)" clipPath="url(#a)">
          <g transform="matrix(0.966, 0.259, -0.259, 0.966, 1788.467, 755.181)">
            <path
              d="M84.764,12.4l-2.538,8.3L53.915,49.013H30.85L2.307,20.47,0,12.4,11.533.865A2.705,2.705,0,0,1,13.551,0H71.213a2.7,2.7,0,0,1,2.018.865Z"
              transform="translate(0.865 23.449)"
              fill="#fedb41"
            />
            <path
              d="M42.382,12.4l-2.538,8.3L11.533,49.013H0V0H28.831a2.7,2.7,0,0,1,2.018.865Z"
              transform="translate(43.247 23.449)"
              fill="#fc3"
            />
            <path
              d="M60.546,0H2.883A2.855,2.855,0,0,0,0,2.883V78.229a2.855,2.855,0,0,0,2.883,2.883H60.546a2.855,2.855,0,0,0,2.883-2.883V2.883A2.855,2.855,0,0,0,60.546,0Z"
              transform="translate(11.533 0)"
              fill="#cae8f9"
            />
            <path
              d="M31.715,2.883V78.229a2.855,2.855,0,0,1-2.883,2.883H0V0H28.831A2.855,2.855,0,0,1,31.715,2.883Z"
              transform="translate(43.247 0)"
              fill="#b7e0f6"
            />
            <path
              d="M28.831,20.182a8.734,8.734,0,1,0-2.883,6.4,8.62,8.62,0,0,0,14.416-6.4A20.182,20.182,0,1,0,9.341,37.192a19.939,19.939,0,0,0,10.841,3.171,21.922,21.922,0,0,0,9.226-2.076,2.89,2.89,0,0,0-2.422-5.247,16.227,16.227,0,0,1-6.8,1.557A14.416,14.416,0,1,1,34.6,20.182C34.6,23.987,28.831,23.987,28.831,20.182Zm-5.824.346a2.973,2.973,0,1,1,0-.519Z"
              transform="translate(23.065 11.917)"
              fill="#17ace8"
            />
            <g transform="translate(43.247 11.917)">
              <path
                d="M10.611,1.7A2.843,2.843,0,0,1,9.226,5.5,21.925,21.925,0,0,1,0,7.578V1.812A16.228,16.228,0,0,0,6.8.255,2.849,2.849,0,0,1,10.611,1.7Z"
                transform="translate(0 32.786)"
                fill="#1689fc"
              />
              <path
                d="M20.182,20.182a8.62,8.62,0,0,1-14.416,6.4A8.574,8.574,0,0,1,0,28.831V23.065a2.864,2.864,0,0,0,2.826-2.537v-.519A2.887,2.887,0,0,0,0,17.3V11.533a8.637,8.637,0,0,1,8.649,8.649c0,3.806,5.766,3.806,5.766,0A14.421,14.421,0,0,0,0,5.766V0A20.2,20.2,0,0,1,20.182,20.182Z"
                transform="translate(0 0)"
                fill="#1689fc"
              />
            </g>
            <path
              d="M.845,0A2.88,2.88,0,0,0,0,2.038v51.9a8.791,8.791,0,0,0,2.528,6.121L10.947,57.4l22.84-22.839L31.714,30.87Z"
              transform="translate(0 35.827)"
              fill="#fea832"
            />
            <path
              d="M34.19,53.935V2.038A2.88,2.88,0,0,0,33.345,0L2.475,30.87,0,34.161,23.245,57.406l8.416,2.649c.021-.02.046-.025.068-.045A8.715,8.715,0,0,0,34.19,53.935Z"
              transform="translate(52.304 35.827)"
              fill="#fe9923"
            />
            <path
              d="M81.42,29.177a8.8,8.8,0,0,1-6.113,2.537H6.112A8.653,8.653,0,0,1,.058,29.235c-.058,0-.058,0-.058-.058L29.177,0H52.243Z"
              transform="translate(2.537 66.696)"
              fill="#fedb41"
            />
            <path
              d="M40.71,29.177A8.8,8.8,0,0,1,34.6,31.714H0V0H11.533Z"
              transform="translate(43.247 66.696)"
              fill="#fc3"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
