import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import './bread-crumbs.scss';

interface BreadCrumbsProps {
  pages: { title: string; url?: string }[];
  withMain?: boolean;
}

export default function BreadCrumbs({
  pages,
  withMain = true,
}: BreadCrumbsProps): React.ReactElement {
  return (
    <div className="seo-nav">
      {(withMain ? [{ url: '/', title: 'Главная' }, ...pages] : pages)
        .filter(({ title }) => title)
        .map(({ url, title }, i) => {
          const isLast = pages.filter(({ title: pageTitle }) => pageTitle).length === i;
          return (
            <Fragment key={`${url}-${title}`}>
              {isLast ? <span>{title}</span> : <Link to={url}>{title}</Link>}
              {!isLast && (
                <svg className="icon icon--next">
                  <use xlinkHref="/assets/svg/sprite-v2.svg#next" />
                </svg>
              )}
            </Fragment>
          );
        })}
    </div>
  );
}
