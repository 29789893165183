import uniqid from 'uniqid';

const initialState = {
  request: {
    type: {
      value: 1,
      label: 'Не начислен кэшбэк'
    },
    shop: {
      hasShop: false,
      value: { value: 0, label: 'Выберите магазин' },
      options: []
    },
    fields: [],
    requests: []
  },
  tickets: [],
  curTicket: {
    ticket: {
      ticket_messages: { data: [] }
    }
  },
  ticketThemes: null,
  userPrograms: null
};
/*  eslint no-param-reassign: ["error", { "props": false }] */
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SAVE_USER_PROGRAMS':
      return {
        ...state,
        userPrograms: payload.dataObj,
        programsNameToId: payload.dataNameToId,
        names: payload.names,
        namesToLowerCase: payload.namesToLowerCase
      };

    // Записать темы тикетов
    case 'SAVE_TICKETS_THEMES':
      return {
        ...state,
        ticketThemes: payload
      };
    // Записать все тикеты
    case 'SAVE_TICKETS_DATA':
      return {
        ...state,
        tickets: payload
      };
    // Записать статусы
    case 'SAVE_TICKET_STATUSES': {
      const statuses = {};
      payload.forEach(status => {
        statuses[status.id] = status.label;
      });
      return {
        ...state,
        statuses
      };
    }
    // Записать данные тикета
    case 'SAVE_TICKET_DATA':
      return {
        ...state,
        curTicket: {
          ...payload
        }
      };

    // Записать данные формы заявки
    case 'SAVE_REQUEST_DATA':
      return {
        ...state,
        request: {
          ...state.request,
          type: payload.type ? payload.type : state.request.type,
          shop: { ...state.request.shop, ...formatShopData(payload.data) },
          fields: payload.data,
          requests: [
            {
              fields: payload.data,
              id: 'first'
            }
          ]
        }
      };

    // Сохранить измененныое значение поля магазина
    case 'CHANGE_SHOP':
      return {
        ...state,
        request: {
          ...state.request,
          shop: {
            ...state.request.shop,
            value: {
              ...payload
            }
          }
        }
      };

    // Добавить заявку
    case 'ADD_REQUEST':
      return {
        ...state,
        request: {
          ...state.request,
          requests: [
            ...state.request.requests,
            ...[
              {
                fields: [
                  ...state.request.fields.map(field => {
                    const newField = { ...field, value: '' };
                    return newField;
                  })
                ],
                id: uniqid()
              }
            ]
          ]
        }
      };

    // Удалить заявку
    case 'DELETE_REQUEST':
      return {
        ...state,
        request: {
          ...state.request,
          requests: state.request.requests.filter(request => request.id !== payload.id)
        }
      };

    // Изменить заявку
    case 'CHANGE_REQUEST':
      return {
        ...state,
        request: {
          ...state.request,
          requests: [
            ...changeRequestFields(payload.requestId, payload.fieldId, payload.value, state)
          ]
        }
      };
    default:
      return state;
  }
};

/**
 * Форматирует данные для поля выбора магазина.
 * Переименовывает свойства объектов магазинов для react-select.
 */
function formatShopData(data) {
  const shopsInfo = {};
  shopsInfo.hasShop = data.length !== 0 && data[0].reference === 'shops';
  if (shopsInfo.hasShop) {
    shopsInfo.options = data[0].options.map(item => {
      item.label = item.name;
      delete item.name;
      return item;
    });
    data.splice(0, 1);
  } else {
    shopsInfo.options = [];
  }
  return shopsInfo;
}

/**
 * Вызывается при каждом изменении содержимого полей,
 * изменяет соотв. данные в сторе
 */
function changeRequestFields(requestId, fieldId, value, state) {
  const newState = state.request.requests.map(request => {
    if (request.id === requestId) {
      return {
        ...request,
        fields: request.fields.map(field => {
          if (field.field_id === fieldId) field.value = value;
          return field;
        })
      };
    }
    return request;
  });
  return newState;
}
