import ModelWithTokens from '../base/base-model-with-tokens';
import TicketMessages from './ticket-messages';

export default class Tickets extends ModelWithTokens {
  resourceName() {
    return 'tickets';
  }

  fields() {
    return [
      'createStamp',
      'lastMessage',
      'lastMessageFromUser',
      'statusId',
      'themeId',
      'ticketFields'
    ];
  }

  relationships() {
    return {
      ticket_messages: new TicketMessages()
    };
  }
}
