import React from 'react';
import './error-boundary-data.scss';
import Button from '../button/button';

function ErrorBoundaryData() {
  return (
    <>
      <div className="auth__blue-screen-wrapper">
        <div className="auth__blue-screen_centered">
          <div className="auth__blue-screen">
            <div className="blue-screen__body">
              <h1>Что-то пошло не так</h1>
            </div>
            <a href="/">
              <Button className="err-btn">На главную</Button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default ErrorBoundaryData;
