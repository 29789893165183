import atob from 'atob';

/**
 * Склеивает все параметры, пришедшие в токене
 * в один объект
 * @param {string} token
 */
const getTokenData = (token) => {
  try {
    const tokenData = {
      ...JSON.parse(atob(token.split('.')[0])),
      ...JSON.parse(atob(token.split('.')[1]))
    };
    return tokenData;
  } catch (err) {
    return {};
  }
};

export default getTokenData;
