import isUndefined from 'lodash.isundefined';
import forOwn from 'lodash.forown';
import isArray from 'lodash.isarray';
import clone from 'lodash.clone';
import cloneDeep from 'lodash.clonedeep';
import map from 'lodash.map';
import forEach from 'lodash.foreach';
import isString from 'lodash.isstring';

// import moment from 'moment';
import QueryBuilder from './QueryBuilder';
import Formatter from './Formatter';

const formatter = new Formatter();

export default class Model {
  constructor() {
    this.queryBuilder = new QueryBuilder();
    this.selfValidate();
    this.type = this.resourceName();
    this.links = {};
  }

  // override

  fields() {
    return [];
  }

  dates() {
    return [];
  }

  relationships() {
    return {};
  }

  computed() {
    return {};
  }

  resourceName() {
    return null;
  }

  async request(config) {
    // to be implemented in base model
  }

  // fetch requests

  async makeFetchRequest(url, config, method, data) {
    const reqMethod = method || 'GET';
    const requestConfig = {
      method: reqMethod,
      url,
      headers: {
        Accept: 'application/vnd.api+json',
      },
      data: data || null,
    };
    if (config && config.headers) {
      requestConfig.headers = {
        ...requestConfig.headers,
        ...config.headers,
      };
    }

    this.queryBuilder.reset();
    const response = await this.request(requestConfig);

    if (response.data.errors) return Promise.reject(response.data);

    return this.respond(response.data);
  }

  get(config) {
    return this.makeFetchRequest(`${this.resourceUrl()}${this.queryBuilder.getQuery()}`, config);
  }

  find(id, config) {
    return this.makeFetchRequest(
      `${this.resourceUrl()}${id}${this.queryBuilder.getQuery()}`,
      config,
    );
  }

  all() {
    return this.get();
  }

  paginate(perPage = 10, page = 1) {
    this.queryBuilder.paginate(perPage, page);

    return this.makeFetchRequest(`${this.resourceUrl()}${this.queryBuilder.getQuery()}`);
  }

  async fetchRelation(relationship, links = {}, config) {
    if (isUndefined(links.self)) {
      links.self = this.getRelationshipUrl(relationship);
    }

    this[relationship] = await this.relationships()[relationship].makeFetchRequest(
      links.related ? links.related : links.self,
      config,
    );

    return this[relationship];
  }

  // persist requests

  async makePersistRequest(config, options) {
    config.headers = {
      ...config.headers,
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
    };
    const response = await this.request(config);
    if (response.status === 204 || response.status === 202) return '';
    if (options && options.onlyData === true) return response.data;
    if (response.data.errors) return Promise.reject(response.data);
    return this.respond(response.data);
  }

  save(withId) {
    if (this.hasOwnProperty('id')) {
      return this.update(withId);
    }

    return this.create();
  }

  create() {
    const dataToRequest = {
      url: this.resourceUrl(),
      method: 'POST',
      data: this.serialize(this.data()),
    };
    return this.makePersistRequest(dataToRequest);
  }

  update(withId = true) {
    const dataToRequest = {
      url: withId ? this.getSelfUrl() : this.resourceUrl(),
      method: 'PATCH',
      data: this.serialize(this.data()),
    };
    return this.makePersistRequest(dataToRequest);
  }

  delete() {
    return this.makePersistRequest({
      url: this.getSelfUrl(),
      method: 'DELETE',
    });
  }

  attach(model, data = null) {
    const config = {
      url: `${this.getSelfUrl()}/${model.type}/${model.id}`,
      method: 'POST',
    };

    if (data) {
      config.data = data;
    }

    return this.makePersistRequest(config);
  }

  updateRelated(model, data = null, withId = true) {
    const config = {
      url: withId
        ? `${this.getSelfUrl()}/${model.type}/${model.id}`
        : `${this.getSelfUrl()}/${model.type}`,
      method: 'PATCH',
    };
    config.data = {};
    if (data) {
      config.data = data;
    } else {
      const dataObj = {
        type: model.type,
        id: model.id,
      };
      const fields = model.fields();
      if (fields) {
        dataObj.attibutes = {};
        fields.forEach((field) => {
          if (model.hasOwnProperty(field)) {
            dataObj.attibutes[field] = model[field];
          }
        });
      }
      config.data.data = dataObj;
    }

    return this.makePersistRequest(config);
  }

  createRelated(model, { data, inArray, headers }) {
    data = data || null;
    inArray = inArray || true;
    const config = {
      url: `${this.getSelfUrl()}/${model.type}`,
      method: 'POST',
    };
    config.data = {};
    if (data) {
      config.data = data;
    } else {
      let dataObj = {
        type: model.type,
        id: model.id,
      };
      if (inArray) dataObj = [dataObj];
      config.data.data = dataObj;
    }

    config.headers = {
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
      ...headers,
    };

    return this.request(config).then((response) => response.data);
  }

  deleteRelated(model, data = null, inArray = true) {
    const config = {
      url: `${this.getSelfUrl()}/${model.type}`,
      method: 'DELETE',
    };
    config.data = {};
    if (data) {
      config.data = data;
    } else {
      let dataObj = {
        type: model.type,
        id: model.id,
      };
      if (inArray) dataObj = [dataObj];
      config.data.data = dataObj;
    }

    config.headers = {
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
    };

    return this.request(config).then((response) => response.data);
  }

  detach(model) {
    return this.makePersistRequest({
      url: `${this.getSelfUrl()}/${model.type}/${model.id}`,
      method: 'DELETE',
    });
  }

  sync(relationship) {
    const data = this.serialize(this.data());

    return this.makePersistRequest({
      url: `${this.getSelfUrl()}/${relationship}`,
      method: 'PATCH',
      data: data.data.relationships[relationship],
    });
  }

  // modify query string

  with(resourceName) {
    this.queryBuilder.include(resourceName);

    return this;
  }

  orderBy(column, direction = 'asc') {
    this.queryBuilder.orderBy(column, direction);

    return this;
  }

  orderByDesc(column) {
    return this.orderBy(column, 'desc');
  }

  where(key, value = null, group = null) {
    this.queryBuilder.where(key, value, group);

    return this;
  }

  filter(filter, group = null) {
    return this.where(filter, null, group);
  }

  limit(limit) {
    return this.where('limit', limit);
  }

  offset(offset) {
    return this.where('offset', offset);
  }

  select(fields) {
    if (isArray(fields)) {
      const selectFields = clone(fields);
      fields = {};
      fields[this.resourceName()] = selectFields;
    }

    this.queryBuilder.select(fields);

    return this;
  }

  // build model

  respond(response) {
    if (response.hasOwnProperty('errors')) return response;
    if (response.statusCode !== 204 || response.statusCode !== 202) {
      const data = this.deserialize(response);

      if (this.isCollection(data)) {
        return this.resolveCollection(data);
      }
      return this.resolveItem(data);
    }
  }

  resolveCollection(data) {
    const resolved = {};

    if (data.hasOwnProperty('links')) {
      resolved.links = data.links;
    }

    if (data.hasOwnProperty('meta')) {
      resolved.meta = data.meta;
    }

    resolved.data = this.newCollection(map(data.data, (item) => this.resolveItem(item)));

    return resolved;
  }

  resolveItem(data) {
    return this.hydrate(data);
  }

  hydrate(data) {
    const model = clone(this);

    model.id = data.id;
    model.type = data.type;

    if (data.hasOwnProperty('relationships')) {
      model.relationshipNames = data.relationships;
    }

    if (data.hasOwnProperty('links')) {
      model.links = data.links;
    }

    forEach(model.fields(), (field) => {
      model[field] = data[field];
    });

    // forOwn(model.dates(), (format, field) => {
    // console.log(data[field], moment(data[field]));
    // model[field] = moment(data[field]);
    // });

    forEach(data.relationships, (relationship) => {
      const relation = model.relationships()[relationship];

      if (isUndefined(relation)) {
        throw new Error(
          `Sarale: Relationship ${relationship} has not been defined in ${model.constructor.name} model.`,
        );
      }

      const fetch = (config) => model.fetchRelation(relationship, data[relationship].links, config);

      if (this.isCollection(data[relationship])) {
        model[relationship] = {
          ...relation.resolveCollection(data[relationship]),
          fetch,
        };
      } else if (data[relationship].data) {
        model[relationship] = relation.resolveItem(data[relationship].data);
        model[relationship].fetch = fetch;
      }
    });

    forOwn(model.relationships(), (relatedModel, relationshipName) => {
      if (isUndefined(model[relationshipName])) {
        model[relationshipName] = {
          fetch: () => model.fetchRelation(relationshipName),
        };
      }
    });

    forOwn(model.computed(), (computation, key) => {
      model[key] = computation(model);
    });

    return model;
  }

  // extract data from model

  data() {
    const data = {};

    data.type = this.type;

    if (this.hasOwnProperty('id')) {
      data.id = this.id;
    }

    if (this.hasOwnProperty('relationshipNames')) {
      data.relationships = this.relationshipNames;
    }

    forEach(this.fields(), (field) => {
      if (!isUndefined(this[field])) {
        data[field] = this[field];
      }
    });

    // forOwn(this.dates(), (format, field) => {
    //   if (!isUndefined(this[field])) {
    //     data[field] = moment(this[field]).format(format);
    //   }
    // });

    forEach(this.relationships(), (model, relationship) => {
      if (!isUndefined(this[relationship]) && !isUndefined(this[relationship].data)) {
        if (isArray(this[relationship].data)) {
          data[relationship] = {
            data_collection: true,
            data: map(this[relationship].data, (relation) => relation.data()),
          };
        } else {
          data[relationship] = {
            data: this[relationship].data(),
          };
        }
      }
    });

    return data;
  }

  // helpers

  resourceUrl() {
    if (this.links.hasOwnProperty('related')) {
      return this.links.related;
    }

    return `${this.baseUrl()}/${this.resourceName()}/`;
  }

  getSelfUrl() {
    if (this.links.hasOwnProperty('self')) {
      return this.links.self;
    }

    if (!this.hasOwnProperty('id')) {
      throw new Error(
        `Sarala: Unidentifiable resource exception. ${this.constructor.name} id property is undefined.`,
      );
    }

    this.links.self = `${this.resourceUrl()}${this.id}`;

    return this.links.self;
  }

  getRelationshipUrl(relationship) {
    return `${this.getSelfUrl()}/relationships/${relationship}`;
  }

  isCollection(data) {
    return (
      data.hasOwnProperty('data_collection') && data.data_collection === true && isArray(data.data)
    );
  }

  deserialize(data) {
    return formatter.deserialize(data);
  }

  serialize(data) {
    return formatter.serialize(data);
  }

  selfValidate() {
    const name = this.resourceName();

    if (name === null || !isString(name) || name.length === 0) {
      throw new Error(
        `Sarale: Resource name not defined in ${this.constructor.name} model. Implement resourceName method in the ${this.constructor.name} model to resolve this error.`,
      );
    }
  }

  clone() {
    return cloneDeep(this);
  }

  newCollection(data) {
    return data;
  }

  // sarala fetch переписывает значение relation в модели, заменяя полученное
  // из includes на пришедшее из запроса, для повторого использования fetch
  // и данных из includes, fetchRelated возвращает полученные данные,
  // не изменяя модель
  async fetchRelated(model, relation) {
    const curInclude = model[relation];
    const res = await model[relation].fetch();
    model[relation] = curInclude;

    return res;
  }

  getRelated(model, { data, inArray, headers }) {
    data = data || null;
    inArray = inArray || true;
    const config = {
      url: `${this.getSelfUrl()}/${model.type}`,
      method: 'GET',
    };
    config.data = {};
    if (data) {
      config.data = data;
    } else {
      let dataObj = {
        type: model.type,
        id: model.id,
      };
      if (inArray) dataObj = [dataObj];
      config.data.data = dataObj;
    }

    config.headers = {
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
      ...headers,
    };

    return this.request(config).then((response) => {
      if (response.data.errors) throw response.data;
      if (!response.data) throw response;
      return response.data;
    });
  }

  createRelated(model, { data, inArray, headers }) {
    data = data || null;
    inArray = inArray || true;
    const config = {
      url: `${this.getSelfUrl()}/${model.type}`,
      method: 'POST',
    };
    config.data = {};
    if (data) {
      config.data = data;
    } else {
      let dataObj = {
        type: model.type,
        id: model.id,
      };
      if (inArray) dataObj = [dataObj];
      config.data.data = dataObj;
    }

    config.headers = {
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
      ...headers,
    };

    return this.request(config).then((response) => {
      if (response.data.errors) throw response.data;
      if (!response.data) throw response;
      return response.data;
    });
  }

  deleteRelated(model, { data, inArray }) {
    data = data || null;
    inArray = inArray || true;
    const config = {
      url: `${this.getSelfUrl()}/${model.type}`,
      method: 'DELETE',
    };
    config.data = {};
    if (data) {
      config.data = data;
    } else {
      let dataObj = {
        type: model.type,
        id: model.id,
      };
      if (inArray) dataObj = [dataObj];
      config.data.data = dataObj;
    }

    config.headers = {
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
    };

    return this.request(config).then((response) => {
      if (response.data.errors) throw response.data;
      if (!response.data) throw response;
      return response.data;
    });
  }
}
