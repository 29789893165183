import createError from '../helpers/utils/create-error';
import Auth from './models/auth/auth';
import store from '../../store-config';
import getTokenData from '../helpers/utils/get-token-data';
import datestampIsExpired from '../helpers/utils/datestamp-expired';

export async function getAccess() {
  const auth = new Auth();
  auth.id = 'access';

  try {
    const authRequest = await auth.save(true);
    store.dispatch({ type: 'SAVE_TOKENS', payload: { accessToken: authRequest.accessToken } });
    store.dispatch({
      type: 'SET_USER_ID',
      payload: getTokenData(authRequest.refreshToken).sub,
    });
    // saveTokenData(authRequest);
    return authRequest;
  } catch (err) {
    store.dispatch({ type: 'DELETE_TOKENS' });
    return Promise.reject(err);
  }
}

export default async function generateAccessToken(is401) {
  const { accessToken } = store.getState().appReducer.tokens;

  if (!store.getState().user.logged && !window.isLoggedFromNode)
    createError('Неавторизованный реквест');

  const { timeOffset } = store.getState().appReducer;

  let needToRequestAccess = !accessToken || is401;
  if (!needToRequestAccess) {
    const { exp: accessExp } = getTokenData(accessToken);
    if (datestampIsExpired(accessExp - timeOffset - 10)) needToRequestAccess = true;
  }

  if (needToRequestAccess) {
    await getAccess();
    return {
      accessToken: store.getState().appReducer.tokens.accessToken,
      refreshToken: store.getState().appReducer.tokens.refreshToken,
      header: { authorization: `Bearer ${store.getState().appReducer.tokens.accessToken}` },
    };
  }

  return {
    accessToken,
    header: { authorization: `Bearer ${accessToken}` },
  };
}
// function saveTokenData({ accessToken, refreshToken }) {
//   store.dispatch({ type: 'SAVE_TOKENS', payload: { accessToken } });
//   store.dispatch({
//     type: 'SET_USER_ID',
//     payload: JSON.parse(atob(refreshToken.split('.')[1])).sub
//   });
//   store.dispatch({
//     type: 'SET_LOGGED',
//     payload: true
//   });
// }
