import React from 'react';
import loadable from '@loadable/component';
import BlogStore from 'src/stores/blog/blog-store';

const Blog = loadable(() => import(/* webpackChunkName: "blog" */ '../components/blog/blog'));

export default function BlogPage() {
  return <Blog />;
}

BlogPage.serverFetch = async () => {
  const blogStore = new BlogStore();
  const articles = await blogStore.loadArticles();

  return {
    preloadedState: { articles },
  };
};
