import dayjs from 'dayjs';
import CashbackCodes from '../../../json-api/models/user/cashback-codes';

export default async function getCodes() {
  const codes = new CashbackCodes();
  const { data } = await codes.get();
  const dataFormatted = [];

  // eslint-disable-next-line camelcase
  data.forEach(({ program_misc: misc, programs, ...code }) => {
    programs.data.forEach((program) => {
      dataFormatted.push({
        ...code,
        logo: misc?.data.find(({ id }) => id === program.id)?.logo,
        uriCode: program?.uriCode
      });
    });
  });

  return dataFormatted
    .sort((a, b) => a.startStamp - b.startStamp)
    .sort((a, b) => {
      // Подвинуть активные коды наверх
      const aEnd = a.endStamp > dayjs(new Date()).unix() ? 1 : 0;
      const bEnd = b.endStamp > dayjs(new Date()).unix() ? 1 : 0;
      return bEnd - aEnd;
    });
}
