import ModelWithTokens from '../base/base-model-with-tokens';
import ProgramMisc from '../programs/program-misc';
import { ITEMS_PER_PAGE_COUNT } from '../../../../constants/history';

export default class Click extends ModelWithTokens {
  resourceName() {
    return 'clicks';
  }

  fields() {
    return ['clickStamp', 'programId', 'logoURL'];
  }

  get(config) {
    this.queryBuilder.paginate(ITEMS_PER_PAGE_COUNT);
    return this.makeFetchRequest(
      `${this.baseUrl()}/users/${this.userId}/clicks/${this.queryBuilder.getQuery()}`,
      config,
    );
  }

  getMore(config) {
    this.queryBuilder.paginate(ITEMS_PER_PAGE_COUNT, this.next);
    return this.makeFetchRequest(
      `${this.baseUrl()}/users/${this.userId}/clicks/${this.queryBuilder.getQuery()}`,
      config,
    );
  }

  delete(config) {
    const data = {
      data: [
        {
          type: 'clicks',
          id: this.id,
        },
      ],
    };
    return this.makeFetchRequest(
      `${this.baseUrl()}/users/${this.userId}/clicks/`,
      config,
      'DELETE',
      data,
    );
  }

  relationships() {
    return {
      program_misc: new ProgramMisc(),
    };
  }
}
