import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { captureSentryError } from '../../helpers/utils/capture-sentry-browser';
import ErrorBoundaryData from '../shared/error-boundary-data/error-boundary-data';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { showError: false, isReload: false };
  }

  static getDerivedStateFromError(error) {
    if (
      error.name === 'ChunkLoadError' ||
      error.code === 'CSS_CHUNK_LOAD_FAILED' ||
      (typeof error === 'string' && error.includes('chunk'))
    ) {
      document.location.reload();
      return { isReload: true };
    }
    return { showError: true };
  }

  componentDidCatch(error) {
    if (!this.state.isReload) captureSentryError(error);
  }

  render() {
    if (this.state.showError) {
      // React router здесь не работает,
      // forced изменение location
      window.addEventListener('popstate', () => {
        document.location.replace(document.location.href);
      });

      return <ErrorBoundaryData />;
    }
    if (this.state.isReload) return <></>;
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired
};
