import React from 'react';
import loadable from '@loadable/component';

const AuthReg = loadable(() =>
  import(/* webpackChunkName: "social-auth" */ '../../components/auth-reg/auth-reg')
);

export default function SocialAuth() {
  return <AuthReg type="social" />;
}
