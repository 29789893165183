import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import dataController from '../../data-controller/data-controller';
import DeliveryCountries from '../../json-api/models/other/delivery-countries';
import User from '../../json-api/models/user/user';

import showToast from '../../helpers/utils/show-toast';
import Dropdown from '../dropdown/dropdown';
import Button from '../shared/button/button';

import generateAccessToken from '../../json-api/generate-access-token';
import useProgramsActions from '../../context/programs-context/hooks/use-programs-actions';
import store from '../../../store-config';
import './countries.scss';

function Countries({
  countries,
  userCountry,
  userId,
  setCountryUser,
  onClose,
  isMainPage = false,
}) {
  const [country, setCountry] = useState(userCountry);
  const [showModal, setShowModal] = useState(false);
  const { getPrograms } = useProgramsActions();

  const onBackButtonEvent = () => {
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    const body = window.document.querySelector('body');
    const windowWidth = window.innerWidth;
    const { clientWidth } = document.documentElement;

    body.classList.add('hide-overflow');

    if (windowWidth > clientWidth) {
      body.style.paddingRight = `${windowWidth - clientWidth}px`;
    }

    // Отображаем попап после того, как зафиксировали экран, иначе попап "прыгает"
    setShowModal(true);

    return () => {
      body.classList.remove('hide-overflow');
      body.style.paddingRight = null;
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  useEffect(() => {
    if (!countries) {
      dataController.get(['deliveryCountries']);
    }
  });

  const handleConfirmClick = async () => {
    const { label, value: shortName, phoneCode, isFilter, isDelivery, id } = country;
    if (id !== userCountry.id) {
      const data = {
        data: {
          attributes: {
            label,
            shortName,
            phoneCode,
            isFilter,
            isDelivery,
          },
          id,
          type: 'delivery_countries',
        },
      };
      const userModel = new User();
      userModel.id = userId;

      await userModel.updateRelated(new DeliveryCountries(), data, false).then(() => {
        generateAccessToken(true).then(() => {
          store.dispatch({ type: 'RESET_BANNERS', payload: {} });

          if (isMainPage) {
            dataController.get(['banners']);
            const isLogged = true;
            getPrograms({
              filters: { type: 'noCashback' },
              isLogged,
            });
          }
        });
      });
      setCountryUser(country);
      showToast(`Вы выбрали ${label}`);
    }

    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      {showModal && (
        <div className="countries">
          <div className="countries__dialog">
            <div className="countries__content">
              <div className="countries__header">
                {onClose && <button className="countries__close" type="button" onClick={onClose} />}
                <div className="countries__title">
                  <img className="countries__icon" src="/assets/svg/region/world.svg" alt="" />
                  Укажите вашу страну&nbsp;доставки
                </div>
                <div className="countries__subtitle">
                  Выбор страны необходим для&nbsp;отображения магазинов, осуществляющих доставку
                  в&nbsp;страну покупателя.
                </div>
              </div>
              <div className="countries__body">
                <Dropdown
                  className="popup-control"
                  options={countries}
                  defaultValue={country}
                  placeholder="Выберите страну доставки"
                  onChange={(newCountry) => {
                    setCountry(newCountry);
                  }}
                />
                <Button className="dropdown__btn" onClick={handleConfirmClick} disabled={!country}>
                  Подтвердить
                </Button>
              </div>
              <div className="countries__footer">
                Если Вы неверно выбрали страну доставки, то&nbsp;кэшбэк может быть аннулирован или
                начислен с&nbsp;пониженной ставкой. Изменить страну доставки Вы можете в&nbsp;личном
                кабинете.
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

Countries.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  userCountry: PropTypes.shape({
    label: PropTypes.string,
  }),
  onClose: PropTypes.func,
};

const mapStateToProps = ({ user, other }, props) => ({
  isMainPage: props.isMainPage,
  countries: other.countries,
  userCountry: user.user.delivery_countries,
  userId: user.user.id,
});

const mapDispatchToProps = (dispatch) => ({
  setCountryUser: (payload) => dispatch({ type: 'SAVE_COUNTRY', payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Countries);
