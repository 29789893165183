import {
  Entity,
  ResponseMany,
  ResponseOne,
  JsonApiRawDataCollection,
  JsonApiRawDataOne,
} from './types';

export default class Serializer<TEntity extends Entity> {
  deserializeOne(
    rawData: JsonApiRawDataOne<TEntity['fields']>
  ): ResponseOne<TEntity['fields'], TEntity['relations']> {
    const res = {
      id: rawData.data.id,
      ...rawData.data.attributes,
      links: rawData.links,
      meta: rawData.meta || {},
    };

    if (rawData.data.relationships && rawData.included) {
      rawData.included.forEach(({ attributes, id, type }) => {
        if (!res[type]) res[type] = [];
        res[type] = [...res[type], { ...attributes, id, type }];
      });
    }

    return res;
  }

  deserializeMany(
    rawData: JsonApiRawDataCollection<TEntity['fields']>
  ): ResponseMany<TEntity['fields']> {
    const res: ResponseMany<TEntity['fields']> = {
      meta: rawData.meta || {},
      links: rawData.links,
      data: null,
    };

    res.data = rawData.data.map((item) => {
      const itemRes = {
        ...item.attributes,
        id: item.id,
      };

      if (item.relationships) {
        const relations = Object.keys(item.relationships);

        relations.forEach((relationName) => {
          let relationData = item.relationships[relationName].data;
          if (!Array.isArray(relationData)) relationData = [relationData];

          itemRes[relationName] = relationData.map(({ id, type }) => {
            const relatedInclude = rawData.included.find(
              ({ id: includeId, type: includeType }) => id === includeId && type === includeType
            );
            return {
              ...relatedInclude.attributes,
              id: relatedInclude.id,
              links: relatedInclude.links,
              type: relatedInclude.type,
            };
          });
        });
      }

      return itemRes;
    });

    return res;
  }
}
