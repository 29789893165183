import React from 'react';
import loadable from '@loadable/component';
import dataController from '../data-controller/data-controller';

const PromocodeDetails = loadable(() =>
  import(/* webpackChunkName: "promocode-details" */ '../components/promocode/promocode-details')
);

export default function PromocodeDetailsPage({ setMeta }) {
  return <PromocodeDetails setMeta={setMeta} />;
}

PromocodeDetailsPage.serverFetch = ({ store }) => {
  return dataController.get(['programPromocodeDetails'], store);
};
