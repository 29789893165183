import { notify } from 'react-notify-toast';

export default function showToast({ type = '', msg, time = 3000 }) {
  const options = {
    background: type === 'error' ? 'rgb(222, 83, 71)' : 'rgba(0, 0, 0, 0.75)',
    text: '#FFFFFF'
  };

  notify.show(msg, 'custom', time, options);
}
