import ModelWithTokens from '../base/base-model-with-tokens';
import ProgramMisc from '../programs/program-misc';
import Programs from '../programs/programs';

export default class CashbackCodes extends ModelWithTokens {
  resourceName() {
    return 'cashback_codes';
  }

  fields() {
    return ['code', 'descriptionText', 'endStamp', 'logo', 'status', 'title', 'uri'];
  }

  relationships() {
    return {
      programs: new Programs(),
      program_misc: new ProgramMisc()
    };
  }
}
