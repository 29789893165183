import React from 'react';
import loadable from '@loadable/component';

const History = loadable(() =>
  import(/* webpackChunkName: "history" */ '../../components/history/history'),
);

export default function HistoryPage() {
  return <History />;
}
