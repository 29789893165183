/* eslint-disable no-console */
import DeliveryCountries from '../../../json-api/models/other/delivery-countries';

/**
 * Запрос стран с сервера
 */
export default async function fetchCountries() {
  try {
    const countriesModel = new DeliveryCountries();
    const { data: countriesData } = await countriesModel.get();

    // TODO: здесь должен быть фильтр по параметру - в стране есть магазины
    return {
      countries: [
        { label: 'Все страны', value: null },
        ...countriesData
          .filter(({ isFilter }) => isFilter)
          .map(({ label, id: value }) => ({
            label,
            value,
          })),
      ],
    };
  } catch (error) {
    console.error({ error });
    return { countries: [] };
  }
}
