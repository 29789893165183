export default class QueryBuilder {
  createRequestUrl({
    id,
    resourceName,
    apiAddress,
    include = [],
    filter = {},
    relatedResource
  }: {
    id?: string;
    resourceName: string;
    apiAddress: string;
    include?: string[];
    filter?: { [key: string]: string };
    relatedResource?: string;
  }): string {
    let pathname = `${apiAddress}${resourceName}`;
    if (id) pathname += `/${id}`;
    if (relatedResource) pathname += `/${relatedResource}`;

    const searchParams = new URLSearchParams();

    if (include.length !== 0) {
      const includesParam = include.join(',');
      searchParams.set('include', includesParam);
    }

    Object.keys(filter).forEach((name) => searchParams.set(`filter[${name}]`, filter[name]));

    const search = decodeURIComponent(searchParams.toString());
    return search ? `${pathname}/?${search}` : pathname;
  }
}
