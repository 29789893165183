import { Entity } from 'src/json-api/framework/types';
import SiteApi from '../base/site-api';

export default class Subscriptions extends SiteApi implements Entity {
  resourceName = 'subscriptions';

  alwaysWithAuth = true;

  fields: Partial<{
    label: string;
  }>;

  relations = {};

  responseDelete: never;

  responseCreate: never;

  responseUpdate: this['fields'];
}
