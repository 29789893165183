import User from '../../../json-api/models/user/user';
import Balance from '../../../json-api/models/user/balance';

export default async function getBalance({ getState }) {
  const userId = getState().user.user.id;
  const user = new User();
  user.id = userId;
  const response = await user.getRelated(new Balance(), {});
  return response.data.map(({ attributes, ...rest }) => ({ ...attributes, ...rest }));
}
