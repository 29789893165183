import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import NotifyCount from './notify-count';
import Icon from '../shared/icon/icon';

MenuItem.propTypes = {
  to: PropTypes.string,
  spriteId: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  notifyCount: PropTypes.number,
  className: PropTypes.string
};

MenuItem.defaultProps = {
  to: '',
  spriteId: '',
  title: '',
  notifyCount: 0,
  className: ''
};

export default function MenuItem({ to, spriteId, title, onClick, notifyCount, className }) {
  return (
    <Link to={to} onClick={onClick}>
      <div className={className}>
        {spriteId && <Icon id={spriteId} />}
        <span>{title}</span>
        {notifyCount ? <NotifyCount inMenu count={notifyCount} /> : []}
      </div>
    </Link>
  );
}
