import ModelWithTokens from '../base/base-model-with-tokens';
import ProgramMisc from '../programs/program-misc';

export default class Stats extends ModelWithTokens {
  resourceName() {
    return 'stats';
  }

  fields() {
    return ['earnedCashback', 'payoutedCashback'];
  }

  get(config) {
    return this.makeFetchRequest(`${this.baseUrl()}/users/${this.userId}/stats/`, config);
  }
}
