import { createContext } from 'react';

export const typesDict = [
  { label: 'По популярности', value: '' },
  { label: 'По размеру кэшбэка в %', value: 'percent' },
  { label: 'По размеру кэшбэка в рублях', value: 'rub' },
  { label: 'Магазины с промокодами', value: 'promocodes' }
];

/**
 * Схема контекста, больше информации в with-programs
 */
const initialState = {
  programs: [],
  promocodes: [],
  promocodesNext: '',
  isPending: false,
  prev: '',
  next: '',
  countries: [],
  categories: [],
  types: typesDict,
  category: {},
  country: {},
  type: {},
  programsCount: 0,
  favPrograms: [],
  promocodesCount: '',
  promoPrograms: []
};

const ProgramsStateContext = createContext(initialState);

export default ProgramsStateContext;
