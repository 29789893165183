const queryExceptions = ['next'];

export default function setCanonical({ pathname, search }) {
  let canonical = document.querySelector('link[rel="canonical"]');

  if (!search) {
    if (canonical) {
      canonical.remove();
    }
    return;
  }

  const query = search.replace(/^\?/, '');
  const queryObj = {};

  query.replace(/([^=&]+)=([^&]*)/g, (m, key, value) => {
    queryObj[decodeURIComponent(key)] = decodeURIComponent(value);
  });

  const filteredQuery = Object.keys(queryObj).filter((key) => !queryExceptions.includes(key));
  const restQuery = Object.keys(queryObj).filter((key) => queryExceptions.includes(key));
  const newQuery =
    restQuery.length > 0 ? `?${restQuery.map((key) => `${key}=${queryObj[key]}`).join('&')}` : '';

  const href = `https://skidka.ru${pathname}${newQuery}`;

  if (filteredQuery.length > 0) {
    if (!canonical) {
      const createdCanonical = document.createElement('link');
      createdCanonical.setAttribute('rel', 'canonical');
      document.head.appendChild(createdCanonical);
      canonical = createdCanonical;
    }
    canonical.setAttribute('href', href);
  } else if (canonical) {
    canonical.remove();
  }
}
