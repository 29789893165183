import React from 'react';
import loadable from '@loadable/component';
import preloadProgramsDataForSSR from '../context/programs-context/preload-programs-data-for-ssr';

const Promocode = loadable(() =>
  import(/* webpackChunkName: "promocode" */ '../components/promocode/promocode')
);

export default function PromocodesPage({ setMeta }) {
  return <Promocode isCategory isPromocodesPage setMeta={setMeta} />;
}
// на промокодной странице не грузим магазины
/* PromocodesPage.serverFetch = async ({ isLogged, req }) => {
  const preloadedState = await preloadProgramsDataForSSR({
    isLogged,
    url: req.url,
    promocodesPage: true
  });

  return {
    preloadedState
  };
}; */
