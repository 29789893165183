import { jitsuClient } from '@jitsu/sdk-js';
import isNode from '../utils/is-node';

let jitsuOpts = {
  key: 'cl.x3gli30e56jchss979rac.fbuhgot3l6pqp40wfvax2g',
  tracking_host: 'https://alcs.skidka.ru/',
  //randomize_url: true,
  compat_mode: false,
};

export const jitsu = isNode() ? {track: function (){}} : jitsuClient(jitsuOpts);
