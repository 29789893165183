import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, useLocation, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import routes from '../router/router';
import dataController from '../data-controller/data-controller';

import { jitsu } from '../helpers/jitsu/jitsu';
import captureSentryBrowser from '../helpers/utils/capture-sentry-browser';
import isNode from '../helpers/utils/is-node';
import showToast from '../helpers/utils/show-toast';
import isFromApp from '../helpers/utils/is-from-app';
import setCanonical from '../helpers/set-canonical';
import WithHeaderFooter from './hoc/with-header-footer';
import Loader from './shared/loader/loader';
import FixedButton from './shared/fixed-button/fixed-button';
import SessionTimer from './admin/enter-by-user/session-timer/session-timer';

import BottomNotification from './shared/bottom-notification/bottom-notification';
import CookieNotify from './cookie/cookie-notify';
import ApproveEmailNotify from './approve-email/approve-email-notify';
import ScrollToTop from './shared/scroll-to-top/scroll-to-top';
import NotFound from './not-found/not-found';
import Countries from './countries/countries';

import '../../scss/bootstrap.scss';
import '../../scss/_entry.scss';

function App({
  disablePromoButton,
  history,
  logged,
  promoButtonUrl,
  setMeta,
  showLoader,
  showApproveEmail,
  userId,
  userLoaded,
  userCountry,
  setLogged,
  saveToken,
}) {
  const location = useLocation();
  const { pathname } = location;

  const [hideCookie, setHideCookie] = useState(true);
  const isNotGotoPage = !pathname.split('/').includes('goto');

  useEffect(() => {
    if (!isNotGotoPage) {
      const accessToken = new URLSearchParams(location.search).get('access');
      if (accessToken) {
        setLogged(true);
        saveToken({ accessToken });
      }
    }
  });

  useEffect(() => {
    if (userLoaded) {
      dataController.get(['banners']);
    }

    if (!userLoaded) {
      dataController.get(['user']);
    }
    if (isNotGotoPage && localStorage && !localStorage.getItem('cookie-agree')) setHideCookie(false);
  }, [logged, userLoaded, userId]);

  useEffect(() => {
    setCanonical(location);
  }, [location]);

  useEffect(() => {
    if (process.env.MODE !== 'development') {
      const {
        referrer,
        location: { hostname },
      } = document;

      const isGotoMobile = new URLSearchParams(location.search).has('access');

      if (
        !sessionStorage.getItem('pageview') &&
        !isGotoMobile &&
        (!referrer || new URL(referrer).hostname !== hostname)
      ) {
        jitsu.track('pageview');
        sessionStorage.setItem('pageview', true);
      }
    }
  });

  useEffect(() => {
    try {
      if (process.env.MODE !== 'development' && !window.IS_TEST) {
        window.ym(10953694, 'hit', document.location.href, { params: window.yaParams });
        //window.gtag('config', 'UA-32783662-2', { page_path: location.pathname });
      }
    } catch (err) {
      captureSentryBrowser('Упали коды метрик в подписке на location');
    }
  }, [location]);

  return (
    <>
      <ScrollToTop />
      <WithHeaderFooter showApproveEmail={showApproveEmail} logged={logged}>
        <Switch>
          {routes.map(({ path, component, exact, loggedOnly, notLoggedOnly, title }) => {
            const Component = component;

            if (!isNode() && loggedOnly) {
              return (
                <Route sensitive key={path} path={path} exact={exact}>
                  {!logged ? (
                    <Redirect to="/user/auth/" />
                  ) : (
                    <Component headTitle={title} setMeta={setMeta} />
                  )}
                </Route>
              );
            }

            if (!isNode() && notLoggedOnly) {
              return (
                <Route sensitive key={path} path={path} exact={exact}>
                  {logged ? <Redirect to="/" /> : <Component headTitle={title} setMeta={setMeta} />}
                </Route>
              );
            }

            return (
              <Route sensitive key={path} path={path} exact={exact}>
                <Component headTitle={title} setMeta={setMeta} />
              </Route>
            );
          })}
          <Route
            render={({ staticContext }) => {
              if (staticContext) staticContext.status = 404;
              return <NotFound setMeta={setMeta} />;
            }}
          />
        </Switch>
        <Loader show={showLoader} />
        {promoButtonUrl && (
          <FixedButton
            onClick={() => {
              history.push(promoButtonUrl);
              disablePromoButton();
            }}
            onClose={disablePromoButton}
            text="Вернуться к промокоду"
          />
        )}
        {!isFromApp() && (
          <div className="bottom-notification__container">
            {!hideCookie && (
              <BottomNotification key="cookie">
                <CookieNotify onHide={setHideCookie} />
              </BottomNotification>
            )}
            <SessionTimer />
          </div>
        )}
      </WithHeaderFooter>
      {logged && isNotGotoPage && (
        <>
          {!userCountry && (
            <div
              style={{
                position: 'fixed',
                top: '0',
                left: '0',
                zIndex: '1000',
                width: '100%',
                height: '100%',
              }}
            />
          )}
          {userCountry && !userCountry.label && <Countries isMainPage />}
        </>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  disablePromoButton: () => dispatch({ type: 'SET_PROMO_BUTTON_URL', payload: '' }),
  setCountryUser: (payload) => dispatch({ type: 'SAVE_COUNTRY', payload }),
  setLogged: (val) => dispatch({ type: 'SET_LOGGED', payload: val }),
  saveToken: ({ accessToken }) => dispatch({ type: 'SAVE_TOKENS', payload: { accessToken } }),
});

const mapStateToProps = ({ user, dataConfig, appReducer }) => ({
  promoButtonUrl: appReducer.promoButtonUrl,
  logged: user.logged || (!isNode() && window.isLoggedFromNode),
  email: user.logged && user.user.email,
  showApproveEmail: user.user.emailApproved === false,
  showLoader: appReducer.showLoader,
  user: user.logged && user.user,
  userCountry: user.user.delivery_countries,
  userIsPending: dataConfig.user.isPending,
  userLoaded: dataConfig.user.loaded,
  userId: user.user.id,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
