/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import uniqid from 'uniqid';
import { Grid, Row, Col, Button } from "react-bootstrap";
import { useSpring, animated, useTransition } from 'react-spring';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import formatMoney from '../../../helpers/utils/format-money';
import useWindowSize from '../../shared/hooks/window-size';

const propTypes = {
  balance: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        available: PropTypes.string,
        id: PropTypes.string,
        waiting: PropTypes.string
      })
    )
  })
};

const defaultProps = {
  balance: null
};

function HeaderBalance({ balance, setBalanceIsOpenToStore }) {
  const [showBalance, setShowBalance] = useState(true);
  const [showData, setShowData] = useState(false);
  const [balanceData, setBalanceData] = useState({ wait: [], ready: [] });

  const { width } = useWindowSize();
  const [count, setCount] = useState(0);
  const [showTitles, setShowTitles] = useState(true);

  useEffect(() => {
    setBalanceIsOpenToStore(showBalance);
  }, [setBalanceIsOpenToStore, showBalance]);

  useEffect(() => {
    if (balance && balance.data) {
      const balanceFormatted = { wait: [], ready: [] };
      balance.data.forEach((cur) => {
        let curSign = '';
        switch (cur.id) {
          case 'RUB':
            curSign = '‎₽';
            break;
          case 'EUR':
            curSign = '‎€';
            break;
          case 'USD':
            curSign = '‎$';
            break;
          default:
            curSign = '‎';
        }

        setCount((oldCount) => oldCount + cur.waiting.length + cur.available.length);

        balanceFormatted.wait = [
          ...balanceFormatted.wait,
          { amount: { num: cur.waiting, sign: curSign }, id: uniqid() }
        ];

        if (+cur.available !== 0)
          balanceFormatted.ready = [
            ...balanceFormatted.ready,
            { amount: { num: cur.available, sign: curSign }, id: uniqid() }
          ];
      });
      setBalanceData(balanceFormatted);
      setShowData(true);
    }
  }, [balance]);

  useEffect(() => {
    if (balanceData.wait.length || balanceData.ready.length)
      setShowTitles(count < 26 || width > 1200);
  }, [count, width, balanceData]);

  const animation = {
    show: {
      opacity: 1,
      minWidth: '40px',
      width: '100%',
      height: '57px'
    },
    hide: {
      minWidth: '120px',
      width: '0%'
    }
  };

  const buffer = useSpring({ marginBottom: showBalance ? '60px' : '0px' });
  const props = useSpring(showBalance ? animation.show : animation.hide);

  const transitions = useTransition(showBalance, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  });

  return (
    <>
      <animated.div className="balance__animated" style={props}>
        <div className="header-bottom__balance header-bottom__balance">
          {showData && (
            <>
              <Grid>
                <Row>
                  {transitions.map(
                    ({ item, key, props: transitionProps }) =>
                      item && (
                        <animated.div key={key} style={transitionProps}>
                          <Col xs={12}>
                            <div className="balance-wrapper">
                              <Link to="/user/payout/">
                                <div className="balance-ready balance-line">
                                  {/* <StatusIcon className="icon" type="success" /> */}
                                  <svg className="icon icon_green">
                                    <use xlinkHref="/assets/svg/sprite-v2.svg#available" />
                                  </svg>
                                  <span className="accounts">
                                    {showTitles && 'Доступно: '}
                                    <span className="amount">
                                      {balanceData.ready.length !== 0 ? (
                                        balanceData.ready.map((cur) => (
                                          <span key={cur.id}>
                                            {formatMoney(cur.amount.num)}
                                            <span className="cur-sign fix-ruble">
                                              {cur.amount.sign}
                                            </span>
                                          </span>
                                        ))
                                      ) : (
                                        <span key="rub">
                                          0.00<span className="cur-sign fix-ruble">₽</span>
                                        </span>
                                      )}
                                    </span>
                                  </span>
                                  {/* <span className="balance-link">Вывести</span> */}
                                  <span className="balance-link">Вывести</span>
                                </div>
                              </Link>
                              <Link to="/user/history/">
                                <div className="balance-wait balance-line">
                                  {/* <StatusIcon className="icon" type="wait" /> */}
                                  <svg className="icon" fill="#FFB039">
                                    <use xlinkHref="/assets/svg/sprite-v2.svg#purchase-waiting" />
                                  </svg>
                                  <span className="accounts">
                                    {showTitles && 'В ожидании: '}
                                    <span className="amount">
                                      {balanceData.wait.map((cur) => (
                                        <span key={cur.id}>
                                          {cur.amount.num}
                                          <span className="cur-sign fix-ruble">
                                            {cur.amount.sign}
                                          </span>
                                        </span>
                                      ))}
                                    </span>
                                  </span>
                                  <span className="balance-link">История</span>
                                </div>
                              </Link>
                            </div>
                          </Col>
                        </animated.div>
                      )
                  )}
                </Row>
              </Grid>
            </>
          )}
        </div>
      </animated.div>

      <animated.div style={buffer} className="balance__buffer" />

      <div
        className={`balance-close__wrapper balance-close__wrapper${
          showBalance ? '_show' : '_hide'
        }`}
        onClick={() => setShowBalance(!showBalance)}
        onKeyPress={() => setShowBalance(!showBalance)}
        role="button"
        tabIndex="0"
      >
        <svg className="icon balance-close">
          <use xlinkHref="/assets/svg/sprite-v2.svg#wallet" />
        </svg>
        <svg className="icon balance-close arrow">
          <use xlinkHref="/assets/svg/sprite-v2.svg#arrow-left" />
        </svg>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setBalanceIsOpenToStore: (val) => dispatch({ type: 'SET_BALANCE_IS_OPEN', payload: val })
});

HeaderBalance.propTypes = propTypes;
HeaderBalance.defaultProps = defaultProps;

export default connect(null, mapDispatchToProps)(HeaderBalance);
