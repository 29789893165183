import { createContext } from 'react';

/**
 * Схема контекста, больше информации в with-programs
 */
const initialState = {
  getPrograms: () => {},
  getPromocodes: () => {},
  appendNextPrograms: () => {},
  setFilters: () => {},
  getCountries: () => {},
  getCategories: () => {},
  getFavPrograms: () => {},
  deleteFavProgram: () => {},
  addFavProgram: () => {},
  setCategory: () => {},
  setCountry: () => {},
  setType: () => {},
  appendNextPromocodes: () => {},
  getPromoProgram: () => {}
};

const ProgramsActionsContext = createContext(initialState);

export default ProgramsActionsContext;
