import BaseModel from '../../base/base-model';
import GeneralConditions from './general-conditions';
import IndividualConditions from './individual-conditions';
import MaxholdConditions from './maxhold-conditions';
import MaxholdDoneConditions from './maxhold-done-conditions';

export default class Descriptions extends BaseModel {
  resourceName() {
    return 'descriptions';
  }

  relationships() {
    return {
      general_conditions: new GeneralConditions(),
      individual_conditions: new IndividualConditions(),
      maxhold_conditions: new MaxholdConditions(),
      maxhold_done_conditions: new MaxholdDoneConditions()
    };
  }

  fields() {
    return ['description', 'rateFile', 'rateFileLinkName'];
  }
}
