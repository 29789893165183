import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';

import Button from '../shared/button/button';

import './not-found.scss';

export default function NotFound({ setMeta }) {
  setMeta({
    title: 'Ошибка 404: Страница не найдена',
    withoutAddition: true,
    disableDescription: true,
    disableKeywords: true
  });

  return (
    <Grid>
      <Row>
        <Col xs={12}>
          <div className="not-found">
            <div className="not-found__title">404</div>
            <div className="not-found__body">
              Очень жаль! <br />
              Запрашиваемой страницы не существует.
              <br />
              Возможно, вы неправильно ввели адрес.
            </div>
            <a href="/">
              <Button type="empty">Перейти на главную</Button>
            </a>
            <a className="not-found__link" href="mailto:support@skidka.ru">
              написать нам письмо
            </a>
          </div>
        </Col>
      </Row>
    </Grid>
  );
}
