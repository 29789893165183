import React from 'react';
import { Col } from 'react-bootstrap';

export default function Contacts() {
  return (
    <>
      <Col xs={12} md={3}>
        <div className="footer__coop footer__col">
          <div className="footer__title">Сотрудничество</div>
          <div>
            <a href="email:partner@skidka.ru" aria-label="Email адрес для сотрудничества">
              partner@skidka.ru
            </a>
          </div>
        </div>
      </Col>
      <Col xs={12} md={2}>
        <div className="footer__support footer__col">
          <div className="footer__title">Поддержка</div>
          <div>
            <a
              href="email:support@skidka.ru"
              aria-label="Email адрес службы поддержки: support@skidka.ru, круглосуточно"
            >
              support@skidka.ru
            </a>
            <div>круглосуточно</div>
          </div>
        </div>
      </Col>
    </>
  );
}
