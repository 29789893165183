import * as Sentry from '@sentry/browser';

/**
 * Отправляет сообщение в sentry
 * @param {string} err
 */
export default function captureSentryBrowser(err) {
  Sentry.captureMessage(err);
}

/**
 * Отправляет ошибку в sentry
 * @param {Error} err
 */
export function captureSentryError(err) {
  Sentry.captureException(err);
}
