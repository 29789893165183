import React from 'react';
import loadable from '@loadable/component';

const FavShops = loadable(() =>
  import(/* webpackChunkName: "shops" */ '../components/shops/fav-shops')
);

export default function FavoritesPage() {
  return <FavShops />;
}
