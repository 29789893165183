import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import requestAccessForAdmin from '../../components/admin/enter-by-user/session-timer/request-acces-for-admin';
import getTokenData from '../../helpers/utils/get-token-data';
import dataController from '../../data-controller/data-controller';
import getSearchParams from '../../helpers/utils/get-search-params';

function AuthByPage({
  isAdmin,
  history,
  userLoaded,
  token,
  logged,
  setLogged,
  saveTokens,
  saveUserId,
  resetStore
}) {
  useEffect(() => {
    (async () => {
      if (userLoaded) {
        if (isAdmin) {
          const userId = getSearchParams('userId', document.location.href);
          if (!userId) {
            history.push('/');
            return;
          }

          const accessToken = await requestAccessForAdmin({
            userId,
            resetStore: true
          });

          setLogged(true);
          saveTokens({ accessToken });
          saveUserId(userId);
        }

        history.push('/');
      }
    })();
  }, [history, isAdmin, resetStore, saveTokens, saveUserId, setLogged, userLoaded]);

  useEffect(() => {
    if (token && logged) {
      const { superuser } = getTokenData(token);
      if (superuser) dataController.get(['user']);
    }
  }, [token, logged]);

  return <></>;
}

const mapDispatchToProps = (dispatch) => ({
  setLogged: (val) => dispatch({ type: 'SET_LOGGED', payload: val }),
  saveTokens: ({ accessToken }) => dispatch({ type: 'SAVE_TOKENS', payload: { accessToken } }),
  saveUserId: (userId) =>
    dispatch({
      type: 'SET_USER_ID',
      payload: userId
    })
});

const mapStateToProps = ({ user, dataConfig, appReducer }) => ({
  isAdmin: user.user.isAdmin,
  logged: user.logged,
  userLoaded: dataConfig.user.loaded,
  token: appReducer.tokens.accessToken
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AuthByPage));
