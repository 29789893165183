import ModelWithTokens from '../base/base-model-with-tokens';

export default class DeliveryCountries extends ModelWithTokens {
  resourceName() {
    return 'delivery_countries';
  }

  fields() {
    return ['label', 'shortName', 'isFilter', 'isDelivery', 'phoneCode'];
  }
}
