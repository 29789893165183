import dayjs from 'dayjs';
import { makeAutoObservable, when, reaction } from 'mobx';
import showToast from 'src/components/shared/toast/show-toast';
import datestampIsExpired from 'src/helpers/utils/datestamp-expired';
import getSmsTime from 'src/helpers/utils/get-sms-time';
import getTokenData from 'src/helpers/utils/get-token-data';
import EmailFieldTransport from './email-field-transport';

type Pages =
  | 'attach'
  | 'disabled'
  | 'approve-old'
  | 'approve-old-sent'
  | 'approve-new-sent'
  | 'approve-sent-again'
  | 'approve-phone'
  | 'approve-phone-sent'
  | 'new-email'
  | 'new-email-only';

export default class EmailFieldStore {
  emailApproved = false;

  userId = '';

  transport: EmailFieldTransport;

  email = '';

  newEmail = '';

  emailProve = '';

  phone = '';

  page: Pages = 'attach';

  phoneToken = '';

  approvedPhoneToken = '';

  approvedEmailToken = '';

  code = '';

  validation = '';

  nextTimestampForSms: number;

  refreshUser: () => void;

  constructor(transport: EmailFieldTransport) {
    makeAutoObservable(this, {
      transport: false,
      onChangeEmail: false,
      onApproveOldEmail: false,
      onApprovePhone: false,
      onSendCode: false
    });
    this.transport = transport;

    when(
      () => this.page === 'attach' && !!this.email,
      () => this.setPage('disabled')
    );

    reaction(
      () => ({
        email: this.email,
        newEmail: this.newEmail,
        emailProve: this.emailProve,
        phone: this.phone,
        page: this.page
      }),
      (value, previousValue) => {
        if (
          value.email !== previousValue.email ||
          value.newEmail !== previousValue.newEmail ||
          value.emailProve !== previousValue.emailProve ||
          value.phone !== previousValue.phone ||
          value.page !== previousValue.page
        ) {
          this.setValidation('');
        }
      }
    );
  }

  resetField = () => {
    this.setPage(this.email ? 'disabled' : 'attach');
    this.setValidation('');
    this.setCode('');
    this.setNewEmail('');
    this.phoneToken = '';
    this.approvedPhoneToken = '';
    this.approvedEmailToken = '';
    this.emailProve = '';
  };

  setEmailApproved = (value: boolean) => {
    this.emailApproved = value;
  };

  setUserId = (value: string) => {
    this.userId = value;
  };

  setEmail = (value: string) => {
    this.email = value;
  };

  setNewEmail = (value: string) => {
    this.newEmail = value;
  };

  setProveEmail = (value: string) => {
    this.emailProve = value;
  };

  setPhone = (value: string) => {
    this.phone = value;
  };

  setCode = (value: string) => {
    this.code = value;
  };

  setPage = (value: Pages) => {
    this.page = value;
  };

  setValidation = (value: string) => {
    this.validation = value;
  };

  setRefreshUser = (cb: () => void) => {
    this.refreshUser = cb;
  };

  calculateNextTimestampForSms = (token: string) => {
    const timeFromToken: number = getSmsTime(token);
    const nowTimestamp = dayjs(new Date()).unix();
    this.nextTimestampForSms = nowTimestamp + timeFromToken;
  };

  get emailProveEqual() {
    return this.newEmail === this.emailProve;
  }

  onChangeEmail = (again: boolean = false) => {
    this.setValidation('');
    if (!this.phone) this.onApproveOldEmail(again);
    else this.onApprovePhone();
  };

  onApproveOldEmail = async (again: boolean = false) => {
    try {
      await this.transport.onApproveOldEmail();
      showToast({ msg: 'Письмо отправлено' });
      this.setPage(again ? 'approve-sent-again' : 'approve-old-sent');
    } catch (errors) {
      this.setValidation(errors[0].detail);
    }
  };

  onConfirmEmail = async ({ code, token }: { code: string; token: string }) => {
    try {
      this.approvedEmailToken = await this.transport.onApproveEmailToken({ code, token });
      this.setPage('new-email');
    } catch (errors) {
      this.setValidation(errors[0].detail);
    }
  };

  onSendNewEmail = async () => {
    try {
      if (!this.newEmail) {
        this.setValidation('Введите email');
        return;
      }

      if (!this.emailProveEqual) {
        this.setValidation('Поля не совпадают');
        return;
      }

      if (!this.emailApproved) {
        await this.transport.changeUserEmail({ userId: this.userId, email: this.newEmail });
      } else {
        await this.transport.onSendNewEmail({
          token: this.phone ? this.approvedPhoneToken : this.approvedEmailToken,
          email: this.newEmail
        });
      }

      this.setEmail(this.newEmail);
      showToast({ msg: 'Email успешно изменен' });
      this.resetField();
    } catch (errors) {
      this.setValidation(errors[0].detail);
    }
  };

  onApproveNewEmail = async ({ code, token }: { code: string; token: string }) => {
    try {
      const approvedToken = await this.transport.onApproveEmailToken({ code, token });
      await this.transport.onSendNewEmail({ token: approvedToken });
      this.resetField();
      showToast({ msg: 'Email успешно изменен' });
      this.refreshUser();
    } catch (errors) {
      this.setValidation(errors[0].detail);
    }
  };

  onApprovePhone = async () => {
    try {
      if (this.phoneToken && dayjs(new Date()).unix() < this.nextTimestampForSms) {
        this.setPage('approve-phone-sent');
        return;
      }

      const token = await this.transport.onApprovePhone();
      this.phoneToken = token;
      this.calculateNextTimestampForSms(token);
      this.setPage('approve-phone-sent');
    } catch (errors) {
      this.setValidation(errors[0].detail);
    }
  };

  onSendCode = async () => {
    try {
      this.approvedPhoneToken = await this.transport.onSendCode({
        code: this.code,
        token: this.phoneToken
      });
      this.setPage('new-email');
    } catch (errors) {
      this.setValidation(errors[0].detail);
    }
  };

  onAttachNewEmail = async (again?: boolean) => {
    try {
      if (this.emailApproved) {
        this.approvedPhoneToken = await this.transport.onAttachNewEmail({
          email: this.newEmail
        });
        this.setPage(again ? 'approve-sent-again' : 'approve-new-sent');
      } else {
        await this.transport.changeUserEmail({ userId: this.userId, email: this.newEmail });
        this.setEmail(this.newEmail);
        showToast({ msg: 'Email успешно изменен' });
        this.resetField();
      }
    } catch (errors) {
      this.setValidation(errors[0].detail);
    }
  };
}
