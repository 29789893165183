import React, { useEffect, useState } from 'react';
import useInterval from '../hooks/use-interval';

function getHumanTime(time: number) {
  const seconds = time % 60;
  const minutes = (time - seconds) / 60;

  if (seconds <= 0 && minutes <= 0) return '0:00';

  return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
}

interface TimerProps {
  nextTime: number;
  hideOnEnd?: boolean;
  onEnd: () => void;
}

export default function Timer({
  nextTime = 0,
  onEnd,
  hideOnEnd = true,
}: TimerProps): React.ReactElement {
  const [time, setTime] = useState(nextTime);
  const [started, setStarted] = useState(false);

  const timeoutCb = () => {
    setStarted(true);
    setTime((prevTime) => {
      const newTime = prevTime - 1;
      return newTime;
    });
  };

  const cancelInterval = useInterval(timeoutCb, 1000);

  useEffect(() => {
    if (nextTime) setTime(nextTime);
  }, [nextTime]);

  useEffect(() => {
    if (time === 0 && started) {
      onEnd();
      cancelInterval();
    }
  }, [cancelInterval, onEnd, started, time]);

  return <>{(nextTime || !hideOnEnd) && <span>{getHumanTime(time)}</span>}</>;
}
