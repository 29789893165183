import ModelWithTokens from '../base/base-model-with-tokens';

export default class Notifies extends ModelWithTokens {
  resourceName() {
    return 'notifies';
  }

  fields() {
    return ['dateStamp', 'body', 'isReaded'];
  }
}
