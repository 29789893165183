import store from '../../../../store-config';
import DeliveryCountries from '../../../json-api/models/other/delivery-countries';

export default async function getDeliveryCountries() {
  const countriesModel = new DeliveryCountries();
  const { data: countriesData } = await countriesModel.get();
  const countries = [
    ...countriesData.map(({ label, shortName: value, isFilter, isDelivery, phoneCode, id }) => ({
      label,
      value,
      isFilter,
      isDelivery,
      phoneCode,
      id,
    })),
  ];

  store.dispatch({ type: 'SAVE_COUNTRIES', payload: countries });
  return countries;
}
