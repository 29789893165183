export const ActionType = {
  CHANGE_TYPE: 'history/changeType',
  LOAD_CLICKS: 'history/loadClicks',
  LOAD_SALES: 'history/loadSales',
  LOAD_PAYOUTS: 'history/loadPayouts',
  LOAD_STATS: 'history/loadStats',
  SET_LOADED: 'history/setLoaded',
  SET_DATA_LOADED: 'history/setDataLoaded',
  SET_FILTERS: 'history/setFilters',
  SET_FILTERS_COUNTER: 'history/setFiltersCounter',
  RESET_FILTERS: 'history/resetFilters',
  RESET_TYPE: 'history/resetType',
};

export const changeType = (type) => ({
  type: ActionType.CHANGE_TYPE,
  payload: type,
});

export const loadClicks = (data) => ({
  type: ActionType.LOAD_CLICKS,
  payload: data,
});

export const loadSales = (data) => ({
  type: ActionType.LOAD_SALES,
  payload: data,
});

export const loadPayouts = (data) => ({
  type: ActionType.LOAD_PAYOUTS,
  payload: data,
});

export const loadStats = (data) => ({
  type: ActionType.LOAD_STATS,
  payload: data,
});

export const setLoaded = (loadedStatus) => ({
  type: ActionType.SET_LOADED,
  payload: loadedStatus,
});

export const setDataLoaded = (dataLoadedStatus) => ({
  type: ActionType.SET_DATA_LOADED,
  payload: dataLoadedStatus,
});

export const setFilters = (filters) => ({
  type: ActionType.SET_FILTERS,
  payload: filters,
});

export const setFiltersCounter = (counter) => ({
  type: ActionType.SET_FILTERS_COUNTER,
  payload: counter,
});

export const resetFilters = () => ({
  type: ActionType.RESET_FILTERS,
});

export const resetType = () => ({
  type: ActionType.RESET_TYPE,
});
