import BaseModel from '../base/base-model';
import ProgramMisc from './program-misc';

export default class Promocodes extends BaseModel {
  resourceName() {
    return 'promocodes';
  }

  relationships() {
    return {
      program_misc: new ProgramMisc()
    };
  }

  fields() {
    return [
      'dateTo',
      'fullDescription',
      'promocode',
      'shortDescription',
      'title',
      'position',
      'cashbackBlocking',
      'kind',
      'discountFix',
      'discountPercent'
    ];
  }
}
