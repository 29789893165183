import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';

import './session-timer.scss';
import Button from '../../../shared/button/button';
import getTokenData from '../../../../helpers/utils/get-token-data';
import Timer from '../../../shared/timer/timer';
import requestAccessForAdmin from './request-acces-for-admin';

function SessionTimer({ login, id, token, logged, setLogged, saveToken, saveUserId }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!logged) setShow(false);
  }, [logged]);

  useEffect(() => {
    if (token) {
      const { superuser } = getTokenData(token);
      if (superuser) setShow(true);
    }
  }, [token]);

  async function onExit() {
    document.location.reload();
  }

  return (
    <>
      {show && (
        <div className="session-timer__wrapper">
          <div className="session-timer__body">
            <div className="session-timer">
              Вы вошли под <b>{login}</b> (ID:&nbsp;{id}), выход через:{' '}
              <div className="time-counter">
                {token && (
                  <Timer
                    key={token}
                    nextTime={getTokenData(token).exp - dayjs(new Date()).unix()}
                    onEnd={() => {
                      document.location.pathname = '/admin/';
                    }}
                  />
                )}
              </div>
            </div>
            <div className="session-timer__btns">
              <Button
                onClick={async () => {
                  const accessToken = await requestAccessForAdmin({ userId: id });
                  setLogged(true);
                  saveToken({ accessToken });
                  saveUserId(id);
                }}
                type="white"
              >
                Продлить
              </Button>
              <Button onClick={() => onExit()} type="white">
                Выйти
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = ({ user, appReducer }) => ({
  logged: user.logged,
  login: user.user.login,
  id: user.user.id,
  token: appReducer.tokens.accessToken
});

const mapDispatchToProps = (dispatch) => ({
  setLogged: (val) => dispatch({ type: 'SET_LOGGED', payload: val }),
  saveToken: ({ accessToken }) => dispatch({ type: 'SAVE_TOKENS', payload: { accessToken } }),
  saveUserId: (userId) =>
    dispatch({
      type: 'SET_USER_ID',
      payload: userId
    }),
  resetStore: () => dispatch({ type: 'RESET_STORE' })
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionTimer);
