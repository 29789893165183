import React, { forwardRef } from 'react';
import Select from 'react-select';

import './styled-react-select.scss';

const StyledReactSelect = forwardRef((props, ref) => (
  <Select
    {...props}
    ref={ref}
    tabIndex={-1}
    className={`select-main-container ${props.className || ''}`}
    classNamePrefix="select-main"
  />
));

export default StyledReactSelect;
