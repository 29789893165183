import { Entity } from 'src/json-api/framework/types';
import SiteApi from '../base/site-api';

export default class ApproveCurrentEmail extends SiteApi implements Entity {
  resourceName = 'approve_current_email';

  alwaysWithAuth = true;

  fields: Partial<{
    token: string;
  }>;

  responseDelete: never;

  responseCreate: '';

  responseUpdate: '';

  relations: {};
}
