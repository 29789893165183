import Categories from '../../../json-api/models/programs/categories';

export default async function getCategories() {
  const categoriesModel = new Categories();
  const { data: categories } = await categoriesModel.get();
  return [
    { label: 'Все категории', value: null, translitName: '' },
    { label: 'Избранные магазины', value: 'fav', translitName: 'fav', loggedOnly: true },
    ...categories.map((cat) => ({
      label: cat.name,
      value: cat.id,
      seoText: cat.seoText,
      translitName: cat.alias
    }))
  ];
}
