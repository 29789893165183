import Banners from '../../../json-api/models/slider/banners';
import BannersWithTokens from '../../../json-api/models/slider/banners-with-tokens';
import Programs from '../../../json-api/models/programs/programs';
import ProgramsWithTokens from '../../../json-api/models/programs/programs-with-tokens';

export default async function getBanners({ logged }) {
  let bannersModel;
  if (logged) {
    bannersModel = new BannersWithTokens();
  } else {
    bannersModel = new Banners();
  }
  const { data: banners } = await bannersModel.get();

  let programsIds = [];
  banners.forEach(({ programId }) => {
    if (programId && !programsIds.includes(programId)) programsIds = [...programsIds, programId];
  });

  let programsModel;
  if (logged) {
    programsModel = new ProgramsWithTokens();
  } else {
    programsModel = new Programs();
  }

  const programsToUri = {};

  if (programsIds.length > 0) {
    const { data: programs } = await programsModel.where('id', programsIds.join(',')).with('').get();
    programs.forEach(({ id, uriCode }) => {
      programsToUri[id] = uriCode;
    });
  }

  const bannersFormatted = {
    mobile: [],
    desktop: [],
    promo: [],
    promoMobile: [],
    desktop_not_auth: [],
  };
  banners.forEach((banner) => {
    const bannerRes = { ...banner };
    if (banner.deeplink) {
      bannerRes.url = banner.deeplink;
    } else {
      if (banner.programId) {
        bannerRes.url = `/${
          bannerRes.place.includes('promocode') ? 'promocode' : 'shops'
        }/details/${encodeURIComponent(programsToUri[banner.programId])}/`;
      }

      if (banner.promocodeId) {
        bannerRes.url += `?promocode_id=${banner.promocodeId}`;
      }
    }

    switch (bannerRes.place) {
      case 'banner_mobile':
        if (logged) {
          bannersFormatted.mobile = [...bannersFormatted.mobile, bannerRes];
        }
        break;
      case 'banner_big':
        bannersFormatted.desktop = [...bannersFormatted.desktop, bannerRes];
        break;
      case 'banner_big_not_auth':
        bannersFormatted.desktop_not_auth = [...bannersFormatted.desktop_not_auth, bannerRes];
        break;
      case 'banner_promocode':
        bannersFormatted.promo = [...bannersFormatted.promo, bannerRes];
        break;
      case 'banner_promocode_mobile':
        bannersFormatted.promoMobile = [...bannersFormatted.promoMobile, bannerRes];
        break;
      default:
        break;
    }
  });

  return bannersFormatted;
}
