import ModelWithTokens from '../base/base-model-with-tokens';

export default class BannersWithTokens extends ModelWithTokens {
  resourceName() {
    return 'banners';
  }

  fields() {
    return ['image', 'programId', 'place', 'deeplink', 'promocodeId'];
  }
}
