import React from 'react';
import loadable from '@loadable/component';

const ApproveReg = loadable(() =>
  import(/* webpackChunkName: "confirm-reg" */ '../../components/auth-reg/confirm-reg')
);

export default function ApproveRegRage() {
  return <ApproveReg />;
}
