/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useEffect, useState } from 'react';
import uniqid from 'uniqid';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import exit from '../../../helpers/utils/exit';
import formatMoney from '../../../helpers/utils/format-money';
import Button from '../../shared/button/button';
import MenuItem from '../menu-item';
import menuItemsData from '../menu-items-data';
import isNode from '../../../helpers/utils/is-node';

const propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    nickname: PropTypes.string,
    nicknameToShow: PropTypes.string,
    avatarPath: PropTypes.string,
    isAdmin: PropTypes.bool,
    balance: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          available: PropTypes.string,
          id: PropTypes.string,
          waiting: PropTypes.string
        })
      )
    })
  }),
  showMenuRight: PropTypes.func.isRequired,
  notifyCount: PropTypes.number,
  logged: PropTypes.bool,
  userLoaded: PropTypes.bool
};

const defaultProps = {
  user: {
    user: {
      balance: {
        data: []
      }
    }
  },
  notifyCount: 0,
  logged: false,
  userLoaded: false
};

function MenuRight({ user, showMenuRight, notifyCount, logged, userLoaded }) {
  const [balanceData, setBalanceData] = useState({ wait: [], ready: [] });

  useEffect(() => {
    if (user.balance && user.balance.data) {
      const balanceFormatted = { wait: [], ready: [] };
      user.balance.data.forEach((cur) => {
        let curSign = '';
        switch (cur.id) {
          case 'RUB':
            curSign = '‎₽';
            break;
          case 'EUR':
            curSign = '‎€';
            break;
          case 'USD':
            curSign = '‎$';
            break;
          default:
            curSign = '‎';
        }
        balanceFormatted.wait = [
          ...balanceFormatted.wait,
          { amount: `${formatMoney(cur.waiting)} ${curSign}`, id: uniqid() }
        ];
        if (cur.available !== '0.00')
          balanceFormatted.ready = [
            ...balanceFormatted.ready,
            {
              amount: `${formatMoney(cur.available)} ${curSign}`,
              id: uniqid()
            }
          ];
      });
      setBalanceData(balanceFormatted);
    }
  }, [user]);

  return (
    <>
      <div className="menu-right">
        {logged && userLoaded ? (
          <>
            <Link to="/user/profile/">
              <div className="user" onClick={() => showMenuRight(false)}>
                <div className="user__info">
                  <div className="name">
                    {user?.nicknameToShow?.length > 15
                      ? `${user.nicknameToShow.substr(0, 13)}...`
                      : user.nicknameToShow}
                  </div>
                  <div className="id">id {user.id}</div>
                  <div href="/profile" className="profile">
                    Настроить профиль
                  </div>
                </div>
                <div>
                  {user.avatarPath ? (
                    <img className="avatar" src={user.avatarPath} alt="" />
                  ) : (
                    <svg className="avatar">
                      <use xlinkHref="/assets/svg/sprite-v2.svg#user" />
                    </svg>
                  )}
                </div>
              </div>
            </Link>
            <div className="money">
              <Link to="/user/payout/">
                <div className="ready" onClick={() => showMenuRight(false)}>
                  <div className="title">Доступно к выводу</div>
                  {balanceData.ready.length !== 0 ? (
                    balanceData.ready.map((cur) => (
                      <div key={cur.id} className="money-line fix-ruble">
                        {cur.amount}
                      </div>
                    ))
                  ) : (
                    <div className="money-line fix-ruble">0.00 ₽</div>
                  )}
                </div>
              </Link>
              <Link to="/user/history/">
                <div className="wait" onClick={() => showMenuRight(false)}>
                  <div className="title">В ожидании</div>
                  {balanceData.wait.map((cur) => (
                    <div key={cur.id} className="money-line fix-ruble">
                      {cur.amount}
                    </div>
                  ))}
                </div>
              </Link>
            </div>
          </>
        ) : (
          <div className="menu__reg">
            <Link to="/user/auth/">
              <Button type="white" onClick={() => showMenuRight(false)}>
                Вход
              </Button>
            </Link>
            <Link to="/user/reg/">
              <Button type="white" onClick={() => showMenuRight(false)}>
                Регистрация
              </Button>
            </Link>
          </div>
        )}
      </div>
      {logged && userLoaded && (
        <div className="menu__items menu__items_right">
          {menuItemsData
            .filter(
              ({ isAdminItem, hideOnMobile }) =>
                !hideOnMobile && (!isAdminItem || (isAdminItem && user.isAdmin))
            )
            .map(({ to, spriteId, title, showNotifyCount, isAdminItem, isLogout }) => (
              <MenuItem
                key={spriteId}
                to={to}
                spriteId={spriteId}
                title={title}
                className={`menu__item ${
                  !isNode() && !isLogout && document.location.href.includes(to)
                    ? 'menu__item_current'
                    : ''
                }`}
                notifyCount={showNotifyCount && notifyCount}
                onClick={(e) => {
                  showMenuRight(false);
                  if (isAdminItem) {
                    e.preventDefault();
                    document.location.href = '/admin/';
                  }
                  if (isLogout) exit();
                }}
              />
            ))}
        </div>
      )}
    </>
  );
}

MenuRight.propTypes = propTypes;
MenuRight.defaultProps = defaultProps;
export default MenuRight;
