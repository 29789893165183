import dayjs from 'dayjs';
import { makeAutoObservable } from 'mobx';
import NotifiesStore from './notifies-store';

export default class NotifiesUIStore {
  private store: NotifiesStore;

  constructor(store: NotifiesStore) {
    makeAutoObservable(this);
    this.store = store;
  }

  get isLoading() {
    return this.store.isLoading;
  }

  get notifies() {
    return this.store.notifies
      .slice()
      .sort((a, b) => +b.dateStamp - +a.dateStamp)
      .map((notify) => ({
        date: dayjs.unix(+notify.dateStamp).format('DD.MM.YYYY'),
        body: notify.body,
        id: notify.id
      }));
  }

  load = () => this.store.loadNotifies();

  readAll = () => this.store.setAllRead();

  delete = (id: string) => this.store.deleteNotify(id);
}
