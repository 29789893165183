import React from 'react';

import Close from '../icons/close';
import './bottom-notification.scss';
import './notify.scss';

interface BottomNotificationProps {
  children: React.ReactNode | React.ReactNode[];
  canClose: boolean;
  onClose: () => void;
}

export default function BottomNotification({
  children,
  canClose,
  onClose,
}: BottomNotificationProps): React.ReactElement {
  return (
    <div className="bottom-notification">
      {canClose && <Close onClick={onClose} className="bottom-notification__close" />}
      {children}
    </div>
  );
}
