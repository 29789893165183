import React, { useEffect } from 'react';
import Notifications, { notify } from 'react-notify-toast';
import PropTypes from 'prop-types';
import './toast.scss';
import { connect } from 'react-redux';

const propTypes = {
  show: PropTypes.bool,
  msg: PropTypes.string,
  type: PropTypes.string,
  setShow: PropTypes.func.isRequired,
};

const defaultProps = {
  show: false,
  type: '',
  msg: '',
};

function ToastCustom({ show, setShow, msg, type, balanceIsOpen, zIndex }) {
  // Показать на 3 секунды и скрыть
  useEffect(() => {
    const options = {
      background: type === 'error' ? 'rgb(222, 83, 71)' : 'rgba(0, 0, 0, 0.75)',
      text: '#FFFFFF',
    };
    if (show) {
      notify.show(msg, 'custom', 3000, options);
      setTimeout(() => {
        setShow(false);
      }, 3000);
    }
  }, [msg, setShow, show, type]);

  return (
    <div className="toast-wrapper" style={{ top: balanceIsOpen ? '120px' : '60px', zIndex }}>
      <Notifications />
    </div>
  );
}

ToastCustom.propTypes = propTypes;
ToastCustom.defaultProps = defaultProps;

const mapStateToProps = ({ appReducer }) => ({
  balanceIsOpen: appReducer.balanceIsOpen,
});

export default connect(mapStateToProps)(ToastCustom);
