import { useEffect, useRef, useState } from 'react';

export default function useInterval(callback, delay) {
  const savedCallback = useRef();
  const [intevalId, setIntervalId] = useState();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      setIntervalId(id);
      return () => clearInterval(id);
    }

    return () => {};
  }, [delay]);

  return () => clearInterval(intevalId);
}
