export const ITEMS_PER_PAGE_COUNT = 20; // Количество элементов, запрашиваемых с сервера за один раз. Может быть от 1 до 50

export const Types = {
  CLICKS: 'Переходы',
  SALES: 'Покупки',
  PAYOUTS: 'Получение денег',
};

export const defaultType = Types.SALES;

const MONTHS = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

const WEEKDAYS_LONG = [
  'Воскресенье',
  'Понедельник',
  'Вторник',
  'Среда',
  'Четверг',
  'Пятница',
  'Суббота',
];

const WEEKDAYS_SHORT = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];

const FIRST_WEEK_DAY = 1;

export const DAY_PICKER_LOCALIZATION_PROPS = {
  locale: 'ru',
  months: MONTHS,
  weekdaysLong: WEEKDAYS_LONG,
  weekdaysShort: WEEKDAYS_SHORT,
  firstDayOfWeek: FIRST_WEEK_DAY,
};
