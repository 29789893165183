import React from 'react';
import loadable from '@loadable/component';
import preloadProgramsDataForSSR from '../context/programs-context/preload-programs-data-for-ssr';

const Shops = loadable(() =>
  import(/* webpackChunkName: "shops-category" */ '../components/shops/shops')
);

export default function ShopsCategoryPage({ setMeta }) {
  return <Shops isPromocodePage={false} setMeta={setMeta} />;
}

ShopsCategoryPage.serverFetch = async ({ isLogged, req }) => {
  const preloadedState = await preloadProgramsDataForSSR({
    isLogged,
    url: req.url
  });

  return {
    preloadedState
  };
};
