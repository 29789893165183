import React from "react";
import loadable from "@loadable/component";

const Dialog = loadable(() =>
  import(
    /* webpackChunkName: "dialog" */ "../../components/support/dialog/dialog"
  )
);

export default function SupportDialog() {
  return <Dialog />;
}
