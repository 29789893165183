import ModelWithTokens from '../base/base-model-with-tokens';

export default class IsNeedRequest extends ModelWithTokens {
  resourceName() {
    return 'is_need_request';
  }

  fields() {
    return ['needRequest'];
  }
}
