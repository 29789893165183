/* eslint-disable import/no-mutable-exports */
/* eslint-disable no-param-reassign */
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';

import appReducer from './store/app/app';
import account from './store/account/account';
import support from './store/support/support';
import history from './store/history/history';
import profile from './store/profile/profile';
import user from './store/user/user';
import auth from './store/auth/auth';
import pay from './store/pay/pay';
import other from './store/other/other';
import dataConfig from './store/data/data-config';
import programs from './store/programs/programs';
import isNode from './src/helpers/utils/is-node';

const rootReducer = combineReducers({
  appReducer,
  account,
  support,
  history,
  profile,
  user,
  auth,
  pay,
  other,
  dataConfig,
  programs,
});

const allReducer = (state, action) => {
  if (action.type === 'RESET_STORE') {
    state = undefined;
  }

  return rootReducer(state, action);
};

const config = {
  whitelist: ['RESET_STORE', 'SAVE_TOKENS', 'SET_LOGGED', 'SET_USER_ID'],
  channel: 'SK04983jjf_32!',
};

let store;
if (isNode()) {
  const composeEnhancers = compose;
  store = createStore(allReducer, composeEnhancers(applyMiddleware(thunk)));
} else {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  store = createStore(
    allReducer,
    window.REDUX_DATA,
    composeEnhancers(applyMiddleware(...[thunk, createStateSyncMiddleware(config)])),
  );

  initMessageListener(store);
}

export default store;
