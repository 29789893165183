import React from 'react';
import loadable from '@loadable/component';

const PhoneAttach = loadable(() =>
  import(/* webpackChunkName: "phone-attach" */ '../../components/profile/phone-attach')
);

export default function PhoneAttachPage() {
  return <PhoneAttach />;
}
