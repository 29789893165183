import UserPrograms from '../../../json-api/models/user/user-programs';

export default async function getUserPrograms() {
  const userPrograms = new UserPrograms();
  const { data } = await userPrograms.get();
  const dataObj = {};
  let dataNameToId = [];
  let names = [];
  let namesToLowerCase = [];
  if (data.length) {
    data.forEach(program => {
      dataObj[program.id] = program;
      dataNameToId = { ...dataNameToId, [program.name]: program.id };
      names = [...names, program.name];
      namesToLowerCase = { ...namesToLowerCase, [program.name.toLowerCase()]: program.name };
    });
  }

  return {
    dataObj,
    dataNameToId,
    names,
    namesToLowerCase
  };
}
