import BaseModel from '../base/base-model';

export default class CashbackRates extends BaseModel {
  resourceName() {
    return 'cashback_rates';
  }

  fields() {
    return ['fix', 'name', 'percent', 'percentMax', 'percentMin'];
  }
}
