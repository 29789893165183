import dayjs from 'dayjs';

const initialState = {
  // user
  user: {
    old: false,
    isPending: false,
    loaded: false,
    needLogin: true,
    lastReset: dayjs(new Date()).unix(),
    lastRequest: 0,
    saveTime: 0,
  },
  balance: {
    old: false,
    isPending: false,
    loaded: false,
    needLogin: true,
    lastReset: dayjs(new Date()).unix(),
    lastRequest: 0,
    saveTime: 0,
  },
  subscriptions: {
    old: false,
    isPending: false,
    loaded: false,
    needLogin: false,
    lastReset: dayjs(new Date()).unix(),
    lastRequest: 0,
    saveTime: 0,
  },
  isNeedRequest: {
    old: false,
    isPending: false,
    loaded: false,
    needLogin: true,
    lastReset: dayjs(new Date()).unix(),
    lastRequest: 0,
    saveTime: 0,
  },

  notifies: {
    old: false,
    isPending: false,
    loaded: false,
    needLogin: true,
    lastReset: dayjs(new Date()).unix(),
    lastRequest: 0,
    saveTime: 0,
  },
  currencies: {
    old: false,
    isPending: false,
    loaded: false,
    needLogin: true,
    lastReset: dayjs(new Date()).unix(),
    lastRequest: 0,
    saveTime: 0,
  },

  pay_nodes: {
    old: false,
    isPending: false,
    loaded: false,
    needLogin: true,
    lastReset: dayjs(new Date()).unix(),
    lastRequest: 0,
    saveTime: 0,
  },
  history: {
    old: false,
    isPending: false,
    loaded: false,
    needLogin: true,
    lastReset: dayjs(new Date()).unix(),
    lastRequest: 0,
    saveTime: 0,
  },
  programs: {
    old: false,
    isPending: false,
    loaded: false,
    needLogin: false,
    lastReset: dayjs(new Date()).unix(),
    lastRequest: 0,
    saveTime: 120,
    updateWhenLogged: true,
  },
  promocodes: {
    old: false,
    isPending: false,
    loaded: false,
    needLogin: false,
    lastReset: dayjs(new Date()).unix(),
    lastRequest: 0,
    saveTime: 120,
  },
  payTargets: {
    old: false,
    isPending: false,
    loaded: false,
    needLogin: true,
    lastReset: dayjs(new Date()).unix(),
    lastRequest: 0,
    saveTime: 0,
  },
  userPrograms: {
    old: false,
    isPending: false,
    loaded: false,
    needLogin: true,
    lastReset: dayjs(new Date()).unix(),
    lastRequest: 0,
    saveTime: 0,
  },
  // end of user

  // support
  tickets: {
    old: false,
    isPending: false,
    loaded: false,
    needLogin: true,
    lastReset: dayjs(new Date()).unix(),
    lastRequest: 0,
    saveTime: 0,
  },
  ticketStatuses: {
    old: false,
    isPending: false,
    loaded: false,
    needLogin: false,
    lastReset: dayjs(new Date()).unix(),
    lastRequest: 0,
    saveTime: 0,
  },
  ticketThemes: {
    old: false,
    isPending: false,
    loaded: false,
    needLogin: false,
    lastReset: dayjs(new Date()).unix(),
    lastRequest: 0,
    saveTime: 0,
  },
  curTicket: {
    old: false,
    isPending: false,
    loaded: false,
    needLogin: true,
    lastReset: dayjs(new Date()).unix(),
    lastRequest: 0,
    saveTime: 0,
  },
  // end of support
  social: {
    old: false,
    isPending: false,
    loaded: false,
    needLogin: false,
    lastReset: dayjs(new Date()).unix(),
    lastRequest: 0,
    saveTime: 0,
  },
  payObjects: {
    old: false,
    isPending: false,
    loaded: false,
    needLogin: false,
    lastReset: dayjs(new Date()).unix(),
    lastRequest: 0,
    saveTime: 0,
  },
  codes: {
    old: false,
    isPending: false,
    loaded: false,
    needLogin: true,
    lastReset: dayjs(new Date()).unix(),
    lastRequest: 0,
    saveTime: 0,
  },
  userForReload: {
    old: false,
    isPending: false,
    loaded: false,
    needLogin: true,
    lastReset: dayjs(new Date()).unix(),
    lastRequest: 0,
    saveTime: 0,
  },
  categories: {
    old: false,
    isPending: false,
    loaded: false,
    needLogin: false,
    lastReset: dayjs(new Date()).unix(),
    lastRequest: 0,
    saveTime: 600,
  },
  programDetails: {
    old: false,
    isPending: false,
    loaded: false,
    needLogin: false,
    lastReset: dayjs(new Date()).unix(),
    lastRequest: 0,
    saveTime: 0,
  },
  programPromocodeDetails: {
    old: false,
    isPending: false,
    loaded: false,
    needLogin: false,
    lastReset: dayjs(new Date()).unix(),
    lastRequest: 0,
    saveTime: 0,
  },
  promocodeDetails: {
    old: false,
    isPending: false,
    loaded: false,
    needLogin: false,
    lastReset: dayjs(new Date()).unix(),
    lastRequest: 0,
    saveTime: 0,
  },
  banners: {
    old: false,
    isPending: false,
    loaded: false,
    needLogin: false,
    lastReset: dayjs(new Date()).unix(),
    lastRequest: 0,
    saveTime: 0,
  },
  welcomePrograms: {
    old: false,
    isPending: false,
    loaded: false,
    needLogin: false,
    lastReset: dayjs(new Date()).unix(),
    lastRequest: 0,
    saveTime: 120,
  },
  deliveryCountries: {
    old: false,
    isPending: false,
    loaded: false,
    needLogin: false,
    lastReset: dayjs(new Date()).unix(),
    lastRequest: 0,
    saveTime: 0,
  },
  country: {
    old: false,
    isPending: false,
    loaded: false,
    needLogin: true,
    lastReset: dayjs(new Date()).unix(),
    lastRequest: 0,
    saveTime: 0,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'CLEAR_NOT_SHARED_DATA_CONFIG': {
      const newState = {};
      Object.keys(state).forEach((key) => {
        if (!state[key].saveTime) {
          newState[key] = initialState[key];
        }
      });
      return { ...state, ...newState };
    }
    case 'CHANGE_DATA_HANDLER':
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          ...payload,
        },
      };

    default:
      return state;
  }
};
