import ModelWithTokens from '../base/base-model-with-tokens';
import TicketFields from './ticket-fields';

export default class TicketThemes extends ModelWithTokens {
  resourceName() {
    return 'ticket_themes';
  }

  fields() {
    return ['label'];
  }

  relationships() {
    return {
      ticket_fields: new TicketFields()
    };
  }
}
