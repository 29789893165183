import BaseModel from '../../base/base-model';

export default class IndividualConditions extends BaseModel {
  resourceName() {
    return 'individual_conditions';
  }

  fields() {
    return ['text', 'title'];
  }
}
