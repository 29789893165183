import AuthWithTokens from '../../json-api/models/auth/auth-with-tokens';
import store from '../../../store-config';

/**
 * Очищает стор и отправляет запрос
 * на удаление access
 */
export default async function exit() {
  const auth = new AuthWithTokens();
  auth.id = 'access';
  try {
    await auth.delete();
    store.dispatch({ type: 'RESET_STORE' });
    document.location.reload();
  } catch (err) {
    document.location.reload();
    throw Error(`Не удалось выйти ${JSON.stringify(err)}`);
  }
}
