import getUserData from './type-loaders/user';
import getSubscriptions from './type-loaders/subscriptions';
import getIsNeedRequest from './type-loaders/is-need-request';
import getSocialLinks from './type-loaders/social';
import getPayObjects from './type-loaders/pay-objects';
import getTicketThemes from './type-loaders/ticket-themes';
import getTicketStatuses from './type-loaders/ticket-statuses';
import getUserPrograms from './type-loaders/user-programs';
import getTickets from './type-loaders/tickets';
import getCurrencies from './type-loaders/get-currencies';
import getCodes from './type-loaders/cashback-codes';
import getUserDataReload from './type-loaders/user-reload';
import getCategories from './type-loaders/categories';
import getPrograms from './type-loaders/programs';
import getProgramDetails from './type-loaders/get-program-details';
import getBanners from './type-loaders/banners';
import getWelcomePrograms from './type-loaders/welcome-programs';
import getPromocodes from './type-loaders/promocodes';
import getPromocodeDetails from './type-loaders/get-promocode-details';
import getBalance from './type-loaders/balance';
import getDeliveryCountries from './type-loaders/delivery-countries';
import getCountries from './type-loaders/countries';
import getPromocodeProgramDetails from './type-loaders/get-promocode-program-details';

export default function typeDict({ type, dispatch, getState, logged }) {
  const dict = {
    user: {
      get: getUserData,
      dispatchType: 'SAVE_USER_DATA',
    },
    subscriptions: {
      get: getSubscriptions,
      dispatchType: 'SAVE_ALL_SUBSCRIBES',
    },
    isNeedRequest: {
      get: getIsNeedRequest,
      dispatchType: 'SET_IS_NEED_REQUEST',
    },
    social: {
      get: getSocialLinks,
      dispatchType: 'SAVE_SOCIAL',
    },
    categories: {
      get: getCategories,
      dispatchType: 'SAVE_CATEGORIES',
    },
    payObjects: {
      get: getPayObjects,
      dispatchType: 'SAVE_PAY_OBJECTS',
    },
    ticketThemes: {
      get: getTicketThemes,
      dispatchType: 'SAVE_TICKETS_THEMES',
    },
    ticketStatuses: {
      get: getTicketStatuses,
      dispatchType: 'SAVE_TICKET_STATUSES',
    },
    userPrograms: {
      get: getUserPrograms,
      dispatchType: 'SAVE_USER_PROGRAMS',
    },
    tickets: {
      get: getTickets,
      dispatchType: 'SAVE_TICKETS_DATA',
    },
    currencies: {
      get: getCurrencies,
      dispatchType: 'SAVE_CURRENCIES',
    },
    codes: {
      get: getCodes,
      dispatchType: 'SAVE_CODES',
    },
    userForReload: {
      get: getUserDataReload,
      dispatchType: 'SAVE_USER_DATA',
    },
    programs: {
      get: getPrograms,
      dispatchType: 'SAVE_PROGRAMS',
    },
    promocodes: {
      get: getPromocodes,
      dispatchType: 'SAVE_PROGRAMS',
    },
    programDetails: {
      get: getProgramDetails,
      dispatchType: 'SAVE_PROGRAM_DETAILS',
    },
    programPromocodeDetails: {
      get: getPromocodeProgramDetails,
      dispatchType: 'SAVE_PROGRAM_PROMOCODE_DETAILS',
    },
    promocodeDetails: {
      get: getPromocodeDetails,
      dispatchType: 'SAVE_PROMOCODE_DETAILS',
    },
    banners: {
      get: getBanners,
      dispatchType: 'SAVE_BANNERS',
    },
    welcomePrograms: {
      get: getWelcomePrograms,
      dispatchType: 'SAVE_WELCOME_PROGRAMS',
    },
    balance: {
      get: getBalance,
      dispatchType: 'SAVE_BALANCE',
    },
    deliveryCountries: {
      get: getDeliveryCountries,
      dispatchType: 'SAVE_COUNTIES',
    },
    countries: {
      get: getCountries,
      dispatchType: 'SAVE_COUNTIES',
    },
  };

  return {
    get: async () => dict[type].get({ getState, logged }),
    dispatchData: (payload) =>
      dispatch({
        type: dict[type].dispatchType,
        payload,
      }),
  };
}
