import React, { useState, useEffect, useCallback, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';

import fetchCountries from './api-requests/fetch-countries';
import fetchCategories from './api-requests/fetch-categories';
import fetchPrograms from './api-requests/fetch-programs';
import Programs from '../../json-api/models/programs/programs';
import DeliveryCountries from '../../json-api/models/other/delivery-countries';
import Categories from '../../json-api/models/programs/categories';
import ProgramsStateContext, { typesDict } from './programs-state-context';
import ProgramsActionsContext from './programs-actions-context';
import fetchPromocodes from './api-requests/fetch-promocodes';
import fetchProgramsByIds from './api-requests/fetch-programs-by-ids';
import usePrevious from '../../components/shared/hooks/use-prev';
import { captureSentryError } from '../../helpers/utils/capture-sentry-browser';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
  initialState: PropTypes.shape({
    programs: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.instanceOf(Programs)),
      prev: PropTypes.string.isRequired,
      next: PropTypes.string.isRequired,
    }),
    countries: PropTypes.arrayOf(PropTypes.instanceOf(DeliveryCountries)),
    categories: PropTypes.arrayOf(PropTypes.instanceOf(Categories)),
    isPending: PropTypes.bool,
    category: PropTypes.shape({ value: PropTypes.string }),
    country: PropTypes.shape({ value: PropTypes.string }),
    type: PropTypes.shape({ value: PropTypes.string }),
  }),
};

const defaultProps = {
  initialState: {
    programs: { data: [], prev: '', next: '' },
    categories: [],
    countries: [],
    category: {},
    country: {},
    type: {},
  },
};

// TODO: нужно сделать обработку ошибок
// TODO: отклонять запрос, если он уже выполняется

/**
 * Обертка для контекста Programs
 * Задает значение и методы взаимодействия с данными контекста
 * initialState передается с сервера для поддержки ssr
 *
 * ? может получится тут использовать react.memo для повышения перфоманса
 */

function reducer(state, { type, payload }) {
  switch (type) {
    case 'programs/save': {
      const { programs, prev, next, count } = payload;
      return { ...state, programs, prev, next, programsCount: count };
    }
    case 'favPrograms/save': {
      return { ...state, favPrograms: payload };
    }
    case 'favPrograms/add': {
      return { ...state, favPrograms: [...state.favPrograms, payload] };
    }
    case 'favPrograms/delete': {
      return { ...state, favPrograms: state.favPrograms.filter(({ id }) => id !== payload) };
    }
    case 'programs/append': {
      const { programs, prev, next, count } = payload;
      return {
        ...state,
        programs: [...state.programs, ...programs],
        prev,
        next,
        programsCount: count,
      };
    }
    case 'programs/removeById': {
      return { ...state, programs: state.programs.filter(({ id }) => payload.includes(id)) };
    }
    case 'promocodes/save': {
      const { promocodes, prev, next, count } = payload;
      return { ...state, promocodes, prev, promocodesNext: next, promocodesCount: count };
    }
    case 'promocodes/append': {
      const { promocodes, next } = payload;
      return {
        ...state,
        promocodes: [...state.promocodes, ...promocodes],
        promocodesNext: next,
      };
    }
    case 'countries/save':
      return { ...state, countries: payload };
    case 'categories/save':
      return { ...state, categories: payload };
    case 'category/save':
      return { ...state, category: payload };
    case 'country/save':
      return { ...state, country: payload };
    case 'type/save':
      return { ...state, type: payload };
    case 'clearFilters':
      return { ...state, type: {}, country: {}, category: {} };
    case 'promoPrograms/save':
      return { ...state, promoPrograms: payload };
    default:
      return state;
  }
}

export default function WithPrograms({ children, initialState }) {
  const [state, dispatch] = useReducer(reducer, {
    programs: initialState.programs.data,
    prev: initialState.programs.prev,
    next: initialState.programs.next,
    countries: initialState.countries,
    categories: initialState.categories,
    types: typesDict,
    country: initialState.country,
    category: initialState.category,
    type: initialState.type,
    promocodes: [],
    promocodesNext: '',
    promocodesCount: '',
    programsCount: initialState.programs.count,
    favPrograms: [],
    promoPrograms: initialState.promoPrograms,
  });

  const { pathname } = useLocation();
  const prevPathname = usePrevious(pathname);

  const prevCategory = usePrevious(state.category);

  useEffect(() => {
    if (
      (prevPathname?.includes('shops') && !pathname.includes('shops')) ||
      (prevPathname?.includes('promocode') && !pathname.includes('promocode'))
    )
      dispatch({ type: 'clearFilters' });
  }, [pathname, prevPathname]);

  const setCategory = useCallback(
    (category) => {
      if (category || (!category && prevCategory))
        dispatch({ type: 'category/save', payload: category });
    },
    [prevCategory],
  );

  const setCountry = useCallback((country) => {
    if (country) dispatch({ type: 'country/save', payload: country });
  }, []);

  const setType = useCallback((type) => {
    if (type) dispatch({ type: 'type/save', payload: type });
  }, []);

  /**
   * Метод загрузки магазинов, который используют компоненты.
   * Если передать значение курсора в initialCursor,
   * то произойдет запрос с пагинацией.
   * Возвращает результат запроса или [], если что-то пошло не так.
   * @param {string} [initialCursor] - курсор
   */
  const getPrograms = useCallback(async ({ initialCursor, filters, isLogged } = {}) => {
    try {
      const { programs: programsResponse } = await fetchPrograms({
        isLogged,
        initialCursor,
        filters: {
          category: filters?.category?.value,
          country: filters?.country?.value,
          type: filters?.type,
        },
      });

      const selectedCat = filters?.category?.value;

      const { data, prev, next, count } = programsResponse;

      dispatch({
        type: 'programs/save',
        payload: {
          programs: data,
          prev,
          next,
          count,
        },
      });

      return programsResponse;
    } catch (error) {
      captureSentryError(error);
      dispatch({ type: 'programs/save', payload: { programs: [] } });
      return [];
    }
  }, []);

  const getPromocodes = useCallback(async ({ filters } = {}) => {
    try {
      const { promocodes: promocodesResponse } = await fetchPromocodes({
        filters: {
          category: filters?.category?.value,
          country: filters?.country?.value,
        },
      });
      const { data, prev, next, count } = promocodesResponse;

      dispatch({ type: 'promocodes/save', payload: { promocodes: data, prev, next, count } });

      return promocodesResponse;
    } catch (error) {
      captureSentryError(error);
      dispatch({ type: 'promocodes/save', payload: { promocodes: [] } });
      return [];
    }
  }, []);

  /**
   * Добавить следующие 20 магазинов к текущим
   */
  const appendNextPrograms = useCallback(async () => {
    try {
      const { programs } = await fetchPrograms({
        isLogged: false,
        cursor: state.next,
        filters: {
          category: state?.category?.value,
          country: state?.country?.value,
          type: state?.type?.value,
        },
      });
      const { data, prev, next, count } = programs;

      dispatch({ type: 'programs/append', payload: { programs: data, prev, next, count } });
    } catch (error) {
      captureSentryError(error);
    }
  }, [state?.category?.value, state?.country?.value, state.next, state?.type?.value]);

  const appendNextPromocodes = useCallback(async () => {
    try {
      const { promocodes } = await fetchPromocodes({
        cursor: state.promocodesNext,
        filters: {
          category: state?.category?.value,
          country: state?.country?.value,
        },
      });
      const { data, prev, next } = promocodes;

      dispatch({ type: 'promocodes/append', payload: { promocodes: data, prev, next } });
    } catch (error) {
      captureSentryError(error);
    }
  }, [state?.category?.value, state?.country?.value, state.promocodesNext]);

  /**
   * Запросить страны
   */
  const getCountries = useCallback(async () => {
    try {
      const { countries } = await fetchCountries();
      dispatch({ type: 'countries/save', payload: countries });
    } catch (error) {
      captureSentryError(error);
    }
  }, []);

  /**
   * Запросить категории
   */
  const getCategories = useCallback(async () => {
    try {
      const { categories } = await fetchCategories();
      dispatch({ type: 'categories/save', payload: categories });
    } catch (error) {
      captureSentryError(error);
    }
  }, []);

  const getPromoPrograms = useCallback(async () => {
    try {
      const data = await fetchProgramsByIds(['72', '233', '936']);
      dispatch({
        type: 'promoPrograms/save',
        payload: data,
      });
    } catch (error) {
      captureSentryError(error);
    }
  }, []);

  const getFavPrograms = useCallback(async ({ favIds }) => {
    try {
      if (favIds.length === 0) return;

      const newFavPrograms = await fetchProgramsByIds(favIds, true);
      dispatch({
        type: 'favPrograms/save',
        payload: newFavPrograms,
      });
    } catch (error) {
      captureSentryError(error);
    }
  }, []);

  const deleteFavProgram = useCallback((id) => {
    dispatch({
      type: 'favPrograms/delete',
      payload: id,
    });
  }, []);

  const addFavProgram = useCallback((program) => {
    dispatch({
      type: 'favPrograms/add',
      payload: program,
    });
  }, []);

  /**
   * Записываем методы работы с данными в контекст,
   * чтобы можно было к ним обратиться
   */
  const [actions, setActions] = useState({
    getPrograms,
    appendNextPrograms,
    getPromocodes,
    getCountries,
    getCategories,
    setCategory,
    setCountry,
    setType,
    getFavPrograms,
    deleteFavProgram,
    addFavProgram,
    appendNextPromocodes,
    getPromoPrograms,
  });

  useEffect(() => {
    setActions({
      getPrograms,
      appendNextPrograms,
      getPromocodes,
      getCountries,
      getCategories,
      setCategory,
      setCountry,
      setType,
      getFavPrograms,
      deleteFavProgram,
      addFavProgram,
      appendNextPromocodes,
      getPromoPrograms,
    });
  }, [
    appendNextPrograms,
    getCategories,
    getCountries,
    getPrograms,
    getPromocodes,
    setCategory,
    setCountry,
    setType,
    getFavPrograms,
    deleteFavProgram,
    addFavProgram,
    appendNextPromocodes,
    getPromoPrograms,
  ]);

  return (
    <ProgramsStateContext.Provider value={state}>
      <ProgramsActionsContext.Provider value={actions}>{children}</ProgramsActionsContext.Provider>
    </ProgramsStateContext.Provider>
  );
}

WithPrograms.propTypes = propTypes;
WithPrograms.defaultProps = defaultProps;
