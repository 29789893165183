import React from "react";
import loadable from "@loadable/component";

const ProfileDeletePage = loadable(() =>
  import(
    /* webpackChunkName: "delete-user" */ "../../components/profile/profile-delete-page"
  )
);

export default function DeleteUser() {
  return <ProfileDeletePage />;
}
