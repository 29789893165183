import { Entity } from 'src/json-api/framework/types';
import BlogApi from '../base/blog-api';

export default class Themes extends BlogApi implements Entity {
  resourceName = 'themes';

  fields: {
    name: string;
  };

  relations: never;

  responseDelete: never;

  responseCreate: never;

  responseUpdate: never;
}
