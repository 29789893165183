const initialState = { programDetails: {} };

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'RESET_DETAILS': {
      return {
        ...state,
        programDetails: {},
        promocodeDetails: {}
      };
    }

    case 'SAVE_PROGRAMS': {
      const programsById = {};
      payload.forEach(({ id, ...program }) => {
        programsById[id] = program;
      });

      return {
        ...state,
        programsData: payload,
        programsById
      };
    }

    case 'SAVE_PROGRAM_DETAILS':
      return {
        ...state,
        programDetails: payload
      };

    case 'SAVE_PROGRAM_PROMOCODE_DETAILS':
      return {
        ...state,
        programDetails: payload
      };

    case 'CLEAR_PROGRAM_DETAILS_REDIRECT':
      return {
        ...state,
        programDetails: {
          ...state.programDetails,
          redirect: null
        }
      };

    case 'SAVE_PROMOCODE_DETAILS':
      return {
        ...state,
        promocodeDetails: payload
      };

    case 'CLEAR_PROMOCODE_DETAILS_REDIRECT':
      return {
        ...state,
        programDetails: {
          ...state.programDetails,
          redirect: null
        }
      };

    case 'SAVE_WELCOME_PROGRAMS':
      return {
        ...state,
        welcomePrograms: payload
      };

    default:
      return state;
  }
};
