import React from 'react';
import PropTypes from 'prop-types';

import Button from '../shared/button/button';
import CookieIcon from './cookie-icon';

CookieNotify.propTypes = {
  onHide: PropTypes.func.isRequired
};

export default function CookieNotify({ onHide }) {
  return (
    <div className="bottom-notify">
      <CookieIcon />
      <div className="bottom-notify__text">
        Мы используем cookie для обеспечения работы сайта. Продолжая пользоваться сайтом, Вы
        принимаете политику использования файлов cookie.
      </div>
      <Button
        size="s"
        className="bottom-notify__btn"
        type="empty"
        onClick={() => {
          if (localStorage) localStorage.setItem('cookie-agree', true);
          onHide(true);
        }}
      >
        Понятно
      </Button>
    </div>
  );
}
