import { makeAutoObservable } from 'mobx';
import ApiModel from 'src/json-api/framework/api-model';
import { ResponseOne } from 'src/json-api/framework/types';
import Articles from 'src/json-api/_models/blog/articles';
import ArticlesWithTokens from 'src/json-api/_models/blog/articles-with-tokens';
import Themes from 'src/json-api/_models/blog/themes';

type Article = ResponseOne<Articles['fields'], Articles['relations']>;
type ArticleManyResponse = (Articles['fields'] & { id: string } & {
  themes?: (Themes['fields'] & { id: string })[];
})[];

export default class BlogStore {
  themes: { id: string; name: string }[] = null;

  themeId: string = null;

  articles: ArticleManyResponse = null;

  loadedArticles: Article[] = [];

  constructor(initialState: { articles?: ArticleManyResponse; loadedArticles?: Article[] }) {
    makeAutoObservable(this, { loadArticle: false });

    if (initialState?.articles) this.articles = initialState.articles;
    if (initialState?.loadedArticles) this.loadedArticles = initialState.loadedArticles;
  }

  async loadThemes(): Promise<{ id: string; name: string }[]> {
    const themesModel = new ApiModel<Themes>(Themes);
    const { data } = await themesModel.getMany();
    this.themes = data;
    return data;
  }

  async loadArticles(logged?: boolean): Promise<ArticleManyResponse> {
    const articlesModel = logged
      ? new ApiModel<ArticlesWithTokens>(ArticlesWithTokens)
      : new ApiModel<Articles>(Articles);
    const { data } = await articlesModel.getMany();
    this.articles = data;
    return data;
  }

  async loadArticle(id: string, logged?: boolean): Promise<Article> {
    const articlesModel = logged
      ? new ApiModel<ArticlesWithTokens>(ArticlesWithTokens)
      : new ApiModel<Articles>(Articles);
    const data = await articlesModel.getOne(id);
    this.loadedArticles.push(data);
    return data;
  }

  setTheme(themeId: string): void {
    this.themeId = themeId;
  }

  get articlesToShow(): ArticleManyResponse {
    if (!this.themeId) return this.articles;
    return this.articles?.filter(({ themes }) => {
      const articleThemes = themes.map(({ id }) => id);
      return articleThemes.includes(this.themeId);
    });
  }
}
