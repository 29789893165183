import axios from 'axios';
import * as Sentry from '@sentry/browser';
import isNode from '../../../helpers/utils/is-node';

import generateAccessToken from '../../generate-access-token';
import Model from '../../sarala/Model';
import { captureSentryError } from '../../../helpers/utils/capture-sentry-browser';
import store from '../../../../store-config';

// TODO придумать куда деть эти глобальные переменные
let accessIsPending = true;

function accessRequestWaiting() {
  return new Promise((resolve) => {
    function checkAccessReq() {
      setTimeout(() => {
        if (accessIsPending) {
          resolve(true);
        } else checkAccessReq();
      }, 20);
    }
    checkAccessReq();
  });
}

export default class BaseModelWithTokens extends Model {
  baseUrl() {
    return isNode() ? `http://${global.host}/api/json/1.0` : '/api/json/1.0';
  }

  async request(config) {
    try {
      const addHeadersToConfig = async (conf, is401) => {
        if (!accessIsPending) await accessRequestWaiting();
        accessIsPending = false;
        const accessRes = await generateAccessToken(is401);
        accessIsPending = true;
        const { header: authHeader } = accessRes;
        return {
          ...conf,
          headers: {
            ...conf.headers,
            ...authHeader,
            client: 'site',
            'client-version': isNode() ? 'server' : 'browser',
          },
        };
      };

      Sentry.addBreadcrumb({
        category: `${config.method}: ${config.url}`,
        message: 'request',
        data: config,
        level: 'info', //Sentry.Severity.Info,
        type: 'http',
      });

      const makeRequest = async (conf, is401) =>
        axios.request(await addHeadersToConfig(conf, is401)).catch((error) => error.response);

      let res = await makeRequest(config);
      if (!res || (!res.data && res.status !== 204 && res.status !== 202)) {
        res = {
          data: {
            data: {
              errors: [{ detail: 'Произошла ошибка, попробуйте еще раз' }],
            },
          },
        };
      }

      if (res.status === 401) {
        res = await makeRequest(config, true).catch((err) => {
          captureSentryError(new Error(err));
        });
      }

      Sentry.addBreadcrumb({
        category: `Response of ${config.method}: ${config.url}`,
        data: res,
        message: 'request',
        level: 'info', //Sentry.Severity.Info,
        type: 'http',
      });

      return res;
    } catch (err) {
      (async () => {
        await axios
          .delete(`${this.baseUrl()}/oauth/`, {
            headers: { client: 'site', 'client-version': 'browser' },
          })
          .catch((error) => {
            captureSentryError(new Error(error));
          });

        store.dispatch({ type: 'RESET_STORE' });

        document.location.reload();
      })();
    }
  }
}
