import dayjs from 'dayjs';

import typeDict from './type-dictionary/type-dict';
import showError from '../helpers/utils/show-error';
import store from '../../store-config';
import setLoadingStatus from './set-loading-status';

import isNode from '../helpers/utils/is-node';
import { captureSentryError } from '../helpers/utils/capture-sentry-browser';

function get(types = [], customStore) {
  const { dispatch, getState } = customStore || store;

  // Удалить из списка запросов те сущности, которым нужна авторизация, если ее нет
  const logged = getState().user.logged || (!isNode() && window.isLoggedFromNode);

  if (logged) {
    // window.isLoggedFromNode = false;
    dispatch({ type: 'SET_LOGGED', payload: true });
  }

  types = types.filter(
    (type) =>
      !getState().dataConfig[type].needLogin || (logged && getState().dataConfig[type].needLogin),
  );
  if (!types.length) {
    types.forEach((type) => {
      const { setLoaded } = setLoadingStatus({
        type,
      });
      setLoaded();
    });
  }

  const promises = types
    .filter((type) => {
      if (isNode()) return true;

      const { old, lastRequest, saveTime, isPending, updateWhenLogged } = getState().dataConfig[
        type
      ];

      if (isPending) return false;

      if (logged && updateWhenLogged) {
        return true;
      }

      const now = dayjs(new Date()).unix();

      if (old) return true;
      if (lastRequest + saveTime <= now) return true;

      return false;
    })
    .map(async (type) => {
      const { old } = getState().dataConfig[type];
      const { setIsPending, setIsUnpending, setLastRequest, setLoaded, setNew } = setLoadingStatus({
        type,
      });

      setIsPending();
      try {
        const { get, dispatchData } = typeDict({ dispatch, type, getState, logged });

        return get()
          .then((payload) => {
            dispatchData(payload);

            if (old) setNew();
            setLoaded();
            setIsUnpending();
            setLastRequest();
            return payload;
          })
          .catch(() => {
            setIsUnpending();
          });
      } catch (err) {
        setIsUnpending();
      }
    });

  return Promise.all(promises).catch(async ({ errors }) => {
    captureSentryError(`Unable to fetch data in dataController`);
    types.forEach((type) => {
      const { setIsUnpending } = setLoadingStatus({
        dispatch,
        type,
      });
      setIsUnpending();
    });

    showError(errors);
  });
}

const dataController = {
  get,
  config: {
    setIsPending: ({ type }) => setLoadingStatus({ dispatch: store.dispatch, type }).setIsPending(),
    setIsUnpending: ({ type }) =>
      setLoadingStatus({ dispatch: store.dispatch, type }).setIsUnpending(),
    setLoaded: ({ type }) => setLoadingStatus({ dispatch: store.dispatch, type }).setLoaded(),
    setOld: ({ type }) => setLoadingStatus({ dispatch: store.dispatch, type }).setOld(),
    setNew: ({ type }) => setLoadingStatus({ dispatch: store.dispatch, type }).setNew(),
    setLastRequest: ({ type }) =>
      setLoadingStatus({ dispatch: store.dispatch, type }).setLastRequest(),
  },
};

export default dataController;
