import React from "react";
import loadable from "@loadable/component";

const PayRequset = loadable(() =>
  import(
    /* webpackChunkName: "pay-request" */ "../../components/pay-request/pay-request"
  )
);

export default function PayRequsetPage() {
  return <PayRequset />;
}
