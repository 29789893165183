import BaseModel from '../../base/base-model';

export default class MaxholdConditions extends BaseModel {
  resourceName() {
    return 'maxhold_conditions';
  }

  fields() {
    return ['text', 'title'];
  }
}
