import React, { useState, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import onEnter from '../../../helpers/utils/on-enter';
import './swipe-menu.scss';
import usePrevious from '../hooks/use-prev';

const propTypes = {
  width: PropTypes.number,
  show: PropTypes.bool,
  handleShow: PropTypes.func.isRequired,
  innerComponent: PropTypes.node.isRequired,
  side: PropTypes.oneOf(['left', 'right'])
};

const defaultProps = {
  width: 0,
  show: false,
  side: 'left'
};

function SwipeMenu({ width, show, handleShow, innerComponent, side, closeIconBurger }) {
  const stops = {
    show: side === 'left' ? '0' : `-100`,
    hide: side === 'left' ? '-100' : '0'
  };

  const [point, setPoint] = useState(stops.hide[side]);
  const [swipePoint, setSwipePoint] = useState(stops.hide[side]);
  const [startPoint, setStartPoint] = useState();

  const swipeEl = useRef();
  const prevPoint = usePrevious(point);

  const [touchY, setTouchY] = useState();
  const prevTouchY = usePrevious(touchY);

  useEffect(() => {
    if (show) {
      setPoint(stops.show);
      setSwipePoint(stops.show);
    } else {
      setPoint(stops.hide);
      setSwipePoint(stops.hide);
    }
  }, [show, stops]);

  function start(touch) {
    switch (side) {
      case 'left': {
        setStartPoint(swipeEl.current.getBoundingClientRect().left - touch.clientX);
        break;
      }
      case 'right':
        setStartPoint(touch.clientX);
        break;
      default:
        break;
    }
  }

  function move(touch) {
    setTouchY(touch.clientY);
    let isScroll = Math.abs(prevTouchY - touchY) > 7;
    if (isScroll) setPoint(stops.show);
    else if (side === 'left') {
      const newPoint = startPoint + touch.clientX;
      if (newPoint < 0) setPoint(touch.clientX);
    } else {
      const newPoint = -startPoint + touch.clientX;
      if (newPoint > 0) setPoint(touch.clientX);
    }
  }

  function end() {
    switch (side) {
      case 'left':
        if (point < prevPoint) handleShow(false);
        if (point > prevPoint) {
          setPoint(stops.show);
          setSwipePoint(stops.show);
        }
        break;

      case 'right':
        if (point > prevPoint) handleShow(false);
        if (point < prevPoint) {
          setPoint(stops.show);
          setSwipePoint(stops.show);
        }
        break;
      default:
        break;
    }
  }

  return (
    <div className={`swipe-wrapper swipe-wrapper_${side}`}>
      <div
        style={{ width, transform: swipePoint && `translateX(${swipePoint}%)` }}
        className="swipe"
        ref={swipeEl}
        onTouchEnd={end}
        onTouchMove={(e) => move(e.changedTouches[0])}
        onTouchStart={(e) => start(e.touches[0])}
      >
        <svg
          onClick={() => handleShow(false)}
          {...onEnter(() => handleShow(false))}
          className={closeIconBurger ? 'swipe-close swipe-close_burger' : 'swipe-close'}
          tabIndex="0"
          role="button"
          aria-label="Закрыть"
        >
          <use xlinkHref={`/assets/svg/sprite-v2.svg#${closeIconBurger ? 'menu' : 'close'}`} />
        </svg>
        {innerComponent}
      </div>
      <div
        onClick={() => handleShow(false)}
        onKeyDown={() => handleShow(false)}
        style={{
          visibility: show ? 'visible ' : 'hidden',
          opacity: show ? '.4' : '0'
        }}
        className="swipe-mask"
        tabIndex="0"
        role="button"
      />
    </div>
  );
}

SwipeMenu.propTypes = propTypes;
SwipeMenu.defaultProps = defaultProps;

export default SwipeMenu;
