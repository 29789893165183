import React from "react";
import loadable from "@loadable/component";

const Support = loadable(() =>
  import(/* webpackChunkName: "support" */ "../../components/support/support")
);

export default function SupportPage() {
  return <Support />;
}
