import ModelWithTokens from '../base/base-model-with-tokens';
import Tickets from '../tickets/tickets';
import Favorites from './favorites';
import Clicks from './clicks';
import Payouts from './payouts';
import Sales from './sales';
import Balance from './balance';
import Stats from './stats';
import Subscriptions from './subscriptions';
import Notifies from './notifies';
import DeliverCountries from '../other/delivery-countries';

export default class User extends ModelWithTokens {
  resourceName() {
    return 'users';
  }

  fields() {
    return [
      'email',
      'fromSocNet',
      'accounts',
      'avatarPath',
      'nickname',
      'phone',
      'subscribes',
      'notifies',
      'isAdmin',
      'login',
      'hasPassword',
      'emailApproved',
      'delivery_countries',
      'regDate',
    ];
  }

  relationships() {
    return {
      favorites: new Favorites(),
      clicks: new Clicks(),
      payouts: new Payouts(),
      tickets: new Tickets(),
      sales: new Sales(),
      balance: new Balance(),
      subscriptions: new Subscriptions(),
      notifies: new Notifies(),
      delivery_countries: new DeliverCountries(),
      stats: new Stats(),
    };
  }
}
