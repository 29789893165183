import React from 'react';
import PropTypes from 'prop-types';

function Icon({ id, onClick, onKeyPress, className, fill }) {
  return (
    <svg className={className} onClick={onClick} onKeyPress={onKeyPress} fill={fill}>
      <use xlinkHref={`/assets/svg/sprite-v2.svg?220124#${id}`} />
    </svg>
  );
}

Icon.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  className: PropTypes.string,
  fill: PropTypes.string,
};

Icon.defaultProps = {
  className: '',
  fill: '',
};

export default Icon;
