import BaseModel from '../base/base-model';

export default class Currencies extends BaseModel {
  resourceName() {
    return 'currencies';
  }

  fields() {
    return ['name', 'rate', 'sign'];
  }
}
