import uniqid from 'uniqid';

import User from '../../../json-api/models/user/user';

export default async function getUserDataReload() {
  const userMain = new User();
  const id = '';

  const rawData = await userMain.with(['notifies', 'balance']).find(id);

  const data = rawData?.data[0];

  const notifyCount =
    data && data.notifies ? data.notifies.data.filter((notify) => !notify.isReaded).length : 0;

  data.nicknameToShow =
    data.nickname || (data.email && data.email !== null && data.email.split('@')[0]) || data.id;

  data.avatarPath = data.avatarPath === null ? data.avatarPath : `${data.avatarPath}?${uniqid()}`;

  return {
    data,
    notifyCount
  };
}
