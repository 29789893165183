import dayjs from 'dayjs';
import getTokenData from '../../src/helpers/utils/get-token-data';

const initialState = {
  showLoader: false,
  toast: {
    show: false,
    msg: '',
    type: ''
  },
  menuLeft: false,
  menuRight: false,
  hideHeaderFooter: false,
  tokens: {},
  timeOffset: 0,
  balanceIsOpen: true
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'RESET_APP':
      return initialState;
    case 'CUR_NODE_URL':
      return { ...state, nodeUrl: payload };
    case 'INIT':
      return { payload };
    case 'SHOW_LOADER':
      return { ...state, showLoader: payload };
    case 'SHOW_ERROR_BOUNDARY':
      return {
        ...state,
        showError: payload.showError,
        errorText: payload.errorText
      };
    case 'SAVE_TOKENS':
      return {
        ...state,
        tokens: { accessToken: payload.accessToken },
        timeOffset:
          payload.accessToken && +getTokenData(payload.accessToken).iat - dayjs(new Date()).unix()
      };
    case 'DELETE_TOKENS':
      return {
        ...state,
        tokens: {}
      };
    case 'SET_TOAST':
      return {
        ...state,
        toast: { ...payload }
      };
    case 'SET_BALANCE_IS_OPEN':
      return {
        ...state,
        balanceIsOpen: payload
      };
    case 'SHOW_MENU_LEFT':
      return {
        ...state,
        menuLeft: !state.menuLeft
      };
    case 'SHOW_MENU_RIGHT':
      return {
        ...state,
        menuRight: !state.menuRight
      };
    case 'SERVER_ROUTE':
      return {
        ...state,
        serverRoute: payload
      };
    case 'SET_PROMO_BUTTON_URL':
      return { ...state, promoButtonUrl: payload };

    default:
      return state;
  }
};
