import ModelWithTokens from '../base/base-model-with-tokens';

export default class Countries extends ModelWithTokens {
  resourceName() {
    return 'countries';
  }

  getDelivery(config) {
    return this.makeFetchRequest(
      `${'/api/json/1.0/'}${'countries'}${'/?include=&filter[isDelivery]=true'}`,
      config,
    );
  }

  fields() {
    return ['label', 'shortName', 'isFilter', 'isDelivery', 'phoneCode'];
  }
}
