const initialState = {
  categories: [],
  countries: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SAVE_CATEGORIES':
      return {
        ...state,
        categories: payload,
      };

    case 'RESET_BANNERS':
      return {
        ...state,
        banners: {
          mobile: [],
          desktop: [],
          desktop_not_auth: [],
        },
      };

    case 'SAVE_BANNERS':
      return {
        ...state,
        banners: payload,
      };

    case 'SAVE_COUNTRIES':
      return {
        ...state,
        countries: payload,
      };

    default:
      return state;
  }
};
