import ApiModel from 'src/json-api/framework/api-model';
import ApproveCurrentEmail from 'src/json-api/_models/requests/approve-current-email';
import ApproveCurrentPhone from 'src/json-api/_models/requests/approve-current-phone';
import ApproveNewEmail from 'src/json-api/_models/requests/approve-new-email';
import ChangeEmail from 'src/json-api/_models/requests/change-email';
import Requests from 'src/json-api/_models/requests/requests';
import User from 'src/json-api/_models/user/user';

export default class EmailFieldTransport {
  async changeUserEmail({ userId, email }: { userId: string; email: string }) {
    const userModel = new ApiModel<User>(User);
    userModel.id = userId;
    userModel.fields = { email };
    return userModel.update();
  }

  async onApproveOldEmail() {
    const requestModel = new ApiModel<Requests>(Requests);
    requestModel.fields.requestType = '6';
    requestModel.fields.subject = 'email_change';

    const createdRequest = await requestModel.create();

    const approveModel = new ApiModel<ApproveCurrentEmail>(ApproveCurrentEmail);
    approveModel.fields.token = createdRequest.token;

    return approveModel.create();
  }

  async onApproveEmailToken({ code, token }: { code: string; token: string }) {
    const requestModel = new ApiModel<Requests>(Requests);
    requestModel.id = '4';
    requestModel.fields = { token, code };

    const createdRequest = await requestModel.update();

    return createdRequest.token;
  }

  async onSendNewEmail({ token, email }: { token: string; email?: string }) {
    const changeEmailModel = new ApiModel<ChangeEmail>(ChangeEmail);
    changeEmailModel.fields = { token, email };

    return changeEmailModel.create();
  }

  async onApprovePhone() {
    const requestModel = new ApiModel<Requests>(Requests);
    requestModel.fields.requestType = '2';
    requestModel.fields.subject = 'email_change';

    const { token } = await requestModel.create();

    const approveModel = new ApiModel<ApproveCurrentPhone>(ApproveCurrentPhone);
    approveModel.fields.token = token;

    await approveModel.create();
    return token;
  }

  async onSendCode({ code, token }: { code: string; token: string }) {
    const requestModel = new ApiModel<Requests>(Requests);
    requestModel.id = '2';
    requestModel.fields = {
      requestType: '2',
      subject: 'email_change',
      token,
      code
    };
    const res = await requestModel.update();

    return res.token;
  }

  async onAttachNewEmail({ email }: { email: string }): Promise<''> {
    const requestModel = new ApiModel<Requests>(Requests);
    requestModel.fields.requestType = '4';
    requestModel.fields.subject = 'email_attach';

    const { token } = await requestModel.create();

    const approveModel = new ApiModel<ApproveNewEmail>(ApproveNewEmail);
    approveModel.fields = { token, email };

    return approveModel.create();
  }
}
