import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { useParams } from 'react-router';
import useStores from 'src/stores/use-stores';
import ArticleHOC from './article-hoc';

export function getArticleId(translitName: string): string {
  const parts = translitName.split('-');
  return parts[parts.length - 1];
}

function Article({ setMeta }: { setMeta: (options: any) => void }) {
  const params = useParams<{ id: string }>();

  const { blog } = useStores();

  const getCurrentArticle = useCallback(() => {
    const currentId = getArticleId(params.id);
    const existingArticle = blog.loadedArticles.find(
      ({ id: articleId }) => articleId === currentId,
    );

    if (!existingArticle) blog.loadArticle(currentId);

    return existingArticle;
  }, [blog, params.id]);

  return (
    <>
      {(() => {
        const currentArticle = getCurrentArticle();
        if (currentArticle) {
          setMeta({
            title: currentArticle.seoTitle,
            description: currentArticle.seoDescription,
          });
          return (
            // @ts-expect-error FIXME переписать на ts
            <ArticleHOC
              imagePath={currentArticle.imageUrl}
              title={currentArticle.title}
              markup={currentArticle.body}
              // @ts-expect-error FIXME надо поменять типы
              theme={currentArticle.themes[0].name}
              publishDate={currentArticle.publishDate}
            />
          );
        }

        return <></>;
      })()}
    </>
  );
}

export default observer(Article);
