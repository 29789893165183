import uniqid from 'uniqid';

import User from '../../../json-api/models/user/user';
import exit from '../../../helpers/utils/exit';

export default async function getUserData({
  withoutIncludes = false,
  changedField = null,
  include = [],
}) {
  const userMain = new User();
  const id = '';
  try {
    const data = await userMain
      .with(
        withoutIncludes
          ? ['notifies', 'balance', 'subscriptions', 'favorites', 'delivery_countries']
          : ['notifies', 'balance', 'subscriptions', 'favorites', 'delivery_countries', ...include],
      )
      .find(id);

    const user = data?.data[0];
    const notifyCount = user?.notifies
      ? user.notifies.data.filter((notify) => !notify.isReaded).length
      : 0;

    user.nicknameToShow = user.nickname || user.email?.split('@')[0] || user.id;

    user.avatarPath = user.avatarPath === null ? user.avatarPath : `${user.avatarPath}?${uniqid()}`;

    const logged = user?.id;

    if (process.env.MODE !== 'development' && logged && !window.IS_TEST) {
      if (window.yaParams) window.yaParams['Пользователь'] = user.id;
      if (window.ym) window.ym(10953694, 'setUserID', window.yaParams['Пользователь']);
    }

    return {
      userId: id,
      data: user,
      changedField,
      notifyCount,
      logged,
    };
  } catch (err) {
    // console.log(err);
    exit();
  }
}
