import { makeAutoObservable, runInAction } from 'mobx';
import Notifies from 'src/json-api/_models/user/notifies';
import NotifiesTransport from './notifies-transport';

export default class NotifiesDomainStore {
  notifies: Array<Notifies['fields'] & { id?: string }> = [];

  isLoading = true;

  transportLayer: NotifiesTransport;

  constructor(transportLayer: NotifiesTransport) {
    makeAutoObservable(this);
    this.transportLayer = transportLayer;
  }

  async loadNotifies() {
    this.isLoading = true;

    const { data } = await this.transportLayer.get();

    runInAction(() => {
      this.notifies = data;
      this.isLoading = false;
    });
  }

  async setAllRead() {
    this.isLoading = true;

    const ids = this.notifies.map(({ id }) => id);
    const requests = ids.map((id) => this.transportLayer.update(id, { isReaded: true }));
    const responses = await Promise.all(requests);

    runInAction(() => {
      this.notifies = responses;
      this.isLoading = false;
    });
  }

  async deleteNotify(id: string) {
    this.isLoading = true;

    await this.transportLayer.delete(id);

    runInAction(() => {
      const deletedNotify = this.notifies.find(({ id: notifyId }) => id === notifyId);
      this.notifies.splice(this.notifies.indexOf(deletedNotify), 1);
      this.isLoading = false;
    });
  }
}
