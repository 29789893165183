import React from 'react';
import ReactLoading from 'react-loading';
import PropTypes from 'prop-types';
import './loader.scss';

const Loader = ({ loaderIsWhite, show }) => {
  return (
    <>
      {show && (
        <div className={`loader-wrapper ${loaderIsWhite ? 'loader-wrapper_white' : ''}`}>
          <ReactLoading
            className="loader"
            type="bubbles"
            color="#1d7bcd"
            height="100px"
            width="100px"
          />
        </div>
      )}
    </>
  );
};

Loader.propTypes = {
  show: PropTypes.bool
};

Loader.defaultProps = {
  show: false
};

export default Loader;
